import { Grid, InputBase, TextField, useMediaQuery } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { useState } from "react";
import "./editPatientInformation.css";
import { ReactComponent as CalendarIcon } from "../../../../../assets/calendar.svg";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { InpatientOrderValidator } from "../../../../inpatientOrder/inpatientOrder.validator";
import { convertStringToDate } from "../../../../../util/utilityFunctions";
import moment from "moment";
import { IEditPatientInformation } from "./editPatientInformation.interface";

const EditPatientInformation = ({
  patientInformation,
  isAutoFocusEnabled,
  setPatientInfo,
  setIsPatientEditMode,
  handleSubmitPatientData,
  newOrderData,
  setExitPopUp,
}: IEditPatientInformation) => {
  const [focusClasses, setFocusClasses] = useState({ dateOfBirth: "" });
  const isSmallerMobileScreen = useMediaQuery("(max-width:414px)");
  const isMobileScreen = useMediaQuery("(max-width:600px)");

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const validateAndSetData = (e: any) => {
    const { name, value } = e.target;
    const validator = new InpatientOrderValidator();
    const isValid = validator.validate(value, name);
    const tempPatientData = {
      ...patientInformation,
      [name]: {
        ...Object(patientInformation)[name],
        value: value,
        valid: isValid?.status,
        errorMessage: null,
      },
    };
    setPatientInfo(tempPatientData);
  };

  const checkForSaveButtonDisable = (): boolean => {
    let isButtonEnabled: boolean = false;
    if (
      patientInformation.firstName.value.toLowerCase() ===
        newOrderData.firstName.value.toLowerCase() &&
      patientInformation.lastName.value.toLowerCase() ===
        newOrderData.lastName.value.toLowerCase() &&
      patientInformation.room.value.toLowerCase() ===
        newOrderData.patientLocation?.value.toLowerCase() &&
      patientInformation.patientId.value.toLowerCase() ===
        newOrderData.patientId!.value.toLowerCase() &&
      moment(newOrderData.dob.value).isSame(
        moment(patientInformation.dateOfBirth.value)
      )
    ) {
      isButtonEnabled = true;
    }
    return isButtonEnabled;
  };

  const validateAndSetDate = (date: string | null | undefined) => {
    const formattedDate = date ? convertStringToDate(date) : "";
    const validator = new InpatientOrderValidator();
    const isValid = validator.validate(formattedDate!, "dateOfBirth");
    const tempData = {
      ...patientInformation,
      dateOfBirth: {
        ...patientInformation.dateOfBirth,
        value: formattedDate,
        valid: isValid?.status,
        errorMessage: null,
      },
    };
    setPatientInfo(tempData);
  };

  return (
    <div
      className="edit-patient-information-component"
      id="edit-patient-information-component"
      data-testid="edit-patient-information-component"
    >
      <Grid className="edit-order-patient-information-grid-container" container>
        <Grid
          className="edit-order-patient-information-grid-item"
          item
          xs={isSmallerMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            error={
              patientInformation.firstName.valid === ValidationStatus.INVALID
            }
            id={patientInformation.firstName.componentId!}
            isRequired={patientInformation.firstName.required}
            isShowWarning={true}
            label={patientInformation.firstName.title!}
            labelClassName="patient-information-title"
            testId="edit-patient-info-firstName"
            warningMessage={patientInformation.firstName.errorMessage}
          >
            <InputBase
              autoFocus={isAutoFocusEnabled}
              className="patient-information-input"
              data-testid="edit-patient-info-firstName-value"
              id={patientInformation.firstName.id!}
              name="firstName"
              onChange={validateAndSetData}
              value={patientInformation.firstName.value}
              inputProps={{ maxLength: 15 }}
            />
          </InputWithLabel>
        </Grid>
        <Grid
          className="edit-order-patient-information-grid-item"
          item
          xs={isSmallerMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            error={
              patientInformation.lastName.valid === ValidationStatus.INVALID
            }
            id={patientInformation.lastName.componentId!}
            isRequired={patientInformation.lastName.required}
            isShowWarning={true}
            label={patientInformation.lastName.title!}
            labelClassName="patient-information-title"
            testId="edit-patient-info-lastName"
            warningMessage={patientInformation.lastName.errorMessage}
          >
            <InputBase
              className="patient-information-input"
              data-testid="edit-patient-info-lastName-value"
              id={patientInformation.lastName.id!}
              name="lastName"
              onChange={validateAndSetData}
              value={patientInformation.lastName.value}
              inputProps={{ maxLength: 15 }}
            />
          </InputWithLabel>
        </Grid>
        <Grid
          className="edit-order-patient-information-grid-item"
          item
          xs={isSmallerMobileScreen ? 12 : isMobileScreen ? 6 : 4}
        >
          <InputWithLabel
            error={
              patientInformation.dateOfBirth.valid === ValidationStatus.INVALID
            }
            id={patientInformation.dateOfBirth.componentId!}
            isRequired={patientInformation.dateOfBirth.required}
            isShowWarning={true}
            label={patientInformation.dateOfBirth.title!}
            labelClassName={focusClasses.dateOfBirth}
            testId="edit-patient-info-dob"
            warningMessage={patientInformation.dateOfBirth.errorMessage}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                components={{ OpenPickerIcon: CalendarIcon }}
                InputAdornmentProps={{
                  classes: {
                    root: "adornedRoot",
                  },
                }}
                InputProps={{
                  classes: {
                    root: `edit-order-patient-information-date-of-birth ${
                      patientInformation.dateOfBirth.valid ===
                      ValidationStatus.INVALID
                        ? "show-error"
                        : "no-error"
                    }`,
                    input: "input",
                    notchedOutline: "outline",
                  },
                }}
                onChange={(value) => validateAndSetDate(value)}
                renderInput={(params) => {
                  params.error = false;
                  params.inputProps!.placeholder = "__/__/____";
                  return (
                    <TextField
                      id={patientInformation.dateOfBirth.id!}
                      name="dateOfBirth"
                      onFocus={(e) => setClasses(e, "Mui-focused")}
                      onBlur={(e) => setClasses(e, "")}
                      {...params}
                    />
                  );
                }}
                value={patientInformation.dateOfBirth.value}
              />
            </LocalizationProvider>
          </InputWithLabel>
        </Grid>
        <Grid
          className="edit-order-patient-information-grid-item"
          item
          xs={isSmallerMobileScreen ? 12 : isMobileScreen ? 6 : 4}
        >
          <InputWithLabel
            error={patientInformation.room.valid === ValidationStatus.INVALID}
            id={patientInformation.room.componentId!}
            isRequired={patientInformation.room.required}
            isShowWarning={true}
            label={patientInformation.room.title!}
            labelClassName="patient-information-title"
            testId="edit-patient-info-roomNo"
            warningMessage={patientInformation.room.errorMessage}
          >
            <InputBase
              className="patient-information-input"
              data-testid="edit-patient-info-roomNo-value"
              id={patientInformation.room.id!}
              name="room"
              onChange={validateAndSetData}
              value={patientInformation.room.value}
            />
          </InputWithLabel>
        </Grid>
        <Grid
          className="edit-order-patient-information-grid-item"
          item
          xs={isSmallerMobileScreen ? 12 : isMobileScreen ? 6 : 4}
        >
          <InputWithLabel
            error={
              patientInformation.patientId.valid === ValidationStatus.INVALID
            }
            id={patientInformation.patientId.componentId!}
            isRequired={patientInformation.patientId.required}
            isShowWarning={true}
            label={patientInformation.patientId.title!}
            labelClassName="patient-information-title"
            testId="edit-patient-info-patientId"
            warningMessage={patientInformation.patientId.errorMessage}
          >
            <InputBase
              className="patient-information-input"
              data-testid="edit-patient-info-patientId-value"
              id={patientInformation.patientId.id!}
              name="patientId"
              onChange={validateAndSetData}
              value={patientInformation.patientId.value}
              inputProps={{ maxLength: 12 }}
            />
          </InputWithLabel>
        </Grid>
      </Grid>
      <div className="edit-info-button-group" id="edit-info-button-group">
        <ExpressButton
          variant={"outlined"}
          parentClass="edit-button"
          clickHandler={() => {
            setIsPatientEditMode(false);
            setExitPopUp(false);
          }}
          testId="edit-patient-info-cancel-btn"
        >
          Cancel
        </ExpressButton>
        <ExpressButton
          variant={"contained"}
          parentClass="edit-button"
          clickHandler={() => handleSubmitPatientData()}
          disabled={checkForSaveButtonDisable()}
          testId="edit-patient-info-save-btn"
        >
          Save
        </ExpressButton>
      </div>
    </div>
  );
};

export default EditPatientInformation;
