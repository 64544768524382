export const MOBILE_BREAKPOINT = 926;
export const CMS_FOOTER_CONTENT = "Footer";
export const CMS_SMS_CONTENT = "SMS Terms & Conditions";
export const CMS_TECHNICALSUPPORT_CONTENT = "TechnicalSupport";
export const CMS_HELPSUPPORT_CONTENT = "HelpSupport";
export const CMS_FAQ_VIDEO_CONTENT = "FAQ_VideoTutorials";
export const CMS_CONTACT_US_CONTENT = "ContactUs";
export const CMS_VAC_THERAPY_INFORMATION_CONTENT = "VACTherapyInformation";
export const CMS_UPS_PDF_CONTENT = "ReturnInstruction";
export const CMS_EDUCATION = "Education";
export const CMS_EDUCATION_VIDEO_LIBRARY = "Education_VideoLibrary";
export const CMS_PICKUP_RETURN_INSTRUCTION =
  "pickupPendingContent/Pickup_ReturnInstructions";
export const CMS_USER_GUIDENCE = "UserGuidance";
export const CMS_SUPPLY_CONTENT = "Supply";
export const DD_DELIVERY_METHODS = "SupplyOrderDeliveryMethods";
export const DD_PHONE_TYPE_CONTENT = "PhoneType";
export const DD_LICENSE_CONTENT = "LicenseType";
export const DD_DEPARTMENT_CONTENT = "Department";
export const DD_ACCIDENT_TYPE_CONTENT = "AccidentType";
export const DD_THERAPY_LENGTHS_CONTENT = "TherapyLengths";
export const DD_VAC_THERAPY_LOCATION_CONTENT = "VACTherapyLocation";
export const DD_TIME_OF_PLACEMENT_CONTENT = "TimeOfPlacement";
export const DD_INSURED_RELATION = "InsuredRelation";
export const DD_THERAPY_GOALS_CONTENT = "TherapyGoals";
export const DD_FACILITY_TYPE_CONTENT = "FacilityType";
export const DD_ALLSTATUS_CONTENT = "AllStatusCategories";
export const DD_PRODUCT_INFORMATION_CONTENT = "ProductInfo";
export const DD_COMMON_DOCS_CONTENT = "CommonDocs";
export const DD_DRESSING_CHANGE_FREQUENCY = "dressingChangeFrequency";
export const DD_RESUPPLY_JUSTIFICATION = "resupplyJustification";
export const DD_CONTACTUS_SUBJECT_CONTENT = "ContactUsSubject";
export const DD_CONTACTUS_COUNTRY_CONTENT = "ContactUsCountry";
export const DD_DELIVERY_SITE_TYPE_CONTENT = "DeliverySiteType";
export const DD_DELIVERY_SITE_TYPE_CONTENT_LANDINGPAGE =
  "DeliverySiteTypeLandingPage";
export const DD_PAYER_TYPE = "PayerType";
export const DD_CLASSIFICATION_CONTENT = "FacilityClassification";
export const DD_MY_LIST_STORAGE_LOC_STATUS_TYPE =
  "MyListStorageLocationStatusType";
export const DD_CARE_SETTING_CONTENT = "CareSetting";
export const DD_NEED_BY_TIME_CONTENT = "NeedByTime";
export const DD_US_STATES_CONTENT = "USStates";
export const DD_INSURANCE_TYPE_CONTENT = "InsuranceType";
export const DD_INSURANCE_TYPE_TRANSITION_CONTENT = "InsuranceTypeTransition";
export const DD_MANUAL_FACILITY_TYPE = "ManualFacilityType";
export const DD_DEBRIDEMENT_TYPE = "DebridementTypes";
export const DD_TUNNELING_POSITION = "ClockPosition";
export const DD_UNDERMINING_POSITION = "underminingPosition";
export const DD_EXUDATE_AMOUNT_TYPE = "ExudateAmount";
export const DD_EXUDATE_APPEARANCE_TYPE = "ExudateAppearance";
export const DD_WOUND_LOCATION = "WoundLocation";
export const DD_WOUND_TYPE = "WoundType";
export const DD_WOUND_DIRECTION = "WoundDirection";
export const DD_WOUND_ORIENTATION = "WoundOrientation";
export const DD_RETURN_METHOD_CONTENT = "ReturnMethod";
export const DD_PROVIDER_TYPE = "ProviderType";
export const DD_REASON_FOR_HOLD = "ReasonForHold";
export const DD_DISCONTINUED_REASON = "DiscontinuedReason";
export const DD_CONTACT_REASON = "ContactResason";
export const DD_EDUCATION_VIDEO_CATEGORIES = "EducationVideoCategories";
export const DD_UPLOAD_DOCUMENT_TYPE = "FileNetDocType";
export const DD_PRODUCTS_AND_SOLUTIONS_CATEGORIES =
  "ProductAndSolutionCategories";
export const DD_REASON_FOR_TRANSITION = "ReasonForTransition";
export const DD_SERVICE_OPTIONS = "ServiceOptions";
export const PRODUCT_TYPE_ACCESSORIES = "Accessories";
export const PRODUCT_TYPE_DRESSING = "Dressing";
export const PRODUCT_TYPE_CANISTER = "Canister";
export const DD_READYCARE_PATIENT_CANCEL_ORDER = "CancelReadyCarePatientOrder";
export const DD_DELIVERY_CONTACT = "DeliveryContact";
export const DD_ALL_ROLES = "AllSalesRoles";
export const DD_ASSIGNMENT_CHANGE_REASON = "AssignmentChangeReason";
export const DD_AFFECTED_FEATURES_TYPES = "AffectedFeatureTypes";
export const DD_WOUND_AGE_TYPE = "WoundAgeType";
export const DD_SITE_STATUS_NOTE_TYPE_CONTENT = "SiteStatusNoteTypes";
export const DD_SITE_STATUS_STATUSES_CONTENT = "SiteStatusStatues";
export const DD_ACUTE_WOUND_LOCATION = "AcuteWoundLocation";
export const DD_ACUTE_WOUND_TYPE = "AcuteWoundType";
export const SVC_RETRIEVE_PATIENT = "api/Provider/RetrievePatient";
export const SVC_RETRIEVE_SALESREP = "api/Provider/RetrieveSalesRepInfo";
export const SVC_RETRIEVE_PATIENT_SEARCH = "api/search/Patient";
export const SVC_RETRIEVE_ACUTE_PATIENT_SEARCH = "/api/GetAcutePatientDetails";
export const SVC_ADD_SEARCH_PATIENT = "api/provider/AddPatient";
export const SVC_VERIFY_CAPTCHA = "/api/verifyCaptcha";
export const SVC_SEARCH_FACILITY = "api/search/getFacilityInfo";
export const SVC_SEARCH_ALL_FACILITY = "/api/getAllFacility";
export const SVC_GET_USER = "api/User/GetUser";
export const SVC_GET_UPDATEEULADATE = "api/User/UpdateEulaAcceptedDate";
export const SVC_CONTACTUS_REQUEST = "api/Email/RequestContactUs";
export const SVC_GENERATE_SEND_CODE = "/api/generateCode";
export const SVC_VALIDATE_SENT_CODE = "/api/validateCode";
export const SVC_CREATE_USER = "/api/createUser";
export const SVC_UPDATE_USER = "/api/UpdateProfile";
export const SVC_UPDATE_USER_PROFILE = "/api/UpdateProfileClient";
export const SVC_GET_CONTENT = "/api/getContent";
export const SVC_GET = "/api";
export const SVC_GET_DROPDOWN_CONTENT = "/api/getDropDownContent";
export const SVC_GET_NESTED_DROPDOWN_CONTENT = "/api/getNestedDropDownContent";
export const SVC_VALIDATE_USER = "/api/CheckUser";
export const SVC_UPDATE_USERNAME = "/api/UpdateUsername";
export const SVC_GET_VAC_PRODUCT_INFO = "/api/GetVacProductInfo";
export const SVC_GET_QUESTIONNAIRE_CONTENT =
  "/api/getAdditionalWoundQuestionaries";
export const SVC_GET_ALERTS = "/api/GetAlerts";
export const SVC_STOP_SHARE_ORDER = "api/Order/CancelSharedVacOrder";
export const SVC_GET_PATIENT_PRODUCT_INFO = "/api/pickupOrDischarge";
export const SVC_VALIDATE_UPS_ADDRESS = "/api/validateUpsAddress";
export const SVC_STATIC_CONTENT = "/api/getStaticContent";
export const SVC_REMOVE_PATIENT = "api/Provider/RemovePatient";
export const SVC_GET_VAC_DRESSING_PRODUCTS = "/api/GetVacProductList";
export const SVC_SHARE_ORDER_EMAIL_REGISTRATION = "api/Email/SendEmail";
export const SVC_GET_REPLENISHSUPPLYORDER = "api/Order/GetReplenishSupplyOrder";
export const SVC_GET_VACORDERLOCKSTATUS = "api/Order/LockVacOrder";
export const SVC_GET_SERVERDATETIME = "/api/GetCurrentDateTime";
export const SVC_SUBMIT_INVENTORY_ADJUSTMENT = "api/email/SendEmail";
export const EXECUTE_CAPTCHA_ACTION = "my_captcha_run";
export const COMMON_DOCS_INSURANCE_AUTHORIZATION_FORM =
  "insuranceAuthorizationForm";
export const INVALID_USERNAME = "Invalid user name";
export const INVALID_EMAIL = "Invalid email address";
export const UNMATCHED_EMAIL = "Email and verify email should match";
export const SOLVENTUM_EMAIL_VALIDATION =
  "Solventum users may not register as an external user, please use SSO";
export const UNMATCHED_PASSWORD =
  "New password and confirm password should match";
export const SC_HELPLINE_PHONENUMBER = "HelplinePhoneNumber";
export const NO_PATIENT_FOUND = `No patients found. Please use "Add Patient" to manage a patient under your care.`;
export const SEARCH_PAYER = "/api/search/payer";
export const SVC_SEARCH_CAREGIVER_FACILITY = "/api/search/HomeCareProvider";
export const SVC_GET_VAC_ORDER_SUMMARY_INFO = "api/Order/GetVacOrderSummary";
export const SVC_GET_USER_ROLES_PERMISSION = "api/User/GetUserRolePermissions";
export const SVC_SEARCH_PRESCRIBER = "api/search/prescribers";
export const SVC_NPI_PRESCRIBER = "/api/SearchPrescriberInNPIRegistry";
export const SVC_SEARCH_HOMECAREFACILITY = "api/search/HomeCareFacility";
export const SVC_SAVE_VAC_ORDER = "api/Order/SaveVacOrder";
export const SVC_UPDATE_VAC_ORDER = "api/Order/UpdateVacOrder";
export const TEMP_SHAREORDER_INVITE = "REQUEST_REGISTRATION_SHARED_ORDER";
export const SVC_GET_USER_LINKED_FACILITY = "api/User/GetUserLinkedFacility";
export const SVC_CANCEL_SUBMIT_VAC_ORDER = "api/Order/DeleteOrSubmitVacOrder";
export const SVC_GET_WOUND_LIST = "/api/getWoundList";
export const SVC_UPLOAD_DOCUMENT = "api/Document/UploadDocument";
export const SVC_PRINT_ORDER_SUMMARY_PDF = "api/Order/GetPdfContent";
export const SVC_GET_FACILITY_PATIENT = "api/Facility/Patients";
export const FILE_UPLOAD_SERVER_ERROR_MESSAGE =
  "One of your files has failed our virus scan. Please remove it from the Upload Documents section and try again to save the order.";
export const FILE_UPLOAD_SERVER_ERROR_MESSAGE_WA_UD =
  "One of your files has failed our virus scan. Please remove it from the Upload Additional Documents section and try again to save wound Assessment.";
export const FILE_UPLOAD_SERVER_ERROR_MESSAGE_WA_UI =
  "One of your files has failed our virus scan. Please remove it from the Upload Wound Images section and try again to save wound Assessment.";
export const FILE_UPLOAD_LOCAL_ERROR_MESSAGE =
  "Files must be uniquely named in one of the following formats: JPG, GIF, JPEG, PNG, DOC, DOCX, TXT, PDF or RTF and cannot exceed 15 MB (15360 KB) in size. Please correct in the Upload Documents section and try again to save the order.";
export const HOLD_THERAPY_ERROR_MESSAGE =
  "Therapy is currently not resumed for the patient order. Please first place the patient therapy on resume and then hold therapy. Please contact 3M for assistance with this order 1-800-275-4524.";
export const RESUME_THERAPY_ERROR_MESSAGE =
  "Therapy is currently not on hold for the patient order. Please first place the patient therapy on hold and then resume therapy. Please contact 3M for assistance with this order 1-800-275-4524.";
export const GET_WOUND_LIST_ERROR_MESSAGE = "No wound found.";
export const HOLD_OR_RESUME_THERAPY_ERROR_MESSAGE =
  "Your request to Hold /Resume Therapy has failed. Please try again or contact 3M for assistance with order 1-800-275-4524.";
export const NO_WOUND_ERROR_MESSAGE_FOR_HOLD_THERAPY =
  "This order currently has no recorded wound sites. A wound site is required prior to placing the order on hold. Please contact the 3M™ Express Therapy Portal support team at ";
export const NO_WOUND_ERROR_MESSAGE_FOR_DISCHARGE =
  "This order currently has no recorded wound sites. A wound site is required prior to discharge. Please contact the 3M™ Express Therapy Portal support team at ";
export const CANCEL_READY_CARE_ORDER_FAILED =
  "Your request to cancel this patient's NPWT order has failed. Please contact 3M for assistance with this order 1-800-275-4524.";
export const GET_INVENTORY_LIST_FAILED =
  "Your request to view the available inventory list has failed. Please try again or contact 3M for assistance ";
export const SAVE_DISCHARGE_FAILED =
  "Your request to submit the discharge details has failed. Please try again or contact 3M for assistance with order 1-800-275-4524.";
export const GET_SALE_PEOPLE_LIST_FAILED =
  "Your request to fetch users details has failed. Please try again or contact 3M for assistance with this user 1-800-275-4524.";
export const GET_INVENTORY_COMING_SOON_TEXT =
  "This feature and other acute facility functionality is coming later in 2024. Until then, you can use the <span style='color:#0049BD;text-decoration:underline; font-weight:700'> current 3M Express </span> to manage your stored product listing.";
export const GET_NEWORDER_COMING_SOON_TEXT =
  "This feature and other acute facility functionality is coming in Spring 2024. Until then, you can use the current 3M™ Express to place inpatient and facility orders.";
export const GET_VAC_ORDER_SUCCESS_MESSAGE =
  "A 3M Medical Solutions representative will contact you to confirm your order and request any additional documentation required.";
export const SVC_GET_PROVIDERS_FOR_FACILITY =
  "api/search/GetProvidersForFacility";
export const SVC_SHARE_VAC_ORDER = "api/Order/ShareVacOrder";
export const SVC_HOLD_OR_RESUME_VAC_THERAPY =
  "api/WoundAssessment/SaveResumeOrHoldTherapy";
export const SVC_RELEASE_ORDER_LOCK = "api/Order/ShareVacOrder";
export const SVC_GET_PATIENT_WOUND_DETAILS = "/api/getWoundDetailInfo";
export const SVC_CREATE_SUPPLY_ORDER = "api/Order/CreateSupplyOrder";
export const SVC_UPDATE_CONTACT_INFO = "/api/UpdateContactInfo";
export const SVC_VALIDATE_CODE = "/api/authentication/ValidateCode/";
export const SVC_UPDATE_USER_CONFIRMATION = "/api/UserConfirmation/";
export const SVC_CHECK_POST_ACUTE_FACILITY =
  "api/search/CheckPostAcuteFacility";
export const IDLEPOPUPTIME = 121; //for calculating after popup time
export const IDLETIME = 1920000; //for calculating 45 mins idle time //2820000
export const IDLEPOPTIMER = 120000;
export const SVC_UNLOCK_VAC_ORDER = "api/Order/UnlockOrder";
export const SVC_GET_PRESCRIPTION_TYPE = "/api/getPrescriptionDetail";
export const SVC_UPDATE_PRESCRIBER_EMAIL = "/api/SendERXRequest";
export const SVC_SAVE_FILE_TO_FILENET = "api/Document/SaveDocumentToFilenet";
export const SVC_GET_USER_FAVOURITE_FACILITIES =
  "api/Facility/GetUserFavouriteFacilities";
export const SVC_GET_ADDORUPDATEINTERNALUSER = "api/User/AddUpdateInternalUser";
export const SVC_GET_UPDATEFAVOURITE = "api/Facility/updateFavourite";
export const SVC_GET_EPRESCRIPTION_STATUS = "/api/getEPrescriptionStatus";
export const SVC_GET_PRESCRIPTION_INFO = "api/prescription/GetPresciptionInfo";
export const SVC_UPDATE_PRESCRIPTION_INFO =
  "api/prescription/UpdatePrescriptionType";
export const SVC_GET_SELECTFACILITY =
  "api/User/GetInternalUserFacilityRolePermission";
export const SVC_GET_ORDER_DETAILS_CONTENT = "/api/GetOrderStatusDetails";
export const SVC_GET_USER_TERRITORIES = "api/Territory/GetTerritories";
export const SVC_GET_SALES_TERRITORIES = "api/Territory/GetSalesTerritories";
export const SVC_GET_SALES_REGION_DETAILS = "api/Territory/GetSalesUserDetails";
export const SVC_GET_PATIENT_ADDRESSES = "/api/getPatientAddresses";
export const SVC_GET_FACILITY_SEARCH_RESULT = "api/search/Facility";
export const SVC_SEND_3M_NOTE = "api/Email/SendANote";
export const SVC_GET_FACILITY_SEARCH_RESULT_TERRITORY =
  "api/search/territoryFacility";
export const SVC_GET_FACILITY_PERMISSION = "api/Facility/GetFacilityPermission";
export const SVC_UPDATE_FACILITY_PERMISSIONS =
  "api/Facility/UpdateFacilityPermissions";
export const SVC_GET_VAC_ORDER_DETAILS = "/api/GetOrderDetails";
export const SVC_SAVE_WOUND_ASSESSMENT_ORDER =
  "api/WoundAssessment/SaveWoundAssessment";
export const SVC_GET_UNLINKED_FACILITES_COUNT =
  "api/User/GetUnlinkedFacilitiesCount";
export const SVC_GET_ORDER_SUPPLIES = "/api/GetOrderSupplies";
export const INTERNAL_SALES_MGR_ROLE_TEXT =
  "usac-ms-express-salesmgr-" + process.env.REACT_APP_OKTA_ENV;
export const INTERNAL_SALES_ROLE_TEXT =
  "usac-ms-express-sales-" + process.env.REACT_APP_OKTA_ENV;
export const SVC_GET_SUPPLY_ORDER_DETAILS = "/api/getSupplyOrderDetails";
export const MissingRx_Alert_Reason_Text =
  "A prescription has not been received and validated for this order.";
export const Proof_of_Delivery_Needed_Alert_Reason_Text =
  "Complete the Proof of Delivery form for this patient.";
export const Pending_Supply_Order_Alert_Reason_Text =
  "Supplies are outside of the anniversary time frame. Please provide an Excessive Supply Form.";
export const Confirm_Placement_Alert_Reason_Text =
  "Confirm the placement of this patients V.A.C.® Therapy Unit.";
export const SVC_CANCEL_READY_CARE_ORDER = "api/Order/CancelReadyCareOrder";
export const SVC_FINANCIAL_INFO = "/api/GetPatientFinancialInfo";
export const SVC_GET_INVENTORY_LIST = "/api/getInventoryByFacility";
export const SVC_GET_FACILITY_STORAGE_LOCATION_LIST =
  "/api/GetFacilityStorageLocation";
export const SVC_UPDATE_INVENTORY_LOCATION = "/api/UpdateStorageLocation";
export const SVC_ENABLE_OR_DISABLE_FACILITY_STORAGE_LOCATIONS =
  "/api/EnableOrDsiableFacilityStorageLocation";
export const SVC_PRINT_SHIPMENT_LABEL_PDF = "/api/GetShippingLabel";
export const SVC_GENERATE_FAX_COVERSHEET = "/api/GeneratePrintFaxCoverSheet";
export const SVC_GET_ALL_DOCUMENTS = "/api/GetAllDocuments";
export const SVC_UPLOAD_ALL_DOCUMENTS = "/api/UploadDocumentsToIFace";
export const SVC_GET_PRODUCT_COROUSEL_IMAGE =
  "/api/getProductsSolutionsContent/Product_CarouselImage";
export const SVC_GET_PRODUCT_CATEGORIES = "/api/GetProductCategories";
export const SVC_GET_PRODUCTS_LIST = "/api/GetProductList";
export const SVC_GET_PRODUCTS_DETAIL =
  "/api/woundProductDetail/getProductDetails";
export const SVC_GET_ACTIVE_PAGE_CONFIGURATION =
  "/api/woundAlgorithm/getActivePageConfiguration";
export const SVC_GET_WTG_CONTENT = "/api/cms/getWoundThearpyGuideContent";
export const SVC_GET_PRODUCTS_LINKED_WITH_TREATMENTS =
  "/api/woundAlgorithm/getProductsLinkedWithTreatments";
export const SVC_GET_RELATED_PRODUCTS_PARAMETERS =
  "/api/woundAlgorithm/getRelatedProductParameters";
export const SVC_GET_DOCUMENT_CONTENT = "/api/GetDocumentContent";
export const SVC_GET_FACILITY_SETTING_PERMISSION =
  "api/Facility/GetFacilitySettingPermission";
export const SVC_GET_UPDATEFACILITY_SETTING_PERMISSION =
  "api/Facility/UpdateFacilitySettingPermission";
export const WTG_SVC_POST_WOUND_PRODUCTS = "/getWoundProducts";
export const SVC_SAVE_DISCHARGE = "api/PickupOrDischarge/SaveDischargeRequest";
export const SVC_GET_ALL_ALERTS = "/api/getAlertsForRO";
export const SVC_GET_CHANGE_PASSWORD = "/api/changepassword";
export const SVC_GET_AUTHENTICATION_CHANNEL = "/api/AutheticationChannel/";
export const SVC_GET_VALIDATE_CODE = "/api/authentication/ValidateCode/";
export const SVC_GET_GET_USER_CONTACT_INFO = "api/User/GetUserContactInfo";
export const SVC_GET_SINGLE_PATIENT = "api/facility/GetPatient";
export const SVC_GET_FACILITY_DETAILS = "api/Facility/GetFacilityDetails";
export const SVC_SET_TEMP_PASSWORD = "/api/SetTemporaryPassword";
export const SVC_GET_USER_DETAILS = "api/User/GetUserDetails";
export const SVC_GET_USER_MANAGER_LIST = "/api/FetchFacilityUsers";
export const SVC_GET_UPDATE_USER_DETAILS = "/api/UpdateUserDetails";
export const SVC_GET_SINGLE_PATIENT_MENU_ACTION =
  "api/facility/getPatientMenuAction";
export const SVC_GET_ADD_USER_DETAILS = "/api/AddUserByAdmin";
export const SVC_SAVE_PICKUP = "api/PickupOrDischarge/SavePickUpRequest";
export const SVC_GET_SALES_USERS_FOR_REGION =
  "api/Territory/GetSalesUsersForRegion";
export const SVC_GET_SALES_USERS_FOR_AREA =
  "api/Territory/GetSalesUsersForArea";
export const SVC_POD_SEND_EPRISCRIPTION = "/api/EPohdRequest";
export const SVC_POD_GENERATE_PDF = "/api/GeneratePodPdf";
export const SVC_ASSIGN_TERRITORIES = "api/Territory/AssignTerritories";
export const SVC_GET_SALES_TERRITORY_FOR_REGION =
  "api/Territory/GetSalesTerritoryDetailsForRegion";
export const SVC_GET_SALES_TERRITORY_FOR_AREA =
  "api/Territory/GetSalesTerritoryDetailsForArea";
export const SUPPLYORDER_SUMMARY_HEADERTEXT =
  "You will recieve order updates and alert if any additional information is required to process your order.";
export const CONTACT_3M_EXPRESS =
  "For any questions regarding your account, please contact 3M™ Express Therapy Portal support team at ";
export const CONFIRM_PLACEMENT_SERAIL_NUMBER_FETCH_ERRORTEXT =
  "Your request to select a Ready Care Serial Number for this patient has failed. Please try again or contact 3M for assistance with this user 1-800-275-4524.";
export const CONFIRM_PLACEMENT_ERRORTEXT =
  "Your request to confirm placement for the selected patient and product has failed. Please try again or contact 3M for assistance ";
export const CONFIRM_PLACEMENT_DB_UPDATE_ERRORTEXT =
  "The request to close the Confirm Placement Alert has failed. Please try again later or contact 3M™ Express Therapy Portal support team at ";
export const CONFIRM_PLACEMENT_SERAIL_NUMBER_NOT_PRESENT =
  "There is currently no 3M™ ActiV.A.C.™ Therapy Units available in this facilities 3M™ V.A.C.® Ready Care Program Inventory to assign to this patient.";
export const Contact_US =
  "Please contact 3M Medical Solutions at 1-800-275-4524 for assistance.";
export const CONFIRM_PLACEMENT_RESTRICTION_ERRORMESSAGE =
  "The Confirm Placement task has already been completed for this patient.";
export const SVC_CONFIRM_PLACEMENT = "/api/ConfirmPickup ";
export const SVC_SEARCH_USERS = "api/User/SearchUser";
export const SVC_AUDIT_HISTORY_DROPDOWN =
  "api/Territory/GetSalesAuditHistoryDropDowns";
export const SVC_AUDIT_HISTORY = "api/Territory/GetSalesAuditHistory";
export const SVC_AUDIT_HISTORY_CSV =
  "api/Territory/DownloadSalesAuditHistoryData";
export const SVC_RETRIVE_WOUND_ASSESMENT_PHOTOS =
  "api/WoundAssessment/getWoundAssessmentPhotos";
export const SVC_CLOSE_SUPPLIES_DELIVERED = "/api/alert/supplyorder/close";
export const SVC_GET_SALES_AREA_LIST = "api/Territory/GetAreas";
export const DD_DISPLAY_ORDER_STATUS = "DisplayOrderStatus";
export const CMS_ORDER_SOURCE_CONTENT = "OrderSourceTypes";
export const DD_DISPLAY_ORDER_TYPES = "DisplayOrderType";
export const SVC_UPDATE_INTERNAL_USERS = "api/User/UpdateUser";
export const SVC_GET_VTIAF_PDF_CONTENT = "/api/VTIAFPdfContent";
export const SVC_GET_UNLINKED_USERS = "api/User/GetUserUnLinkedFacilites";
export const SC_MEDICALSOLUTION_PHONENUMBER = "MedicalSolutions";
export const STATIC_DEVICE_SUPPORT_NUMBER = "DeviceTroubleshootingSupport";
export const SVC_LINK_TO_FACILITY = "api/Facility/LinkFacility";
export const SVC_GET_KEYVAULT_CONTENT = "/api/getKeyValutContent";
export const SVC_GET_HOME_CARE_ORDER_SEARCH = "/api/OrderSearch";
export const SVC_UPDATE_PRESCRIBER = "api/Prescriber/UpdatePrescriber";
export const SVC_GET_HOME_CARE_PROVIDER_FACILITY =
  "api/Facility/GetHomeCareProviders";
export const SVC_UPDATE_HOMECAREPROVIDER_STATUS =
  "api/Facility/UpdateHomeCareProviderStatus";
export const SVC_ADD_PRESCRIBER_INFO = "api/prescriber/AddPrescriberInfo";
export const SVC_UPDATE_PRESCRIBER_INFO =
  "api/Prescriber/UpdatePrescriberStatus";
export const SVC_ADD_HOMECARE_PROVIDER = "api/Facility/AddHomeCareProvider";
export const SVC_GET_ORDER_BY_TERRITORY = "/api/getInprocessOrdersReport";
export const SVC_GET_ORDER_BY_TERRITORY_CSV =
  "/api/DownloadInProcessOrdersReport";
export const SVC_GET_CSV_DOWNLOAD = "/api/DownloadCSV";
export const SVC_GET_FACILITY_ADMIN_DETAILS =
  "api/Facility/GetFacilityAdminDetails";
export const SVC_GET_ACUTEORDER_RO = "/api/GetAcuteOrder";
export const SVC_UPDATE_GUIDENCE_POPUP = "api/Provider/UpdateGuidancePopup";
export const SVC_GENERATE_PRESCRIPTION_PDF_FOR_NON_3ME_VAC =
  "/api/GeneratePrescriptionPadPdf";
export const SVC_GET_SITE_STATUSES = "/api/GetSiteStatus";
export const SVC_GET_APPLICATION_SITE_STATUSES =
  "/api/GetApplicationSiteStatus";
export const SVC_ADD_SITE_STATUS = "/api/AddSiteStatus";
export const SVC_UPDATE_SITE_STATUS = "/api/UpdateSiteStatus";
export const SVC_SUBMIT_EXCHANGE_VAC = "/api/exchangeVac";
export const SVC_POS_GENERATE_PDF = "/api/generateProofOfServicePdf";
export const SVC_ADD_STORAGE_LOCATIONS = "/api/AddFacilityStorageLocation";
export const SVC_UPDATE_STORAGE_LOCATION_NAME =
  "/api/UpdateFacilityStorageLocationName";
export const SVC_INVENTORY_MOVE_PRODUCT = "/api/MoveInventoryProduct";
export const SVC_RETURN_SOLVENTUM_UNIT = "/api/returnToSolventum";
export const SVC_ADD_OR_UPDATE_CUSTOMER_PLUS =
  "/api/AddOrUpdateCustomerPlusDetails";
export const SVC_SUBMIT_CREATE_SERVICE_REQUEST =
  "/api/CreateWorkOrderServiceRequest";
export const SVC_GET_CUSTOMERPLUS_ACUTEORDER_DETAILS =
  "api/Order/GetCustomerPlusPatientDetails";
export const SVC_GET_PRODUCT_NAME_AND_IMAGE_LINK =
  "/api/GetProductNameAndImageLink";
export const SVC_GET_WOUND_ASSESSMENT_REPORTS =
  "api/WoundAssessment/GetWoundAssessmentReport";
export const SVC_GET_ACUTE_SUPPLY_ORDER_DETAILS =
  "/api/GetAcuteSupplyOrderDetails";
export const STATIC_TEXT_INVENTORY_RETURN_TO_SOLVENTUM =
  "The Return Unit process is designed to request the return of broken units. If you wish to return this unit for a different reason, please select 'Cancel' and call Solventum at 1-800-275-4524 to submit your request.";
export const STATIC_TEXT_INVENTORY_ADJUSTMENT_SUCCESS_MSG =
  "Thank you for submitting your request. Your request will be reviewed by the V.A.C.<sup>®</sup> Ready Care Program administrators. You will receive a response within 5-7 business days of your request.";
export const STATIC_TEXT_INVENTORY_ADJUSTMENT_DESCRIPTION_ONE_MSG =
  "Please complete this form if you would like to increase or decrease the inventory stock level of ActiV.A.C. Therapy Units used for the V.A.C.<sup>®</sup> Ready Care Program in your facility. A change to your inventory stock level may affect the frequency of replenishment and will also adjust your disposable inventory level.";
export const STATIC_TEXT_INVENTORY_ADJUSTMENT_DESCRIPTION_TWO_MSG =
  "If you are in immediate need of units, contact the 3M™ Express Therapy Portal Support team at ";
export const SVC_GET_CUSTOMER_PLUS_LIST = "/api/GetCustomerPlusDetails";
export const STATIC_TEXT_END_THERAPY_POPUP_DESC =
  "Ending Therapy will return the device to your Customer Plus inventory where it will need to be prepared before it can be placed in your list of available units.";
export const STATIC_TEXT_END_THERAPY_SUCCESS =
  " Success! The Customer Plus unit has been returned to your inventory for preparation.";
export const STATIC_TEXT_MAKE_UNIT_AVAILABLE_SUCCESS =
  " Success! The Status for the Customer Plus unit has been updated.";
export const STATIC_TEXT_MY_LISTS_INFO_TOOLTIP =
  "The storage location cannot be disabled if inventory units are currently assigned to the specific storage location.";
export const DEFAULT_DRESSING_KIT_KEY = "DefaultDressingKit";
export const SVC_SUBMIT_ACUTE_ORDER = "/api/SubmitAcuteOrder";
export const SVC_GET_CUSTOMER_PLUS_PATIENT_DATA =
  "/api/GetCustomerPlusPatientInfo";
export const SVC_GET_CUSTOMER_INFORMATION = "/api/getCustomerInformation";
export const SVC_RENTAL_PO_DETAILS = "/api/getRentalPODetails ";
export const BILLING_INFO_API_FAILURE_MESSAGE =
  "Your request to retrieve the Customer Billing information has failed. Please try again later or contact 3M™ Express Therapy Portal support team at";
export const BILLING_INFO_POREQUIRED_TRUE =
  "Purchase Order is required for billing. For any further purchase order questions, please contact 3M Medical Solutions at 1-800-275-4524.";
export const BILLING_INFO_POREQUIRED_FALSE =
  "Note: To change your blanket purchase order number on file, please contact 3M Medical Solutions at 1-800-275-4524.";
export const SVC_GET_SOLVENTUM_PRODUCTS = "/api/getAcuteProducts";
// Define the desired category order
export const categoryOrder = ["GENERAL", "ORDER_PLACEMENT", "INVENTORY"];
export const Incomplete_Wound_Alert_Reason_Text =
  "Order has been successfully submitted, but wound information was not. Resubmit wound details to update";
export const SVC_RESUBMIT_ACUTE_WOUND_INFORMATION = "/api/ReSubmitWoundInfo";
export const SVC_RESUBMIT_ACUTE_ORDER_INFORMATION = "/api/ReSubmitAcuteOrder";
export const SVC_GET_ACUTEORDER_SUMMARY = "api/Order/GetAcuteOrderSummary";
export const SVC_GET_FACILITY_SUPPLYORDER = "/api/getFacilitySupplyOrder";
export const SVC_GET_PRODUCT_PRICE_AND_AVAILABILITY =
  "/api/CheckProdutPriceAndAvailability";
export const DD_VAC_ORDER_PRODUCT_TYPE = "VacOrderProductType";
export const DD_INPATIENT_STOCK_SUPPLY_TYPE = "VacOrderSupplyType";
export const ERROR_MESSAGE_POD_PDF_NO_PAYOR_DETAILS =
  "The Payor details are not available at this time for the Proof of Delivery PDF. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MESSAGE_POD_PDF =
  "Your request to print the Proof of Delivery for the patient has failed. Please try again or contact 3M for assistance with this facility ";
export const IN_PATIENT_SUPPLY_ORDER_DRESSING_GUIDE_LINK =
  "https://multimedia.3m.com/mws/media/2054203O/3m-wound-care-product-selection-guide.pdf";
export const DD_FACILITY_SUPPLY_ORDER_TYPES = "FacilitySupplyOrderTypes";
export const DD_FACILITY_SUPPLY_ORDER_STATUSES = "FacilitySupplyOrderStatuses";
export const MAX_AVAILABLE_CART_QUANTITY = 50;
export const SVC_GET_ORDER_SUPPLIES_BY_FACILITY =
  "/api/GetOrderSuppliesByFacility";
export const SVC_GET_ACUTE_ORDER_SUPPLIES_DELIVERY_DETAILS =
  "/api/GetAcuteSupplyOrderDeliveryDetails";
export const SVC_GET_ACUTE_ORDER_SUPPLIES_SHIPPING_COST_DETAILS =
  "/api/GetAcuteSupplyOrderShippingCost";
export const SVC_SUBMIT_ACUTE_SUPPLY_ORDER = "/api/SubmitAcuteSupplyOrder";
export const STATIC_TEXT_FOR_CUSTOMER_OWNED_PRODUCT =
  "Your facility has responsibility for cleaning unit upon removal from the patient. 	If repair is required, please select 'Return Unit to 3M' from the Stored Product 	Listing (available after storage location is updated).";
export const SVC_SALES_ASSISTANCE_SEND_MESSAGES =
  "api/Email/SendSalesAssistanceNote";
export const STATIC_TEXT_EXPRESS_THERAPY_SOURCE_CODE = "*XP";
export const SVC_UPDATE_ACUTE_ORDER = "/api/UpdateAcuteOrder";
export const CMS_DRESSING_SIZE_PDF = "DressingSizeGuidePDF";
export const SVC_SRT_FACILITY_ACUTE_PATIENTS = "api/SRT/GetAcutePatients";
