import { format } from "react-string-format";
import { ISubmitAcuteOrderRequest } from "../components/inpatientOrder/components/mapper/inpatientOrder.request.interface";
import { getKeyData } from "./encryptDecrypt";
import {
  SVC_GET_ACUTE_SUPPLY_ORDER_DETAILS,
  SVC_GET_ACUTEORDER_RO,
  SVC_GET_ACUTEORDER_SUMMARY,
  SVC_GET_CUSTOMER_PLUS_PATIENT_DATA,
  SVC_GET_CUSTOMERPLUS_ACUTEORDER_DETAILS,
  SVC_GET_ORDER_SUPPLIES_BY_FACILITY,
  SVC_GET_PRODUCT_NAME_AND_IMAGE_LINK,
  SVC_SUBMIT_ACUTE_ORDER,
  SVC_SUBMIT_ACUTE_SUPPLY_ORDER,
  SVC_UPDATE_ACUTE_ORDER,
} from "./staticText";

export const getAcuteOrderRoDetails = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_ORDER_MGR_FUNC_BASE_URL ?? "";
    const getFacilityInfoUrl = format("{0}{1}", baseUrl, SVC_GET_ACUTEORDER_RO);
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = accessToken
      ? format("Bearer {0}", accessToken)
      : "";
    const funckeyParse = getKeyData();
    const response = await fetch(getFacilityInfoUrl, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.OrderManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      console.log(response);
      return undefined;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getCustomerPlusAcuteOrderRoDetails = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_3ME_SVC_BASE_URL ?? "";
    const getFacilityInfoUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_GET_CUSTOMERPLUS_ACUTEORDER_DETAILS
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = accessToken
      ? format("Bearer {0}", accessToken)
      : "";
    const response = await fetch(getFacilityInfoUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getAcuteProductImageLink = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_ORDER_MGR_FUNC_BASE_URL ?? "";
    const getProductImageLinkUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_GET_PRODUCT_NAME_AND_IMAGE_LINK
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = accessToken
      ? format("Bearer {0}", accessToken)
      : "";
    const funckeyParse = getKeyData();
    const response = await fetch(getProductImageLinkUrl, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.OrderManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const submitAcuteOrderDetails = async (
  reqParam: ISubmitAcuteOrderRequest
) => {
  try {
    const baseUrl = process.env.REACT_APP_ORDER_MGR_FUNC_BASE_URL ?? "";
    const submitAcuteOrderdetailsURL = format(
      "{0}{1}",
      baseUrl,
      SVC_SUBMIT_ACUTE_ORDER
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(submitAcuteOrderdetailsURL, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.OrderManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParam),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getCustomerPlusPatientInfo = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_ORDER_MGR_FUNC_BASE_URL ?? "";
    const getCustomerPlusPatientDataURL = format(
      "{0}{1}",
      baseUrl,
      SVC_GET_CUSTOMER_PLUS_PATIENT_DATA
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(getCustomerPlusPatientDataURL, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.OrderManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getAcuteOrderSummary = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_3ME_SVC_BASE_URL ?? "";
    const getFacilityInfoUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_GET_ACUTEORDER_SUMMARY
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = accessToken
      ? format("Bearer {0}", accessToken)
      : "";
    const response = await fetch(getFacilityInfoUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getOrderSuppliesByFacility = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_PRODUCT_MGR_FUNC_BASE_URL ?? "";
    const getOrderSuppliesUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_GET_ORDER_SUPPLIES_BY_FACILITY
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = accessToken
      ? format("Bearer {0}", accessToken)
      : "";
    const funckeyParse = getKeyData();
    const response = await fetch(getOrderSuppliesUrl, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.ProductManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getAcuteSupplyOrderDetails = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_ORDER_MGR_FUNC_BASE_URL ?? "";
    const getAcuteSupplyOrderDetailsURL = format(
      "{0}{1}",
      baseUrl,
      SVC_GET_ACUTE_SUPPLY_ORDER_DETAILS
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(getAcuteSupplyOrderDetailsURL, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.OrderManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const submitAcuteSupplyOrderDetails = async (reqParam: any) => {
  try {
    const baseUrl = process.env.REACT_APP_ORDER_MGR_FUNC_BASE_URL ?? "";
    const submitAcuteOrderdetailsURL = format(
      "{0}{1}",
      baseUrl,
      SVC_SUBMIT_ACUTE_SUPPLY_ORDER
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(submitAcuteOrderdetailsURL, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.OrderManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParam),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};
export const updateAcuteOrder = async (reqParam: any) => {
  try {
    const baseUrl = process.env.REACT_APP_ORDER_MGR_FUNC_BASE_URL ?? "";
    const updateAcuteOrderUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_UPDATE_ACUTE_ORDER
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(updateAcuteOrderUrl, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.OrderManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParam),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};
