import _ from "underscore";
import {
  defaultDeliveryInformation,
  defaultDressingKit,
  defaultNewOrderData,
  defaultProductInfo,
  defaultRequesterInfo,
} from "../../newOrder.model";
import {
  ICanister,
  IDeliveryInformation,
  IDressingKit,
  INewOrder,
  IProductAccessory,
  IProductInfo,
  IRequesterInfo,
} from "../../newOrder.interface";
import {
  osteomyelitisies,
  previousTherapy,
  previousTherapyCause,
} from "../../previousTherapies/previousTherapiesData";
import {
  Question,
  WoundQuestionaries,
} from "../../clinicalInformation/clinicalInfo.interface";
import { Wound } from "../newOrderRequestMapper.interface";
import { NewOrderValidator } from "../../newOrder.validator";
import { getDeepClone } from "../../../../util/ObjectFunctions";
import { getUntouchedObj } from "./../../../../util/utilityFunctions";
import { nutriActionData } from "../../nutrition/nutritionAction.data";
import { VacOrderSummaryData } from "./newOrderResponseMapper.interface";
import { comorbiditiesData } from "../../comorbodities/comorbodities.data";
import {
  IInputField,
  ValidationStatus,
} from "../../../../core/interfaces/input.interface";
import {
  ShowAdditionalFields,
  defaultPrimaryInsuranceData,
  defaultSecondaryInsuranceData,
} from "../../insuranceInformation/insuranceInformation/insuranceInformation.model";
import {
  IInsuranceInformation,
  IPayerIDAllDetails,
  IPayerIDDetails,
} from "../../insuranceInformation/insuranceInformation/insuranceInformation.interface";
import { IFacility } from "../../../manageProfile/facilityInformation/facility.interface";
import { INewOrderWoundInfo } from "../../newOrderWoundInfoStepper/newOrderWoundInfo.interface";
import { defaultNewOrderWoundInfoData } from "../../newOrderWoundInfoStepper/newOrderWoundInfo.model";
import { ISecondaryWoundInfo } from "../../clinicalInformation/secondaryWoundInfo/secondaryWoundInfo.interface";
import { defaultNewOrdeSecondaryWoundInfoData } from "../../clinicalInformation/secondaryWoundInfo/secondaryWoundInfo.model";
import { InsuranceInformationValidator } from "../../insuranceInformation/insuranceInformation/insuranceInformation.validator";
import { AnyNaptrRecord } from "dns";

export const mapVacOrderResponse = async (
  vacOrderSummarInfo: VacOrderSummaryData,
  insuranceTypes: any,
  setShowAdditionalObject: any,
  providerTypes: any,
  Validator = new NewOrderValidator(),
  AuthObj: any,
  ValidatorInsurance = new InsuranceInformationValidator()
) => {
  let defaultOrderData: INewOrder = getDeepClone(defaultNewOrderData);
  let newOrder: INewOrder = {
    ...defaultOrderData,

    // Patient Information
    firstName: {
      ...defaultOrderData.firstName,
      valid: Validate(
        Validator,
        "patientinfofirstName",
        vacOrderSummarInfo.customerFirstName
      )!,
      value: vacOrderSummarInfo.customerFirstName,
    },
    lastName: {
      ...defaultOrderData.lastName,
      valid: Validate(
        Validator,
        "patientinfolastName",
        vacOrderSummarInfo.customerLastName
      )!,
      value: vacOrderSummarInfo.customerLastName,
    },
    dob: {
      ...defaultOrderData.dob,
      valid: Validate(Validator, "dob", vacOrderSummarInfo.customerDOB)!,
      value: vacOrderSummarInfo.customerDOB,
    },
    phone: {
      ...defaultOrderData.phone,
      valid: Validate(Validator, "phone", vacOrderSummarInfo.customerPhoneNo)!,
      value: vacOrderSummarInfo.customerPhoneNo,
    },
    address1: {
      ...defaultOrderData.address1,
      valid: Validate(
        Validator,
        "address1",
        vacOrderSummarInfo.permanentAddress.addressLine1
      )!,
      value: vacOrderSummarInfo.permanentAddress.addressLine1,
    },
    address2: {
      ...defaultOrderData.address2,
      valid: Validate(
        Validator,
        "address2",
        vacOrderSummarInfo.permanentAddress.addressLine1
      )!,
      value: vacOrderSummarInfo.permanentAddress.addressLine2,
    },
    city: {
      ...defaultOrderData.city,
      valid: Validate(
        Validator,
        "city",
        vacOrderSummarInfo.permanentAddress.city
      )!,
      value: vacOrderSummarInfo.permanentAddress.city,
    },
    state: {
      ...defaultOrderData.state,
      valid: Validate(
        Validator,
        "state",
        vacOrderSummarInfo.permanentAddress.stateCode
      )!,
      value: vacOrderSummarInfo.permanentAddress.stateCode,
    },
    zip: {
      ...defaultOrderData.zip,
      valid: Validate(
        Validator,
        "zip",
        vacOrderSummarInfo.permanentAddress.postalCode
      )!,
      value: vacOrderSummarInfo.permanentAddress.postalCode,
    },
    email: {
      ...defaultOrderData.email,
      valid: Validate(
        Validator,
        "email",
        vacOrderSummarInfo.customerEmailAddress
      )!,
      value: vacOrderSummarInfo.customerEmailAddress,
    },

    // Patient’s Current Address
    IsSamePermanentAddress: {
      ...defaultOrderData.IsSamePermanentAddress,
      valid: ValidationStatus.VALID,
      value: compareAddress(
        vacOrderSummarInfo.permanentAddress,
        vacOrderSummarInfo.currentAddress
      ),
    },
    patientCurrentAddressPhone: {
      ...defaultOrderData.patientCurrentAddressPhone,
      valid: Validate(
        Validator,
        "patientCurrentAddressPhone",
        vacOrderSummarInfo.currentPhoneNumber
      )!,
      value: vacOrderSummarInfo.currentPhoneNumber,
    },
    patientCurrentAddress1: {
      ...defaultOrderData.patientCurrentAddress1,
      valid: Validate(
        Validator,
        "patientCurrentAddress1",
        vacOrderSummarInfo.currentAddress.addressLine1
      )!,
      value: vacOrderSummarInfo.currentAddress.addressLine1,
    },
    patientCurrentAddress2: {
      ...defaultOrderData.patientCurrentAddress2,
      valid: Validate(
        Validator,
        "patientCurrentAddress2",
        vacOrderSummarInfo.currentAddress.addressLine2
      )!,
      value: vacOrderSummarInfo.currentAddress.addressLine2,
      isDefaultValid: true,
    },
    patientCurrentAddressCity: {
      ...defaultOrderData.patientCurrentAddressCity,
      valid: Validate(
        Validator,
        "patientCurrentAddressCity",
        vacOrderSummarInfo.currentAddress.city
      )!,
      value: vacOrderSummarInfo.currentAddress.city,
    },
    patientCurrentAddressState: {
      ...defaultOrderData.patientCurrentAddressState,
      valid: Validate(
        Validator,
        "patientCurrentAddressState",
        vacOrderSummarInfo.currentAddress.stateCode
      )!,
      value: vacOrderSummarInfo.currentAddress.stateCode,
    },
    patientCurrentAddressZip: {
      ...defaultOrderData.patientCurrentAddressZip,
      valid: Validate(
        Validator,
        "patientCurrentAddressZip",
        vacOrderSummarInfo.currentAddress.postalCode
      )!,
      value: vacOrderSummarInfo.currentAddress.postalCode,
    },

    // Emergency Contact Info (recommended)
    emergencyContactFirstName: {
      ...defaultOrderData.emergencyContactFirstName,
      valid: Validate(
        Validator,
        "emergencyContactFirstName",
        vacOrderSummarInfo.emergencyContactFirstName
      )!,
      value: vacOrderSummarInfo.emergencyContactFirstName,
    },
    emergencyContactLastName: {
      ...defaultOrderData.emergencyContactLastName,
      valid: Validate(
        Validator,
        "emergencyContactLastName",
        vacOrderSummarInfo.emergencyContactLastName
      )!,
      value: vacOrderSummarInfo.emergencyContactLastName,
    },
    emergencyContactPhoneNumber: {
      ...defaultOrderData.emergencyContactPhoneNumber,
      valid: Validate(
        Validator,
        "emergencyContactPhoneNumber",
        vacOrderSummarInfo.emergencyContactPhoneNumber
      )!,
      value: vacOrderSummarInfo.emergencyContactPhoneNumber,
    },

    // Primary Insurance Information
    primaryInsurance: primaryInsurace(
      vacOrderSummarInfo.primaryInsurance,
      insuranceTypes,
      setShowAdditionalObject,
      "primary",
      ValidatorInsurance
    ),

    // Secondary Insurance Information
    isSecondaryInsurancePresent: {
      valid: ValidationStatus.VALID,
      value: checkIsSecondaryPresent(vacOrderSummarInfo),
      isDefaultValid: true,
    },
    secondaryInsurance:
      vacOrderSummarInfo.secondaryInsurance === null
        ? defaultSecondaryInsuranceData
        : primaryInsurace(
            vacOrderSummarInfo.secondaryInsurance,
            insuranceTypes,
            setShowAdditionalObject,
            "secondary",
            ValidatorInsurance
          ),

    // Contributing Cause
    contributingCause: {
      ...defaultOrderData.contributingCause,
      valid:
        vacOrderSummarInfo.contributingCause === null
          ? ValidationStatus.UNTOUCHED
          : ValidationStatus.VALID,
      value:
        vacOrderSummarInfo.contributingCause === null
          ? ""
          : vacOrderSummarInfo.contributingCause === true
          ? "yes"
          : "no",
    },
    woundType: {
      ...defaultOrderData.woundType,
      valid: Validate(
        Validator,
        "woundType",
        vacOrderSummarInfo.primaryWound.type === null
          ? ""
          : vacOrderSummarInfo.primaryWound.type?.toString()
      )!,
      value:
        vacOrderSummarInfo.primaryWound.type === null
          ? ""
          : vacOrderSummarInfo.primaryWound.type?.toString(),
      required: true,
    },
    woundLocation: {
      ...defaultOrderData.woundLocation,
      valid: Validate(
        Validator,
        "woundLocation",
        vacOrderSummarInfo.primaryWound.location === null
          ? ""
          : vacOrderSummarInfo.primaryWound.location?.toString()
      )!,
      value:
        vacOrderSummarInfo.primaryWound.location === null
          ? ""
          : vacOrderSummarInfo.primaryWound.location?.toString(),
      required: true,
    },
    dateOfAccident: {
      ...defaultOrderData.dateOfAccident,
      required: vacOrderSummarInfo.contributingCause === true,
      valid: Validate(
        Validator,
        "dateOfAccident",
        vacOrderSummarInfo.clinicalInformation.contributingCauseAccidentDate ??
          ""
      )!,
      value:
        vacOrderSummarInfo.clinicalInformation.contributingCauseAccidentDate ??
        "",
    },
    accidentType: {
      ...defaultOrderData.accidentType,
      required: vacOrderSummarInfo.contributingCause === true,
      valid: Validate(
        Validator,
        "accidentType",
        vacOrderSummarInfo.clinicalInformation.contributingCauseAccidentType ??
          ""
      )!,
      value:
        vacOrderSummarInfo.clinicalInformation.contributingCauseAccidentType ??
        "",
    },

    // Therapy Information
    lengthOfTherapy: {
      ...defaultOrderData.lengthOfTherapy,
      valid: Validate(
        Validator,
        "lengthOfTherapy",
        vacOrderSummarInfo.therapyDuration === 0
          ? ""
          : vacOrderSummarInfo.therapyDuration.toString()
      )!,
      value:
        vacOrderSummarInfo.therapyDuration === 0
          ? ""
          : vacOrderSummarInfo.therapyDuration.toString(),
    },
    goalOfTherapy: {
      ...defaultOrderData.goalOfTherapy,
      valid: Validate(
        Validator,
        "goalOfTherapy",
        vacOrderSummarInfo.therapyGoal === null
          ? ""
          : vacOrderSummarInfo.therapyGoal.toString()
      )!,
      value:
        vacOrderSummarInfo.therapyGoal === null
          ? ""
          : vacOrderSummarInfo.therapyGoal.toString(),
    },

    // Submit a valid prescription
    submitPrescription: {
      ...defaultOrderData.submitPrescription,
      valid: Validate(
        Validator,
        "submitPrescription",
        vacOrderSummarInfo.prescriptionMethod.toString() === "0"
          ? ""
          : vacOrderSummarInfo.prescriptionMethod.toString()
      )!,
      value: getSubmitPrescription(
        vacOrderSummarInfo.prescriptionMethod.toString()
      ),
    },
    prescriptionDoc: [],

    // Inpatient Transition
    wasNPWTInitiated: {
      ...defaultOrderData.wasNPWTInitiated,
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.isTransition === true ? "yes" : "no",
    },
    wasNPWTUsedin60Days: {
      ...defaultOrderData.wasNPWTUsedin60Days,
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.previousNPWT === true ? "yes" : "no",
    },
    dateInitiated: {
      ...defaultOrderData.dateInitiated,
      required: vacOrderSummarInfo.isTransition === true,
      valid:
        vacOrderSummarInfo.isTransition === true
          ? Validate(
              Validator,
              "dateInitiated",
              vacOrderSummarInfo.transitionFromFacility.transitionInitaiatedDate
            )!
          : ValidationStatus.VALID,
      value: vacOrderSummarInfo.transitionFromFacility.transitionInitaiatedDate,
    },
    reasonForTransition: {
      ...defaultOrderData.reasonForTransition,
      required: vacOrderSummarInfo.isTransition === true,
      valid:
        vacOrderSummarInfo.isTransition === false
          ? ValidationStatus.VALID
          : vacOrderSummarInfo.transitionFromFacility.transitionReason
          ? ValidationStatus.VALID
          : ValidationStatus.UNTOUCHED,
      value: vacOrderSummarInfo?.transitionFromFacility?.transitionReason
        ? vacOrderSummarInfo?.transitionFromFacility?.transitionReason?.toString()
        : "",
    },
    previousRo: {
      ...defaultOrderData.previousRo,
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.previousRONumber,
    },

    inpatientFacility: getInpatientFacility(vacOrderSummarInfo),
    inpatientFacilityAsDefault: false,

    // HomeCareProvider Cause
    homeCareProvider: {
      ...defaultOrderData.homeCareProvider,
      valid: getHomeCareProviderValidation(
        vacOrderSummarInfo.administeringDressingChanges,
        vacOrderSummarInfo.hcp.address.addressLine1
      ),
      value:
        vacOrderSummarInfo.administeringDressingChanges === null
          ? ""
          : vacOrderSummarInfo.administeringDressingChanges === true
          ? "yes"
          : "no",
    },
    vacTherapyLocation: {
      ...defaultOrderData.vacTherapyLocation,
      valid: Validate(
        Validator,
        "vacTherapyLocation",
        vacOrderSummarInfo.vacUseLocation
      )!,
      value:
        vacOrderSummarInfo.vacUseLocation === null
          ? ""
          : vacOrderSummarInfo.vacUseLocation,
    },
    otherVacUseLocation: {
      ...defaultOrderData.otherVacUseLocation,
      required: vacOrderSummarInfo.vacUseLocation === "Other",
      isOptional: vacOrderSummarInfo.vacUseLocation === "Other" ? false : true,
      valid: Validate(
        Validator,
        "otherVacUseLocation",
        vacOrderSummarInfo.otherVacUseLocation
      )!,
      value:
        vacOrderSummarInfo.otherVacUseLocation === null
          ? ""
          : vacOrderSummarInfo.otherVacUseLocation,
    },
    addedCaregiverName: {
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.hcp.facilityName,
      required: false,
    },
    addedCaregiverAddress1: {
      ...defaultOrderData.addedCaregiverAddress1,
      valid: getHomeCareProviderValidation(
        vacOrderSummarInfo.administeringDressingChanges,
        vacOrderSummarInfo.hcp.address.addressLine1
      ),
      value: vacOrderSummarInfo.hcp.address.addressLine1,
      required: false,
    },
    addedCaregiverAddress2: {
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.hcp.address.addressLine2,
      required: false,
    },
    addedCaregiverCity: {
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.hcp.address.city,
      required: false,
    },
    addedCaregiverState: {
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.hcp.address.stateCode,
      required: false,
    },
    addedCaregiverPhone: {
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.hcp.phoneNo,
      required: false,
    },
    addedCaregiverZip: {
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.hcp.address.postalCode,
      required: false,
    },
    addedCaregiverFacilityType: {
      valid: ValidationStatus.VALID,
      value: getHcpFacilityName(providerTypes, vacOrderSummarInfo),
      required: false,
    },

    deliveryContactFirstName: {
      ...defaultOrderData.deliveryContactFirstName,
      valid: Validate(
        Validator,
        "deliveryContactFirstName",
        vacOrderSummarInfo.deliveryFirstName
      )!,
      value: vacOrderSummarInfo.deliveryFirstName,
    },
    deliveryContactLastName: {
      ...defaultOrderData.deliveryContactLastName,
      valid: Validate(
        Validator,
        "deliveryContactLastName",
        vacOrderSummarInfo.deliveryLastName
      )!,
      value: vacOrderSummarInfo.deliveryLastName,
    },
    deliveryContactPhone: {
      ...defaultOrderData.deliveryContactPhone,
      valid: Validate(
        Validator,
        "deliveryContactPhone",
        vacOrderSummarInfo.deliveryPhoneNumber
      )!,
      value: vacOrderSummarInfo.deliveryPhoneNumber,
    },
    deliveryContactQuickFillType: {
      ...defaultOrderData.deliveryContactQuickFillType,
      valid: ValidationStatus.VALID,
      value: fetchDeliveryContactSameAsRequesterStatus(
        vacOrderSummarInfo,
        AuthObj
      ),
    },
    deliveryInstructions: {
      ...defaultOrderData.deliveryInstructions,
      valid: Validate(
        Validator,
        "deliveryInstructions",
        vacOrderSummarInfo.deliveryInstructions
      )!,
      value: vacOrderSummarInfo.deliveryInstructions,
      isDefaultValid: true,
    },

    // Print Common Docs
    commonDocs: {
      valid: ValidationStatus.VALID,
      value: "",
      isDefaultValid: true,
    },

    uploadDocument: [],

    updatedPrescriberEmail: {
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.prescriberEmailAddress,
      componentId: defaultOrderData.submitPrescription.componentId,
      order: defaultOrderData.submitPrescription.order,
    },

    loggedInUserSiteUseID: {
      valid: ValidationStatus.VALID,
      value: "",
      isOptional: true,
    },

    addedCaregiverPhoneExtension: {
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.hcp.extension,
      required: false,
    },
    addedCaregiverFacilityTypeCode: {
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.hcp.facilityType.toString(),
      required: false,
    },
    addedCaregiverSiteUseID: {
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.hcp.hcpSiteUseID,
      required: false,
    },
    addedCaregiverID: {
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.hcp.caregiverID ?? "",
      required: false,
    },
    addedCaregiverAccountNumber: {
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.hcp.accountNumber,
      required: false,
    },
  };
  return newOrder;
};

const getHomeCareProviderValidation = (value: boolean, address: string) => {
  if ((value === true && address && address !== "") || value === false) {
    return ValidationStatus.VALID;
  } else {
    return ValidationStatus.UNTOUCHED;
  }
};

const getSubmitPrescription = (value: string) => {
  switch (value) {
    case "1":
      return "RxImage";
    case "2":
      return "EPrescription";
    case "3":
      return "Fax";
    default:
      return "";
  }
};

const checkIsSecondaryPresent = (vacOrderSummary: VacOrderSummaryData) => {
  if (vacOrderSummary.secondaryInsurance !== null) {
    return true;
  }
  return false;
};

const getAccessoriesObj = (value: any) => {
  return value.map((element: any) => {
    return {
      id: element.sku,
      code: element.sku.toString(),
      value: element.productName.toString(),
    };
  });
};

const Validate = (
  Validator: NewOrderValidator,
  Name: string,
  Value: string
) => {
  let notNullValue = Value === null ? "" : Value;
  const Status = Validator?.validate(notNullValue, Name)?.status;
  return Status === ValidationStatus.INVALID
    ? ValidationStatus.UNTOUCHED
    : Status;
};

const ValidateInsurance = (
  Validator: InsuranceInformationValidator,
  Name: string,
  Value: string
) => {
  let notNullValue = Value === null ? "" : Value;
  const Status = Validator?.validate(notNullValue, Name)?.status;
  return Status === ValidationStatus.INVALID
    ? ValidationStatus.UNTOUCHED
    : Status;
};

const ValidateArray = (
  Validator: NewOrderValidator,
  Name: string,
  Value: any
) => {
  const Status = Validator?.validateArray(Value, Name)?.status;
  return Status === ValidationStatus.INVALID
    ? ValidationStatus.UNTOUCHED
    : Status;
};

const ValidateArrayValues = (Values: any) => {
  var selectedLength = Values.filter(
    (e: { selected: boolean }) => e.selected === true
  ).length;
  return selectedLength > 0
    ? ValidationStatus.VALID
    : ValidationStatus.UNTOUCHED;
};

const ValidateNestedArrayValues = (Values: any) => {
  var selectedLength = Values.filter(
    (e: { selected: boolean; isTextBoxValueValid: boolean }) =>
      e.selected === true && e.isTextBoxValueValid === true
  ).length;
  return selectedLength > 0
    ? ValidationStatus.VALID
    : ValidationStatus.UNTOUCHED;
};

const getHcpFacilityName = (
  providerTypes: any,
  vacOrderSummary: VacOrderSummaryData
) => {
  const selectedType = providerTypes.filter(
    (x: any) => parseInt(x.code) === vacOrderSummary.hcp.facilityType
  )[0];
  if (selectedType) {
    return selectedType.text;
  }
  return vacOrderSummary.hcp.facilityTypeName;
};

const getInpatientFacility = (vacOrderSummary: VacOrderSummaryData) => {
  let reqFacility: IFacility;

  reqFacility = {
    accountId:
      vacOrderSummary.transitionFromFacility.facilityNumber?.toString(),
    accountName: vacOrderSummary.transitionFromFacility.name,
    facilityAddressID:
      vacOrderSummary.transitionFromFacility.facilityNumber?.toString(),
    address1: vacOrderSummary.transitionFromFacility.address.addressLine1,
    address2: vacOrderSummary.transitionFromFacility.address.addressLine2,
    state: vacOrderSummary.transitionFromFacility.address.stateCode,
    city: vacOrderSummary.transitionFromFacility.address.city,
    zip: parseInt(vacOrderSummary.transitionFromFacility.address.postalCode),
    typeName: vacOrderSummary.transitionFromFacility.facilityType,
    accountNumber: vacOrderSummary.transitionFromFacility.facilityNumber,
    addressId: "",
    typeCode: "",
    facilityMode: 0,
    siteUseId: vacOrderSummary.transitionFromFacility.transitionSiteUseID,
  };
  return reqFacility;
};

const requestorFacility = (vacOrderSummary: VacOrderSummaryData) => {
  let reqFacility: IFacility;

  reqFacility = {
    accountId: vacOrderSummary.requestor.facilityNumber?.toString(),
    accountName: vacOrderSummary.requestor.facilityName,
    facilityAddressID: vacOrderSummary.requestor.facilityNumber?.toString(),
    address1: vacOrderSummary.requestor.address.addressLine1,
    address2: vacOrderSummary.requestor.address.addressLine2,
    state: vacOrderSummary.requestor.address.stateCode,
    city: vacOrderSummary.requestor.address.city,
    zip: parseInt(vacOrderSummary.requestor.address.postalCode),
    typeName: vacOrderSummary.requestor.facilityType,
    accountNumber: vacOrderSummary.requestor.facilityNumber
      ? parseInt(vacOrderSummary.requestor.facilityNumber)
      : null,
    addressId: "",
    typeCode: "",
    facilityMode: 0,
    siteUseId: vacOrderSummary.requestor.siteUseID,
    siteUseCode: vacOrderSummary.requestor.siteUseCode,
  };
  return reqFacility;
};

const primaryInsurace = (
  vacOrderSummaryInsurance: any,
  insuranceTypes: any,
  setShowAdditionalObject: any,
  type: string,
  Validator: InsuranceInformationValidator
) => {
  const defaultInsuranceData =
    type === "primary"
      ? defaultPrimaryInsuranceData
      : defaultSecondaryInsuranceData;
  if (vacOrderSummaryInsurance.type) {
    let obj: IInsuranceInformation;
    let insuranceTypeName = insuranceTypes.filter(
      (x: any) => parseInt(x.code) === vacOrderSummaryInsurance.type
    )[0]?.text;
    obj = {
      ...defaultInsuranceData,
      insuranceType: {
        ...defaultInsuranceData.insuranceType,
        valid: ValidationStatus.VALID,
        value: insuranceTypeName,
      },
      insuranceTypeCode: {
        ...defaultInsuranceData.insuranceTypeCode,
        valid: ValidationStatus.VALID,
        value: vacOrderSummaryInsurance.type.toString(),
      },
    };
    switch (vacOrderSummaryInsurance.type) {
      case 1:
        obj = {
          ...obj,
          medicare: insurancePayerDetails(
            obj.medicare,
            vacOrderSummaryInsurance,
            Validator
          ),
        };
        break;
      case 2:
        obj = {
          ...obj,
          medicaid: insurancePayerDetails(
            obj.medicaid,
            vacOrderSummaryInsurance,
            Validator
          ),
        };
        break;
      case 3:
        obj = {
          ...obj,
          commercialInsurance: insurancePayerAllDetails(
            obj.commercialInsurance,
            vacOrderSummaryInsurance,
            Validator
          ),
        };
        break;
      case 4:
        obj = {
          ...obj,
          privatePay: {
            valid: ValidationStatus.VALID,
            value: "",
            required: false,
            isOptional: true,
          },
        };
        break;
      case 5:
        obj = {
          ...obj,
          medicareAdvantage: insurancePayerAllDetails(
            obj.medicareAdvantage,
            vacOrderSummaryInsurance,
            Validator
          ),
        };
        break;
      case 6:
        obj = {
          ...obj,
          charityCare: {
            valid: ValidationStatus.VALID,
            value: "",
            required: false,
            isOptional: true,
          },
        };
        break;
      case 7:
        obj = {
          ...obj,
          managedMedicaid: insurancePayerAllDetails(
            obj.managedMedicaid,
            vacOrderSummaryInsurance,
            Validator
          ),
        };
        break;
      case 9:
        obj = {
          ...obj,
          facilityPay: {
            valid: ValidationStatus.VALID,
            value: "",
            required: false,
            isOptional: true,
          },
        };
        break;
      case 99:
        obj = {
          ...obj,
          otherAdditionalDetails: {
            valid:
              vacOrderSummaryInsurance.additionalInfo === null
                ? ValidationStatus.UNTOUCHED
                : ValidationStatus.VALID,
            value:
              vacOrderSummaryInsurance.additionalInfo === null
                ? ""
                : vacOrderSummaryInsurance.additionalInfo,
            required: true,
            isOptional: true,
          },
        };
        break;
      default:
        break;
    }
    if (type === "primary") {
      setShowAdditionalObject((dt: ShowAdditionalFields) => ({
        typePrimary: {
          medicare: "Medicare" === insuranceTypeName ? true : false,
          medicareAdvantage:
            "Medicare Advantage" === insuranceTypeName ? true : false,
          managedMedicaid:
            "Managed Medicaid" === insuranceTypeName ? true : false,
          commercialInsurance:
            "Commercial Insurance" === insuranceTypeName ? true : false,
          medicaid: "Medicaid" === insuranceTypeName ? true : false,
          charityCare: "Charity Care" === insuranceTypeName ? true : false,
          privatePay: "Private Pay" === insuranceTypeName ? true : false,
          otherAdditionalDetails: "Other" === insuranceTypeName ? true : false,
          workerCompensation:
            "Workers' Compensation" === insuranceTypeName ? true : false,
          facilityPay: "Facility Pay" === insuranceTypeName ? true : false,
        },
        typeSecondary: dt.typeSecondary,
      }));
    } else {
      setShowAdditionalObject((dt: ShowAdditionalFields) => ({
        typeSecondary: {
          medicare: "Medicare" === insuranceTypeName ? true : false,
          medicareAdvantage:
            "Medicare Advantage" === insuranceTypeName ? true : false,
          managedMedicaid:
            "Managed Medicaid" === insuranceTypeName ? true : false,
          commercialInsurance:
            "Commercial Insurance" === insuranceTypeName ? true : false,
          medicaid: "Medicaid" === insuranceTypeName ? true : false,
          charityCare: "Charity Care" === insuranceTypeName ? true : false,
          privatePay: "Private Pay" === insuranceTypeName ? true : false,
          otherAdditionalDetails: "Other" === insuranceTypeName ? true : false,
          workerCompensation:
            "Workers' Compensation" === insuranceTypeName ? true : false,
          facilityPay: "Facility Pay" === insuranceTypeName ? true : false,
        },
        typePrimary: dt.typePrimary,
      }));
    }
    return obj;
  }
  return defaultInsuranceData;
};

const insurancePayerDetails = (
  obj: IPayerIDDetails,
  vacOrderSummary: any,
  Validator: InsuranceInformationValidator
) => {
  return {
    memberID: {
      ...obj.memberID,
      isOptional: false,
      valid: ValidateInsurance(
        Validator,
        "memberID",
        vacOrderSummary.memberID === null ? "" : vacOrderSummary.memberID
      )!,
      value: vacOrderSummary.memberID,
    },
    relationShipInsured: {
      ...obj.relationShipInsured,
      isOptional: false,
      valid: ValidateInsurance(
        Validator,
        "relationShipInsured",
        vacOrderSummary.relationshipToPatient === null
          ? ""
          : vacOrderSummary.relationshipToPatient
      )!,
      value: vacOrderSummary.relationshipToPatient,
    },
  };
};

const insurancePayerAllDetails = (
  obj: IPayerIDAllDetails,
  vacOrderSummary: any,
  Validator: InsuranceInformationValidator
) => {
  return {
    payerName: {
      ...obj.payerName,
      isOptional: false,
      valid: ValidateInsurance(
        Validator,
        "payerName",
        vacOrderSummary.name === null ? "" : vacOrderSummary.name
      )!,
      value: vacOrderSummary.name,
    },
    groupID: {
      ...obj.groupID,
      isOptional: false,
      valid: ValidateInsurance(
        Validator,
        "groupID",
        vacOrderSummary.groupID === null ? "" : vacOrderSummary.groupID
      )!,
      value: vacOrderSummary.groupID,
    },
    memberID: {
      ...obj.memberID,
      isOptional: false,
      valid: ValidateInsurance(
        Validator,
        "memberID",
        vacOrderSummary.memberID === null ? "" : vacOrderSummary.memberID
      )!,
      value: vacOrderSummary.memberID,
    },
    relationShipInsured: {
      ...obj.relationShipInsured,
      isOptional: false,
      valid: ValidateInsurance(
        Validator,
        "relationShipInsured",
        vacOrderSummary.relationshipToPatient === null
          ? ""
          : vacOrderSummary.relationshipToPatient
      )!,
      value: vacOrderSummary.relationshipToPatient,
    },
    extension: {
      ...obj.extension,
      isOptional: false,
      valid: ValidateInsurance(
        Validator,
        "extension",
        vacOrderSummary.providerContactNumberExtension === null
          ? ""
          : vacOrderSummary.providerContactNumberExtension
      )!,
      value:
        vacOrderSummary.providerContactNumberExtension === null
          ? ""
          : vacOrderSummary.providerContactNumberExtension,
    },
    payerContactNumber: {
      ...obj.payerContactNumber,
      isOptional: false,
      valid: ValidateInsurance(
        Validator,
        "payerContactNumber",
        vacOrderSummary.providerContactNumber === null
          ? ""
          : vacOrderSummary.providerContactNumber
      )!,
      value:
        vacOrderSummary.providerContactNumber === null
          ? ""
          : vacOrderSummary.providerContactNumber.toString(),
    },
  };
};

const compareAddress = (address1: any, address2: any) => {
  var address1_json = JSON.stringify(address1);
  var address2_json = JSON.stringify(address2);
  return _.isEqual(address1_json, address2_json) ? "true" : "false";
};

export const mapClinicalInformation = (
  vacOrderSummary: VacOrderSummaryData,
  Validator = new NewOrderValidator()
) => {
  let woundDetails: INewOrderWoundInfo = getDeepClone(
    defaultNewOrderWoundInfoData
  );
  woundDetails = {
    nutriStatusCompromized: {
      ...woundDetails.nutriStatusCompromized,
      valid: Validate(
        Validator,
        "nutriStatusCompromized",
        vacOrderSummary.clinicalInformation.nutritionStatus === null
          ? ""
          : vacOrderSummary.clinicalInformation.nutritionStatus === true
          ? "Yes"
          : "No"
      )!,
      value:
        vacOrderSummary.clinicalInformation.nutritionStatus === null
          ? ""
          : vacOrderSummary.clinicalInformation.nutritionStatus === true
          ? "Yes"
          : "No",
      required: true,
    },
    nutritionActions: {
      ...woundDetails.nutritionActions,
      valid: ValidateArray(
        Validator,
        "nutritionActions",
        nutriActionData.map((obj) => {
          if (
            vacOrderSummary.clinicalInformation.nutritionAction
              ?.split(",")
              .includes(obj.value)
          ) {
            return { ...obj, selected: true };
          }
          return obj;
        })
      )!,
      value: nutriActionData.map((obj) => {
        if (
          vacOrderSummary.clinicalInformation.nutritionAction
            ?.split(",")
            .includes(obj.value)
        ) {
          return { ...obj, selected: true };
        }
        return obj;
      }),
      required: vacOrderSummary.clinicalInformation.nutritionStatus,
    },
    previousTherapies: {
      ...woundDetails.previousTherapies,
      valid: ValidateArrayValues(
        previousTherapy.map((obj) => {
          if (
            vacOrderSummary.clinicalInformation.previousTherapies
              ?.toString()
              .split(",")
              .includes(obj.value) ||
            vacOrderSummary.clinicalInformation.previousTherapies
              ?.toString()
              .split(",")
              .filter((e: string) => e.includes("Other"))
              .toString()
              .split(":")[0]
              .includes(obj.value)
          ) {
            return { ...obj, selected: true };
          }
          return obj;
        })
      )!,
      value: previousTherapy.map((obj) => {
        if (
          vacOrderSummary.clinicalInformation.previousTherapies
            ?.toString()
            .split(",")
            .includes(obj.value) ||
          vacOrderSummary.clinicalInformation.previousTherapies
            ?.toString()
            .split(",")
            .filter((e: string) => e.includes("Other"))
            .toString()
            .split(":")[0]
            .includes(obj.value)
        ) {
          return { ...obj, selected: true };
        }
        return obj;
      }),
      required: true,
    },
    previousTherapyOther: {
      ...woundDetails.previousTherapyOther,
      valid: Validate(
        Validator,
        "previousTherapyOther",
        vacOrderSummary.clinicalInformation.previousTherapies
          ?.toString()
          .split(",")
          .filter((e: string) => e.includes("Other"))
          .toString()
          .split(":")[1] ?? ""
      )!,
      value:
        vacOrderSummary.clinicalInformation.previousTherapies
          ?.toString()
          .split(",")
          .filter((e: string) => e.includes("Other"))
          .toString()
          .split(":")[1] ?? "",
      required:
        vacOrderSummary.clinicalInformation.previousTherapies
          ?.toString()
          .split(",")
          .filter((e: string) => e.includes("Other")).length > 0
          ? true
          : false,
    },
    previousTherapiesCauses: {
      ...woundDetails.previousTherapiesCauses,
      valid: ValidateArrayValues(
        previousTherapyCause.map((obj) => {
          if (
            vacOrderSummary.clinicalInformation.otherTherapiesConditionPrevented
              ?.toString()
              .split(",")
              .includes(obj.value) ||
            vacOrderSummary.clinicalInformation.otherTherapiesConditionPrevented
              ?.toString()
              .split(",")
              .filter((e: string) => e.includes("Other"))
              .toString()
              .split(":")[0]
              .includes(obj.value)
          ) {
            return { ...obj, selected: true };
          }
          return obj;
        })
      ),
      value: previousTherapyCause.map((obj) => {
        if (
          vacOrderSummary.clinicalInformation.otherTherapiesConditionPrevented
            ?.toString()
            .split(",")
            .includes(obj.value) ||
          vacOrderSummary.clinicalInformation.otherTherapiesConditionPrevented
            ?.toString()
            .split(",")
            .filter((e: string) => e.includes("Other"))
            .toString()
            .split(":")[0]
            .includes(obj.value)
        ) {
          return { ...obj, selected: true };
        }
        return obj;
      }),
      required: true,
    },
    previousTherapiesCausesOther: {
      ...woundDetails.previousTherapiesCausesOther,
      valid: Validate(
        Validator,
        "previousTherapiesCausesOther",
        vacOrderSummary.clinicalInformation.otherTherapiesConditionPrevented
          ?.toString()
          .split(",")
          .filter((e: string) => e.includes("Other"))
          .toString()
          .split(":")[1] ?? ""
      )!,
      value:
        vacOrderSummary.clinicalInformation.otherTherapiesConditionPrevented
          ?.toString()
          .split(",")
          .filter((e: string) => e.includes("Other"))
          .toString()
          .split(":")[1] ?? "",
      required:
        vacOrderSummary.clinicalInformation.otherTherapiesConditionPrevented
          ?.toString()
          .split(",")
          .filter((e: string) => e.includes("Other")).length > 0
          ? true
          : false,
    },
    wndInfoComorbidities: {
      ...woundDetails.wndInfoComorbidities,
      valid: ValidateArrayValues(
        comorbiditiesData.map((obj) => {
          if (
            vacOrderSummary.clinicalInformation.comorbititiesApply
              ?.toString()
              .split(",")
              .includes(obj.value) ||
            vacOrderSummary.clinicalInformation.comorbititiesApply
              ?.toString()
              .split(",")
              .filter((e: string) => e.includes("Other"))
              .toString()
              .split(":")[0]
              .includes(obj.value)
          ) {
            return { ...obj, selected: true };
          }
          return obj;
        })
      ),
      value: comorbiditiesData.map((obj) => {
        if (
          vacOrderSummary.clinicalInformation.comorbititiesApply
            ?.toString()
            .split(",")
            .includes(obj.value) ||
          vacOrderSummary.clinicalInformation.comorbititiesApply
            ?.toString()
            .split(",")
            .filter((e: string) => e.includes("Other"))
            .toString()
            .split(":")[0]
            .includes(obj.value)
        ) {
          return { ...obj, selected: true };
        }
        return obj;
      }),
      required: true,
    },
    wndInfoComorbiditiesOther: {
      ...woundDetails.wndInfoComorbiditiesOther,
      valid: Validate(
        Validator,
        "wndInfoComorbiditiesOther",
        vacOrderSummary.clinicalInformation.comorbititiesApply
          ?.toString()
          .split(",")
          .filter((e: string) => e.includes("Other"))
          .toString()
          .split(":")[1] ?? ""
      )!,
      value:
        vacOrderSummary.clinicalInformation.comorbititiesApply
          ?.toString()
          .split(",")
          .filter((e: string) => e.includes("Other"))
          .toString()
          .split(":")[1] ?? "",
      required:
        vacOrderSummary.clinicalInformation.comorbititiesApply
          ?.toString()
          .split(",")
          .filter((e: string) => e.includes("Other")).length > 0
          ? true
          : false,
    },
    wndInfoComorbiditiesDiabetese: {
      ...woundDetails.wndInfoComorbiditiesDiabetese,
      valid:
        vacOrderSummary?.clinicalInformation?.isComorbititiesDiabeticProgram !==
        null
          ? ValidationStatus.VALID
          : ValidationStatus.UNTOUCHED,
      value:
        vacOrderSummary?.clinicalInformation?.isComorbititiesDiabeticProgram ===
        null
          ? ""
          : vacOrderSummary?.clinicalInformation?.isComorbititiesDiabeticProgram.toString(),
      required:
        vacOrderSummary.clinicalInformation.comorbititiesApply
          ?.toString()
          .split(",")
          .filter((e: string) => e.includes("Diabetes")).length > 0
          ? true
          : false,
    },
    isOsteomyelitisPresent: mapIsOsteomyelitisPresent(
      vacOrderSummary,
      Validator,
      woundDetails.isOsteomyelitisPresent.componentId!,
      woundDetails.isOsteomyelitisPresent.order!
    ),
    osteomyelitisies: mapOsteomyelitisies(vacOrderSummary, Validator),
    isTreatemenForResolveBoneInfection: {
      ...woundDetails.isTreatemenForResolveBoneInfection,
      valid: Validate(
        Validator,
        "isTreatemenForResolveBoneInfection",
        vacOrderSummary.clinicalInformation.osteomyelitisRegimenResolve === null
          ? ""
          : vacOrderSummary.clinicalInformation.osteomyelitisRegimenResolve ===
            true
          ? "Yes"
          : "No"
      )!,
      value:
        vacOrderSummary.clinicalInformation.osteomyelitisRegimenResolve === null
          ? ""
          : vacOrderSummary.clinicalInformation.osteomyelitisRegimenResolve ===
            true
          ? "Yes"
          : "No",
      required: vacOrderSummary.clinicalInformation.osteomyelitisPresent
        ? true
        : false,
    },

    debridementAttempted: {
      ...woundDetails.debridementAttempted,
      valid: ValidationStatus.UNTOUCHED,
      value:
        vacOrderSummary.primaryWound.debridementAttempt === null
          ? ""
          : vacOrderSummary.primaryWound.debridementAttempt === true
          ? "Yes"
          : "No",
    },
    debridementDate: {
      ...woundDetails.debridementDate,
      valid: Validate(
        Validator,
        "debridementDate",
        vacOrderSummary.primaryWound.debridementDate
      )!,
      value:
        vacOrderSummary.primaryWound.debridementDate === null
          ? ""
          : vacOrderSummary.primaryWound.debridementDate,
      required: vacOrderSummary.primaryWound.debridementAttempt,
    },
    debridementType: {
      ...woundDetails.debridementType,
      valid: Validate(
        Validator,
        "debridementType",
        vacOrderSummary.primaryWound.debridementType === null
          ? ""
          : vacOrderSummary.primaryWound.debridementType
      )!,
      value:
        vacOrderSummary.primaryWound.debridementType === null
          ? ""
          : vacOrderSummary.primaryWound.debridementType,
      required: vacOrderSummary.primaryWound.debridementAttempt,
    },
    serialDebridementRequired: {
      ...woundDetails.serialDebridementRequired,
      valid: Validate(
        Validator,
        "serialDebridementRequired",
        vacOrderSummary.primaryWound.debridementRequired === null
          ? ""
          : vacOrderSummary.primaryWound.debridementRequired === true
          ? "Yes"
          : "No"
      )!,
      value:
        vacOrderSummary.primaryWound.debridementRequired === null
          ? ""
          : vacOrderSummary.primaryWound.debridementRequired === true
          ? "Yes"
          : "No",
      required: false,
    },
    woundMeasurementDate: {
      ...woundDetails.woundMeasurementDate,
      valid: Validate(
        Validator,
        "woundMeasurementDate",
        vacOrderSummary.primaryWound.measurementDate === null
          ? ""
          : vacOrderSummary.primaryWound.measurementDate
      )!,
      value:
        vacOrderSummary.primaryWound.measurementDate === null
          ? ""
          : vacOrderSummary.primaryWound.measurementDate,
      required:
        vacOrderSummary.primaryWound.measurementDate === null ? false : true,
    },
    woundLength: mapWoundDimensionLength(
      vacOrderSummary.primaryWound,
      Validator,
      woundDetails
    ),
    woundWidth: mapWoundDimensionWidth(
      vacOrderSummary.primaryWound,
      Validator,
      woundDetails
    ),
    woundDepth: mapWoundDimensionDepth(
      vacOrderSummary.primaryWound,
      Validator,
      woundDetails
    ),
    woundThickness: {
      ...woundDetails.woundThickness,
      valid: Validate(
        Validator,
        "woundThickness",
        vacOrderSummary.primaryWound.thickness === null
          ? ""
          : vacOrderSummary.primaryWound.thickness === true
          ? "Yes"
          : "No"
      )!,
      value:
        vacOrderSummary.primaryWound.thickness === null
          ? ""
          : vacOrderSummary.primaryWound.thickness === true
          ? "Yes"
          : "No",
      required: vacOrderSummary.primaryWound.thickness === null ? false : true,
      title: "Wound Thickness",
    },
    woundTunneling: {
      ...woundDetails.woundTunneling,
      valid: Validate(
        Validator,
        "woundTunneling",
        vacOrderSummary.primaryWound.tunnelingPresent === null
          ? ""
          : vacOrderSummary.primaryWound.tunnelingPresent === true
          ? "Yes"
          : "No"
      )!,
      value:
        vacOrderSummary.primaryWound.tunnelingPresent === null
          ? ""
          : vacOrderSummary.primaryWound.tunnelingPresent === true
          ? "Yes"
          : "No",
      required:
        vacOrderSummary.primaryWound.tunnelingPresent === null ? false : true,
      title: "Wound Tunneling",
    },
    location1Depth: mapWoundTunnelingDepth(
      vacOrderSummary,
      Validator,
      true,
      true,
      woundDetails
    ),
    location1Position: mapWoundTunnelingLocation(
      vacOrderSummary,
      Validator,
      true,
      true,
      woundDetails
    ),
    location2Depth: mapWoundTunnelingDepth(
      vacOrderSummary,
      Validator,
      true,
      false,
      woundDetails
    ),
    location2Position: mapWoundTunnelingLocation(
      vacOrderSummary,
      Validator,
      true,
      false,
      woundDetails
    ),
    exudateAmount: {
      ...woundDetails.exudateAmount,
      valid: Validate(
        Validator,
        "exudateAmount",
        vacOrderSummary.primaryWound.exudateAmount === null
          ? ""
          : vacOrderSummary.primaryWound.exudateAmount?.toString()
      )!,
      value:
        vacOrderSummary.primaryWound.exudateAmount === null
          ? ""
          : vacOrderSummary.primaryWound.exudateAmount?.toString(),
      required: false,
      title: "Exudate Amount",
    },
    exudateAppearance: {
      ...woundDetails.exudateAppearance,
      valid: Validate(
        Validator,
        "exudateAppearance",
        vacOrderSummary.primaryWound.exudateAppearance === null
          ? ""
          : vacOrderSummary.primaryWound.exudateAppearance?.toString()
      )!,
      value:
        vacOrderSummary.primaryWound.exudateAppearance === null
          ? ""
          : vacOrderSummary.primaryWound.exudateAppearance?.toString(),
      required:
        vacOrderSummary.primaryWound.exudateAmount &&
        vacOrderSummary.primaryWound.exudateAmount.toString().toLowerCase() !==
          "none",
      title: "Exudate Appearance",
    },
    granulationValue: {
      valid: ValidationStatus.UNTOUCHED,
      value:
        vacOrderSummary.primaryWound.brightRedTissue === null
          ? ""
          : "GRA" + vacOrderSummary.primaryWound.brightRedTissue,
    },
    epthilizationValue: {
      valid: ValidationStatus.UNTOUCHED,
      value:
        vacOrderSummary.primaryWound.dullTissue === null
          ? ""
          : "EPH" + vacOrderSummary.primaryWound.dullTissue,
    },
    sloughValue: {
      valid: ValidationStatus.UNTOUCHED,
      value:
        vacOrderSummary.primaryWound.whiteTissue === null
          ? ""
          : "SLO" + vacOrderSummary.primaryWound.whiteTissue,
    },
    escharValue: {
      valid: ValidationStatus.UNTOUCHED,
      value:
        vacOrderSummary.primaryWound.blackEschar === null
          ? ""
          : "ESC" + vacOrderSummary.primaryWound.blackEschar,
    },
    woundBedTotal: {
      ...woundDetails.woundBedTotal,
      valid: ValidationStatus.UNTOUCHED,
      value:
        vacOrderSummary.primaryWound.bedTotal === null
          ? "0"
          : vacOrderSummary.primaryWound.bedTotal?.toString(),
      componentId: woundDetails.woundBedTotal.componentId!,
      order: woundDetails.woundBedTotal.order,
      required: vacOrderSummary.primaryWound.bedTotal === null ? false : true,
    },
    exposedTissue: {
      valid:
        vacOrderSummary.primaryWound.muscleExposed !== null
          ? ValidationStatus.VALID
          : ValidationStatus.UNTOUCHED,
      value:
        vacOrderSummary.primaryWound.muscleExposed !== null
          ? vacOrderSummary.primaryWound.muscleExposed === true
            ? "Yes"
            : "No"
          : "",
      required: true,
      title: "Exposed Tissue",
      order: woundDetails.exposedTissue.order,
      componentId: woundDetails.exposedTissue.componentId!,
    },

    shortNarrativeOfPossibleConsequences: {
      ...woundDetails.shortNarrativeOfPossibleConsequences,
      valid: ValidationStatus.UNTOUCHED,
      value:
        vacOrderSummary.vacNotUsedConsequences === null
          ? ""
          : vacOrderSummary.vacNotUsedConsequences?.toString(),
      title: "Short Narrative",
      required: vacOrderSummary.vacNotUsedConsequences === null ? false : true,
    },
    woundType: {
      ...woundDetails.woundType,
      valid: Validate(
        Validator,
        "woundType",
        vacOrderSummary.primaryWound.type === null
          ? ""
          : vacOrderSummary.primaryWound.type?.toString()
      )!,
      value:
        vacOrderSummary.primaryWound.type === null
          ? ""
          : vacOrderSummary.primaryWound.type?.toString(),
      required: true,
    },
    woundAge: {
      ...woundDetails.woundAge,
      valid: Validate(
        Validator,
        "woundAge",
        vacOrderSummary.primaryWound.age === null ||
          vacOrderSummary.primaryWound.age.toString() === "0"
          ? ""
          : vacOrderSummary.primaryWound.age?.toString()
      )!,
      value:
        vacOrderSummary.primaryWound.age === null ||
        vacOrderSummary.primaryWound.age.toString() === "0"
          ? ""
          : vacOrderSummary.primaryWound.age?.toString(),
      required: true,
    },
    woundAgeType: {
      ...woundDetails.woundAgeType,
      valid: Validate(
        Validator,
        "woundAgeType",
        vacOrderSummary.primaryWound.ageType === null
          ? "2"
          : vacOrderSummary.primaryWound.ageType?.toString()
      )!,
      value:
        vacOrderSummary.primaryWound.ageType === null
          ? (vacOrderSummary.primaryWound.ageType = "2")
          : vacOrderSummary.primaryWound.ageType?.toString(),
      required: true,
    },
    woundLocation: {
      ...woundDetails.woundLocation,
      valid: Validate(
        Validator,
        "woundLocation",
        vacOrderSummary.primaryWound.location === null
          ? ""
          : vacOrderSummary.primaryWound.location?.toString()
      )!,
      value:
        vacOrderSummary.primaryWound.location === null
          ? ""
          : vacOrderSummary.primaryWound.location?.toString(),
      required: true,
    },
    woundDirection: {
      ...woundDetails.woundDirection,
      valid: Validate(
        Validator,
        "woundDirection",
        vacOrderSummary.primaryWound.locationWritten === null
          ? ""
          : vacOrderSummary.primaryWound.locationWritten?.toString().split(",")
              .length > 0
          ? vacOrderSummary.primaryWound.locationWritten
              ?.toString()
              .split(",")[0]
              .toString()
          : ""
      )!,
      value:
        vacOrderSummary.primaryWound.locationWritten === null
          ? ""
          : vacOrderSummary.primaryWound.locationWritten?.toString().split(",")
              .length > 0
          ? vacOrderSummary.primaryWound.locationWritten
              ?.toString()
              .split(",")[0]
              .toString()
          : "",
      required: false,
    },
    woundOrientation: {
      ...woundDetails.woundOrientation,
      valid: Validate(
        Validator,
        "woundOrientation",
        vacOrderSummary.primaryWound.locationWritten === null
          ? ""
          : vacOrderSummary.primaryWound.locationWritten.split(",").length >= 2
          ? vacOrderSummary.primaryWound.locationWritten
              ?.toString()
              .split(",")[1]
              .toString()
          : ""
      )!,
      value:
        vacOrderSummary.primaryWound.locationWritten === null
          ? ""
          : vacOrderSummary.primaryWound.locationWritten.split(",").length >= 2
          ? vacOrderSummary.primaryWound.locationWritten
              ?.toString()
              .split(",")[1]
              .toString()
          : "",
      required: false,
    },
    isTissuePresent: {
      ...woundDetails.isTissuePresent,
      valid: Validate(
        Validator,
        "isTissuePresent",
        vacOrderSummary.primaryWound.eschar === null
          ? ""
          : vacOrderSummary.primaryWound.eschar === true
          ? "Yes"
          : "No"
      )!,
      value:
        vacOrderSummary.primaryWound.eschar === null
          ? ""
          : vacOrderSummary.primaryWound.eschar === true
          ? "Yes"
          : "No",
      required: true,
    },
    //wound undermining
    woundUndermining: {
      ...woundDetails.woundUndermining,
      valid: Validate(
        Validator,
        "woundUndermining",
        vacOrderSummary.primaryWound.underminingPresent === null
          ? ""
          : vacOrderSummary.primaryWound.underminingPresent === true
          ? "Yes"
          : "No"
      )!,
      value:
        vacOrderSummary.primaryWound.underminingPresent === null
          ? ""
          : vacOrderSummary.primaryWound.underminingPresent === true
          ? "Yes"
          : "No",
      required:
        vacOrderSummary.primaryWound.underminingPresent === null ? false : true,
      title: "Wound Undermining",
    },
    underminingLocation1Depth: mapWoundUnderminingLocationDepth(
      vacOrderSummary,
      Validator,
      true,
      true,
      woundDetails
    ),
    underminingLocation1PositionFrom: mapWoundUnderminingLocationPositionFrom(
      vacOrderSummary,
      Validator,
      true,
      true,
      woundDetails
    ),
    underminingLocation1PositionTo: mapWoundUnderminingLocationPositionTo(
      vacOrderSummary,
      Validator,
      true,
      true,
      woundDetails
    ),
    underminingLocation2Depth: mapWoundUnderminingLocationDepth(
      vacOrderSummary,
      Validator,
      true,
      false,
      woundDetails
    ),
    underminingLocation2PositionFrom: mapWoundUnderminingLocationPositionFrom(
      vacOrderSummary,
      Validator,
      true,
      false,
      woundDetails
    ),

    underminingLocation2PositionTo: mapWoundUnderminingLocationPositionTo(
      vacOrderSummary,
      Validator,
      true,
      false,
      woundDetails
    ),

    // Secondary Wound Info
    isShowSecondaryWoundInfo: {
      valid: ValidationStatus.UNTOUCHED,
      value: vacOrderSummary?.secondaryWound?.type !== null ? "Yes" : "No",
      title: "Secondary Wound Info",
    },
  };
  return woundDetails;
};

export const mapSecondaryWoundIformation = (
  vacOrderSummary: VacOrderSummaryData,
  Validator = new NewOrderValidator()
) => {
  let secondaryWoundDetails: ISecondaryWoundInfo = getDeepClone(
    defaultNewOrdeSecondaryWoundInfoData
  );
  secondaryWoundDetails = {
    ...secondaryWoundDetails,
    shortNarrativeOfPossibleConsequences: {
      ...secondaryWoundDetails.shortNarrativeOfPossibleConsequences,
      valid: ValidationStatus.UNTOUCHED,
      value: "",
    },
    debridementAttempted: {
      ...secondaryWoundDetails.debridementAttempted,
      valid: ValidationStatus.UNTOUCHED,
      value:
        vacOrderSummary.secondaryWound.debridementAttempt === null
          ? ""
          : vacOrderSummary.secondaryWound.debridementAttempt === true
          ? "Yes"
          : "No",
    },
    debridementDate: {
      ...secondaryWoundDetails.debridementDate,
      valid: Validate(
        Validator,
        "debridementDate",
        vacOrderSummary.secondaryWound.debridementDate
      )!,
      value:
        vacOrderSummary.secondaryWound.debridementDate === null
          ? ""
          : vacOrderSummary.secondaryWound.debridementDate,
      required: vacOrderSummary.secondaryWound.debridementAttempt,
    },
    debridementType: {
      ...secondaryWoundDetails.debridementType,
      valid: Validate(
        Validator,
        "debridementType",
        vacOrderSummary.secondaryWound.debridementType === null
          ? ""
          : vacOrderSummary.secondaryWound.debridementType
      )!,
      value:
        vacOrderSummary.secondaryWound.debridementType === null
          ? ""
          : vacOrderSummary.secondaryWound.debridementType,
      required: vacOrderSummary.secondaryWound.debridementAttempt,
    },
    serialDebridementRequired: {
      ...secondaryWoundDetails.serialDebridementRequired,
      valid: Validate(
        Validator,
        "serialDebridementRequired",
        vacOrderSummary.secondaryWound.debridementRequired === null
          ? ""
          : vacOrderSummary.secondaryWound.debridementRequired === true
          ? "Yes"
          : "No"
      )!,
      value:
        vacOrderSummary.secondaryWound.debridementRequired === null
          ? ""
          : vacOrderSummary.secondaryWound.debridementRequired === true
          ? "Yes"
          : "No",
      required: false,
    },
    woundMeasurementDate: {
      ...secondaryWoundDetails.woundMeasurementDate,
      valid: Validate(
        Validator,
        "woundMeasurementDate",
        vacOrderSummary.secondaryWound.measurementDate === null
          ? ""
          : vacOrderSummary.secondaryWound.measurementDate
      )!,
      value:
        vacOrderSummary.secondaryWound.measurementDate === null
          ? ""
          : vacOrderSummary.secondaryWound.measurementDate,
      required:
        vacOrderSummary.secondaryWound.measurementDate === null ? false : true,
    },
    woundLength: mapWoundDimensionLength(
      vacOrderSummary.secondaryWound,
      Validator,
      secondaryWoundDetails
    ),
    woundWidth: mapWoundDimensionWidth(
      vacOrderSummary.secondaryWound,
      Validator,
      secondaryWoundDetails
    ),

    woundDepth: mapWoundDimensionDepth(
      vacOrderSummary.secondaryWound,
      Validator,
      secondaryWoundDetails
    ),

    woundThickness: {
      ...secondaryWoundDetails.woundThickness,
      valid: Validate(
        Validator,
        "woundThickness",
        vacOrderSummary.secondaryWound.thickness === null
          ? ""
          : vacOrderSummary.secondaryWound.thickness === true
          ? "Yes"
          : "No"
      )!,
      value:
        vacOrderSummary.secondaryWound.thickness === null
          ? ""
          : vacOrderSummary.secondaryWound.thickness === true
          ? "Yes"
          : "No",
      required:
        vacOrderSummary.secondaryWound.thickness === null ? false : true,
    },
    woundTunneling: {
      ...secondaryWoundDetails.woundTunneling,
      valid: Validate(
        Validator,
        "woundTunneling",
        vacOrderSummary.secondaryWound.tunnelingPresent === null
          ? ""
          : vacOrderSummary.secondaryWound.tunnelingPresent === true
          ? "Yes"
          : "No"
      )!,
      value:
        vacOrderSummary.secondaryWound.tunnelingPresent === null
          ? ""
          : vacOrderSummary.secondaryWound.tunnelingPresent === true
          ? "Yes"
          : "No",
      required:
        vacOrderSummary.secondaryWound.tunnelingPresent === null ? false : true,
    },
    location1Depth: mapWoundTunnelingDepth(
      vacOrderSummary,
      Validator,
      false,
      true,
      secondaryWoundDetails
    ),
    location1Position: mapWoundTunnelingLocation(
      vacOrderSummary,
      Validator,
      false,
      true,
      secondaryWoundDetails
    ),
    location2Depth: mapWoundTunnelingDepth(
      vacOrderSummary,
      Validator,
      false,
      false,
      secondaryWoundDetails
    ),
    location2Position: mapWoundTunnelingLocation(
      vacOrderSummary,
      Validator,
      false,
      false,
      secondaryWoundDetails
    ),
    exudateAmount: {
      ...secondaryWoundDetails.exudateAmount,
      valid: Validate(
        Validator,
        "exudateAmount",
        vacOrderSummary.secondaryWound.exudateAmount === null
          ? ""
          : vacOrderSummary.secondaryWound.exudateAmount?.toString()
      )!,
      value:
        vacOrderSummary.secondaryWound.exudateAmount === null
          ? ""
          : vacOrderSummary.secondaryWound.exudateAmount?.toString(),
      required: false,
    },
    exudateAppearance: {
      ...secondaryWoundDetails.exudateAppearance,
      valid: Validate(
        Validator,
        "exudateAppearance",
        vacOrderSummary.secondaryWound.exudateAppearance === null
          ? ""
          : vacOrderSummary.secondaryWound.exudateAppearance?.toString()
      )!,
      value:
        vacOrderSummary.secondaryWound.exudateAppearance === null
          ? ""
          : vacOrderSummary.secondaryWound.exudateAppearance?.toString(),
      required:
        vacOrderSummary.secondaryWound.exudateAmount &&
        vacOrderSummary.secondaryWound.exudateAmount
          .toString()
          .toLowerCase() !== "none",
    },
    granulationValue: {
      valid: ValidationStatus.UNTOUCHED,
      value:
        vacOrderSummary.secondaryWound.brightRedTissue == null
          ? ""
          : "GRA" + vacOrderSummary.secondaryWound.brightRedTissue,
    },
    epthilizationValue: {
      valid: ValidationStatus.UNTOUCHED,
      value:
        vacOrderSummary.secondaryWound.dullTissue == null
          ? ""
          : "EPH" + vacOrderSummary.secondaryWound.dullTissue,
    },
    sloughValue: {
      valid: ValidationStatus.UNTOUCHED,
      value:
        vacOrderSummary.secondaryWound.whiteTissue == null
          ? ""
          : "SLO" + vacOrderSummary.secondaryWound.whiteTissue,
    },
    escharValue: {
      valid: ValidationStatus.UNTOUCHED,
      value:
        vacOrderSummary.secondaryWound.blackEschar == null
          ? ""
          : "ESC" + vacOrderSummary.secondaryWound.blackEschar,
    },
    woundBedTotal: {
      ...secondaryWoundDetails.woundBedTotal,
      valid: ValidationStatus.UNTOUCHED,
      value:
        vacOrderSummary.secondaryWound.bedTotal === null
          ? "0"
          : vacOrderSummary.secondaryWound.bedTotal?.toString(),
      componentId: secondaryWoundDetails.woundBedTotal.componentId!,
      order: secondaryWoundDetails.woundBedTotal.order,
      required: false,
    },
    exposedTissue: {
      ...secondaryWoundDetails.exposedTissue,
      valid:
        vacOrderSummary.secondaryWound.muscleExposed !== null
          ? ValidationStatus.VALID
          : ValidationStatus.UNTOUCHED,
      value:
        vacOrderSummary.secondaryWound.muscleExposed !== null
          ? vacOrderSummary.secondaryWound.muscleExposed === true
            ? "Yes"
            : "No"
          : "",
      required: true,
      title: "Exposed Tissue",
      order: secondaryWoundDetails.exposedTissue.order,
      componentId: secondaryWoundDetails.exposedTissue.componentId!,
    },
    woundType: {
      ...secondaryWoundDetails.woundType,
      valid: Validate(
        Validator,
        "woundType",
        vacOrderSummary.secondaryWound.type === null
          ? ""
          : vacOrderSummary.secondaryWound.type?.toString()
      )!,
      value:
        vacOrderSummary.secondaryWound.type === null
          ? ""
          : vacOrderSummary.secondaryWound.type?.toString(),
      required: true,
    },
    woundAge: {
      ...secondaryWoundDetails.woundAge,
      valid: Validate(
        Validator,
        "woundAge",
        vacOrderSummary.secondaryWound.age === null ||
          vacOrderSummary.secondaryWound.age?.toString() === "0"
          ? ""
          : vacOrderSummary.secondaryWound.age?.toString()
      )!,
      value:
        vacOrderSummary.secondaryWound.age === null
          ? ""
          : vacOrderSummary.secondaryWound.age?.toString(),
      required: true,
    },
    woundAgeType: {
      ...secondaryWoundDetails.woundAgeType,
      valid: Validate(
        Validator,
        "woundAgeType",
        vacOrderSummary.secondaryWound.ageType === null
          ? ""
          : vacOrderSummary.secondaryWound.ageType?.toString()
      )!,
      value:
        vacOrderSummary.secondaryWound.ageType === null
          ? (vacOrderSummary.secondaryWound.ageType = "2")
          : vacOrderSummary.secondaryWound.ageType?.toString(),
      required: true,
    },
    woundLocation: {
      ...secondaryWoundDetails.woundLocation,
      valid: Validate(
        Validator,
        "woundLocation",
        vacOrderSummary.secondaryWound.location === null
          ? ""
          : vacOrderSummary.secondaryWound.location?.toString()
      )!,
      value:
        vacOrderSummary.secondaryWound.location === null
          ? ""
          : vacOrderSummary.secondaryWound.location?.toString(),
      required: true,
    },
    woundDirection: {
      ...secondaryWoundDetails.woundDirection,
      valid: Validate(
        Validator,
        "woundDirection",
        vacOrderSummary.secondaryWound.locationWritten === null
          ? ""
          : vacOrderSummary.secondaryWound.locationWritten
              ?.toString()
              .split(",").length > 0
          ? vacOrderSummary.secondaryWound.locationWritten
              ?.toString()
              .split(",")[0]
              .toString()
          : ""
      )!,
      value:
        vacOrderSummary.secondaryWound.locationWritten === null
          ? ""
          : vacOrderSummary.secondaryWound.locationWritten
              ?.toString()
              .split(",").length > 0
          ? vacOrderSummary.secondaryWound.locationWritten
              ?.toString()
              .split(",")[0]
              .toString()
          : "",
      required: false,
    },
    woundOrientation: {
      ...secondaryWoundDetails.woundOrientation,
      valid: Validate(
        Validator,
        "woundOrientation",
        vacOrderSummary.secondaryWound.locationWritten === null
          ? ""
          : vacOrderSummary.secondaryWound.locationWritten
              ?.toString()
              .split(",").length >= 2
          ? vacOrderSummary.secondaryWound.locationWritten
              ?.toString()
              .split(",")[1]
              .toString()
          : ""
      )!,
      value:
        vacOrderSummary.secondaryWound.locationWritten === null
          ? ""
          : vacOrderSummary.secondaryWound.locationWritten
              ?.toString()
              .split(",").length >= 2
          ? vacOrderSummary.secondaryWound.locationWritten
              ?.toString()
              .split(",")[1]
              .toString()
          : "",
      required: false,
    },
    isTissuePresent: {
      ...secondaryWoundDetails.isTissuePresent,
      valid: Validate(
        Validator,
        "isTissuePresent",
        vacOrderSummary.secondaryWound.eschar === null
          ? ""
          : vacOrderSummary.secondaryWound.eschar === true
          ? "Yes"
          : "No"
      )!,
      value:
        vacOrderSummary.secondaryWound.eschar === null
          ? ""
          : vacOrderSummary.secondaryWound.eschar === true
          ? "Yes"
          : "No",
      required: true,
    },
    //wound undermining
    woundUndermining: {
      ...secondaryWoundDetails.woundUndermining,
      valid: Validate(
        Validator,
        "woundUndermining",
        vacOrderSummary.secondaryWound.underminingPresent === null
          ? ""
          : vacOrderSummary.secondaryWound.underminingPresent === true
          ? "Yes"
          : "No"
      )!,
      value:
        vacOrderSummary.secondaryWound.underminingPresent === null
          ? ""
          : vacOrderSummary.secondaryWound.underminingPresent === true
          ? "Yes"
          : "No",
      required:
        vacOrderSummary.secondaryWound.underminingPresent === null
          ? false
          : true,
    },
    underminingLocation1Depth: mapWoundUnderminingLocationDepth(
      vacOrderSummary,
      Validator,
      false,
      true,
      secondaryWoundDetails
    ),
    underminingLocation1PositionFrom: mapWoundUnderminingLocationPositionFrom(
      vacOrderSummary,
      Validator,
      false,
      true,
      secondaryWoundDetails
    ),
    underminingLocation1PositionTo: mapWoundUnderminingLocationPositionTo(
      vacOrderSummary,
      Validator,
      false,
      true,
      secondaryWoundDetails
    ),
    underminingLocation2Depth: mapWoundUnderminingLocationDepth(
      vacOrderSummary,
      Validator,
      false,
      false,
      secondaryWoundDetails
    ),
    underminingLocation2PositionFrom: mapWoundUnderminingLocationPositionFrom(
      vacOrderSummary,
      Validator,
      false,
      false,
      secondaryWoundDetails
    ),
    underminingLocation2PositionTo: mapWoundUnderminingLocationPositionTo(
      vacOrderSummary,
      Validator,
      false,
      false,
      secondaryWoundDetails
    ),
  };
  return secondaryWoundDetails;
};

export const mapWoundQuestionaries = (
  wound: any,
  woundQuestionary: WoundQuestionaries
) => {
  let additionalQuestion: any = woundQuestionary.additionalQuestion.map(
    (element: Question) => {
      if (element.text === "Is pressure over the wound being relieved?") {
        let returnValue =
          getBlankForNullValue(wound.arterialUlcerPressureRelieved) === ""
            ? ""
            : wound.arterialUlcerPressureRelieved === true
            ? "Yes"
            : "No";
        return {
          ...element,
          value: returnValue,
          required: "True",
          valid: ValidationStatus.VALID,
        };
      }
      if (element.text === "Is the patient being turned/positioned?") {
        let returnValue =
          getBlankForNullValue(wound.stageTurnedorPositioned) === ""
            ? ""
            : wound.stageTurnedorPositioned === true
            ? "Yes"
            : "No";
        return {
          ...element,
          value: returnValue,
          required: "True",
          valid: ValidationStatus.VALID,
        };
      }
      if (
        element.text ===
        "Has a group 2 or 3 surface been used for ulcer located on the posterior trunk or pelvis?"
      ) {
        let returnValue =
          getBlankForNullValue(wound.stageUlcerLocation) === ""
            ? ""
            : wound.stageUlcerLocation === true
            ? "Yes"
            : "No";
        return {
          ...element,
          value: returnValue,
          required: "True",
          valid: ValidationStatus.VALID,
        };
      }
      if (element.text === "Are moisture and/or incontinence being managed?") {
        let returnValue =
          getBlankForNullValue(wound.stageMoistureManagement) === ""
            ? ""
            : wound.stageMoistureManagement === true
            ? "Yes"
            : "No";
        return {
          ...element,
          value: returnValue,
          required: "True",
          valid: ValidationStatus.VALID,
        };
      }
      if (element.text === "Is pressure ulcer greater than 30 days?") {
        let returnValue =
          getBlankForNullValue(wound.stageGreaterThanThirtyDays) === ""
            ? ""
            : wound.stageGreaterThanThirtyDays === true
            ? "Yes"
            : "No";
        return {
          ...element,
          value: returnValue,
          required: "True",
          valid: ValidationStatus.VALID,
        };
      }
      if (
        element.text ===
        "Has a reduction of pressure on the foot ulcer been accomplished with appropriate modalities?"
      ) {
        let returnValue =
          getBlankForNullValue(wound.diabeticUlcer) === ""
            ? ""
            : wound.diabeticUlcer === true
            ? "Yes"
            : "No";
        return {
          ...element,
          value: returnValue,
          required: "True",
          valid: ValidationStatus.VALID,
        };
      }
      if (
        element.text ===
        "Has a reduction of pressure on the foot ulcer been accomplished with appropriate modalities?"
      ) {
        let returnValue =
          getBlankForNullValue(wound.neuropathicUlcerPressureReduction) === ""
            ? ""
            : wound.neuropathicUlcerPressureReduction === true
            ? "Yes"
            : "No";
        return {
          ...element,
          value: returnValue,
          required: "True",
          valid: ValidationStatus.VALID,
        };
      }
      if (
        element.text ===
        "Are compression bandages and/or garments being consistently applied?"
      ) {
        let returnValue =
          getBlankForNullValue(wound.venousStatisUlcerBandagesApplied) === ""
            ? ""
            : wound.venousStatisUlcerBandagesApplied === true
            ? "Yes"
            : "No";
        return {
          ...element,
          value: returnValue,
          required: "True",
          valid: ValidationStatus.VALID,
        };
      }
      if (element.text === "Is elevation/ambulation being encouraged?") {
        let returnValue =
          getBlankForNullValue(wound.venousStatisElevationEncouraged) === ""
            ? ""
            : wound.venousStatisElevationEncouraged === true
            ? "Yes"
            : "No";
        return {
          ...element,
          value: returnValue,
          required: "True",
          valid: ValidationStatus.VALID,
        };
      }
      if (
        element.text ===
        "Was the wound surgically created and not represented by descriptions above?"
      ) {
        let returnValue =
          getBlankForNullValue(wound.surgicallyCreated) === ""
            ? ""
            : wound.surgicallyCreated === true
            ? "Yes"
            : "No";
        return {
          ...element,
          isRequiredToShow: true,
          required: "True",
          valid: ValidationStatus.VALID,
          value: returnValue,
        };
      }
      if (element.text === "Description of the surgical procedure") {
        let returnValue =
          getBlankForNullValue(wound.surgicalProcedure) === ""
            ? ""
            : wound.surgicalProcedure?.trim();
        return {
          ...element,
          isRequiredToShow: wound.surgicallyCreated === true,
          required: wound.surgicallyCreated === true ? "True" : "False",
          valid: ValidationStatus.VALID,
          value: returnValue,
        };
      }
      if (element.text === "Date of surgical procedure") {
        let returnValue =
          getBlankForNullValue(wound.surgicalProcedureDate) === ""
            ? ""
            : wound.surgicalProcedureDate;
        return {
          ...element,
          isRequiredToShow: wound.surgicallyCreated === true,
          required: wound.surgicallyCreated === true ? "True" : "False",
          valid: ValidationStatus.VALID,
          value: returnValue,
        };
      }
    }
  );
  let woundQuestionaries: WoundQuestionaries = {
    woundType: woundQuestionary.woundType,
    category: woundQuestionary.category,
    additionalQuestion: additionalQuestion,
  };
  return woundQuestionaries;
};

export const getBlankForNullValue = (fieldValue: any | null | undefined) => {
  if (fieldValue === undefined) return "";
  else if (fieldValue === null) return "";
  else return fieldValue.toString();
};

export const mapRequesterInfo = (
  vacOrderSummarInfo: VacOrderSummaryData,
  loggedInUserFirstName: any,
  loggedInUserLastName: any,
  loggedInUserEmailAddress: any,
  existingPhoneNumber: any,
  Validator = new NewOrderValidator()
) => {
  let phoneNumber = vacOrderSummarInfo.requestor.phoneNumber;
  let requestor: IRequesterInfo = getDeepClone(defaultRequesterInfo);
  const checkForValidCondition = () => {
    return (
      vacOrderSummarInfo.requestor.name.toLocaleLowerCase() ===
        loggedInUserFirstName.toLocaleLowerCase() &&
      vacOrderSummarInfo.requestor.lastName.toLocaleLowerCase() ===
        loggedInUserLastName.toLocaleLowerCase() &&
      vacOrderSummarInfo.requestor.email === loggedInUserEmailAddress &&
      vacOrderSummarInfo.requestor.phoneNumber === existingPhoneNumber
    );
  };
  requestor = {
    IsRequesterSameasSubmitter: {
      ...requestor.IsRequesterSameasSubmitter,
      valid: ValidationStatus.VALID,
      value: checkForValidCondition() ? "yes" : "no",
    },
    requesterFirstName: {
      ...requestor.requesterFirstName,
      valid:
        vacOrderSummarInfo.requestor.name === loggedInUserFirstName
          ? ValidationStatus.VALID
          : Validate(
              Validator,
              "requesterFirstName",
              vacOrderSummarInfo.requestor.name
            )!,
      value: vacOrderSummarInfo.requestor.name,
    },
    requesterLastName: {
      ...requestor.requesterLastName,
      valid:
        vacOrderSummarInfo.requestor.name === loggedInUserFirstName
          ? ValidationStatus.VALID
          : Validate(
              Validator,
              "requesterLastName",
              vacOrderSummarInfo.requestor.lastName
            )!,
      value: vacOrderSummarInfo.requestor.lastName,
    },
    requesterEmail: {
      ...requestor.requesterEmail,
      valid:
        vacOrderSummarInfo.requestor.name === loggedInUserFirstName
          ? ValidationStatus.VALID
          : Validate(
              Validator,
              "requesterEmail",
              vacOrderSummarInfo.requestor.email
            )!,
      value: vacOrderSummarInfo.requestor.email,
    },
    requesterPhoneNumber: {
      ...requestor.requesterPhoneNumber,
      valid: Validate(Validator, "requesterPhoneNumber", phoneNumber ?? "")!,
      value: phoneNumber ?? "",
    },
    requesterTitle: {
      ...requestor.requesterTitle,
      valid:
        vacOrderSummarInfo.requestor.name === loggedInUserFirstName
          ? ValidationStatus.VALID
          : Validate(
              Validator,
              "requesterTitle",
              vacOrderSummarInfo.requestor?.title!
            )!,
      value: vacOrderSummarInfo.requestor?.title!,
      required: checkForValidCondition() ? false : true,
    },
    requesterFacility: requestorFacility(vacOrderSummarInfo),
    requesterFacilityAsDefault: false,
  };
  return requestor;
};

export const mapProductInfo = (vacOrderSummarInfo: VacOrderSummaryData) => {
  let product: IProductInfo = getDeepClone(defaultProductInfo);
  product = {
    // Product Information
    productInformation: {
      ...product.productInformation,
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.isFromReadyCare === true ? "yes" : "no",
    },
    productValue: {
      ...product.productValue,
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.vacUnit.toString(),
    },
  };
  return product;
};

export const mapDressingKit = () => {
  let dressing: IDressingKit = getDeepClone(defaultDressingKit);
  dressing = {
    //primary Vac Dressing KIT
    productId: {
      ...dressing.productId,
      valid: ValidationStatus.UNTOUCHED,
      value: "",
    },
    productCode: {
      ...dressing.productCode,
      valid: ValidationStatus.VALID,
      value: "",
    },
    productName: {
      ...dressing.productName,
      valid: ValidationStatus.UNTOUCHED,
      value: "",
    },
    productSizeCode: {
      ...dressing.productSizeCode,
      valid: ValidationStatus.VALID,
      value: "",
    },
    productSizeName: {
      ...dressing.productSizeName,
      valid: ValidationStatus.UNTOUCHED,
      value: "",
    },
    productQuantity: {
      ...dressing.productQuantity,
      valid: ValidationStatus.VALID,
      value: "1",
    },
    productSizeID: {
      ...dressing.productSizeID,
      valid: ValidationStatus.VALID,
      value: "",
    },
    //secondary Vac Dressing KIT
    secProductId: {
      ...dressing.secProductId,
      valid: ValidationStatus.VALID,
      value: "",
    },
    secProductCode: {
      ...dressing.secProductCode,
      valid: ValidationStatus.VALID,
      value: "",
    },
    secProductName: {
      ...dressing.secProductName,
      valid: ValidationStatus.UNTOUCHED,
      value: "",
    },
    secProductSizeCode: {
      ...dressing.secProductSizeCode,
      valid: ValidationStatus.VALID,
      value: "",
    },
    secProductSizeName: {
      ...dressing.secProductSizeName,
      valid: ValidationStatus.UNTOUCHED,
      value: "",
    },
    secProductQuantity: {
      ...dressing.secProductQuantity,
      valid: ValidationStatus.VALID,
      value: "",
    },
    secProductSizeID: {
      ...dressing.secProductSizeID,
      valid: ValidationStatus.VALID,
      value: "",
    },
  };
  return dressing;
};

export const mapCanister = (
  vacOrderSummarInfo: VacOrderSummaryData
): ICanister => {
  return {
    // Canister
    canisterProductName: {
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.canister.productName,
    },
    canisterProductCode: {
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.canister.sku,
    },
    canisterProductQuantity: {
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.isFromReadyCare
        ? (vacOrderSummarInfo.canister.quantity === 2
            ? vacOrderSummarInfo.canister.quantity - 1
            : 0
          ).toString()
        : vacOrderSummarInfo.canister.quantity.toString(),
      isOptional: true,
      isDefaultValid: true,
    },
    canisterProductID: {
      valid: ValidationStatus.VALID,
      value: vacOrderSummarInfo.canister.sku,
    },
  };
};

export const mapAccessory = (
  vacOrderSummarInfo: VacOrderSummaryData
): IProductAccessory => {
  return {
    // Accessory
    accessories: getAccessoriesObj(vacOrderSummarInfo.accessories),
  };
};

export const mapDeliveryInformation = (
  vacOrderSummarInfo: VacOrderSummaryData,
  Validator = new NewOrderValidator()
) => {
  let delieveryInformationData: IDeliveryInformation = getDeepClone(
    defaultDeliveryInformation
  );
  delieveryInformationData = {
    // Delivery Information
    deliveryProductNeedByDate: {
      ...delieveryInformationData.deliveryProductNeedByDate,
      valid: ValidationStatus.UNTOUCHED,
      value: "Need to change",
    },
    deliveryProductNeedByTime: {
      ...delieveryInformationData.deliveryProductNeedByTime,
      valid: ValidationStatus.UNTOUCHED,
      value: "Need to change",
    },
    //Since the value is not coming exactly as while saving
    deliverySiteType: {
      valid: Validate(
        Validator,
        "deliverySiteType",
        vacOrderSummarInfo.deliverySiteType === 0
          ? ""
          : vacOrderSummarInfo.deliverySiteType?.toString()
      )!,
      value:
        vacOrderSummarInfo.deliverySiteType === 0
          ? ""
          : vacOrderSummarInfo.deliverySiteType?.toString(),
    },
    deliveryFacilityName: {
      valid: Validate(
        Validator,
        "deliveryFacilityName",
        vacOrderSummarInfo.deliverySiteType === 0
          ? ""
          : vacOrderSummarInfo.deliverySiteName
      )!,
      value: vacOrderSummarInfo.deliverySiteName,
    },
    deliveryAddressLine1: {
      valid: Validate(
        Validator,
        "deliveryAddressLine1",
        vacOrderSummarInfo.deliverySiteType === 0
          ? ""
          : vacOrderSummarInfo.deliveryAddress.addressLine1
      )!,
      value: vacOrderSummarInfo.deliveryAddress.addressLine1,
    },
    deliveryAddressLine2: {
      valid: Validate(
        Validator,
        "deliveryAddressLine2",
        vacOrderSummarInfo.deliverySiteType === 0
          ? ""
          : vacOrderSummarInfo.deliveryAddress.addressLine2
      )!,
      value: vacOrderSummarInfo.deliveryAddress.addressLine2,
      isDefaultValid: true,
    },
    deliveryCity: {
      valid: Validate(
        Validator,
        "deliveryCity",
        vacOrderSummarInfo.deliverySiteType === 0
          ? ""
          : vacOrderSummarInfo.deliveryAddress.city
      )!,
      value: vacOrderSummarInfo.deliveryAddress.city,
    },
    deliveryState: {
      valid: Validate(
        Validator,
        "deliveryState",
        vacOrderSummarInfo.deliverySiteType === 0
          ? ""
          : vacOrderSummarInfo.deliveryAddress.stateCode
      )!,
      value: vacOrderSummarInfo.deliveryAddress.stateCode,
    },
    deliveryZipCode: {
      valid: Validate(
        Validator,
        "deliveryZipCode",
        vacOrderSummarInfo.deliverySiteType === 0
          ? ""
          : vacOrderSummarInfo.deliveryAddress.postalCode
      )!,
      value: vacOrderSummarInfo.deliveryAddress.postalCode,
    },
  };
  return delieveryInformationData;
};

export const mapIsOsteomyelitisPresent = (
  vacOrderSummarInfo: VacOrderSummaryData,
  Validator: NewOrderValidator,
  componentId: string,
  orderId: number
) => {
  const value =
    vacOrderSummarInfo.clinicalInformation.osteomyelitisPresent === null
      ? ""
      : vacOrderSummarInfo.clinicalInformation.osteomyelitisPresent === true
      ? "Yes"
      : "No";
  const isValid = Validator?.validate(value, "isOsteomyelitisPresent");
  return {
    valid:
      isValid!.status === ValidationStatus.INVALID
        ? ValidationStatus.UNTOUCHED
        : isValid!.status,
    value: value,
    required: true,
    title: "Osteomyelitis",
    componentId: componentId,
    order: orderId,
  };
};

export const mapOsteomyelitisies = (
  vacOrderSummarInfo: VacOrderSummaryData,
  Validator: NewOrderValidator
) => {
  const osteomyelitisiesLocal =
    vacOrderSummarInfo.clinicalInformation.osteomyelitisTreatmentRegimen;
  const selectedOsteomyelitisies =
    osteomyelitisiesLocal && osteomyelitisiesLocal !== ""
      ? vacOrderSummarInfo.clinicalInformation.osteomyelitisTreatmentRegimen.split(
          ","
        )
      : [];
  const sortedArrayData = selectedOsteomyelitisies.map(
    (osteomyelitis: string) => {
      let output = osteomyelitis.split(":");
      return { title: output[0], value: output[1] };
    }
  );
  const finalOsteomyelitisies = osteomyelitisies.map((osteomyelitis: any) => {
    sortedArrayData.filter((dict: { title: string; value: string }) => {
      if (osteomyelitis.value === dict.title) {
        osteomyelitis.selected = true;
        if (dict.value) {
          osteomyelitis.isRequiredTextBox = dict.value.length > 0;
          osteomyelitis.textBoxValue = dict.value;
          const isValid = Validator.validate(
            dict.value,
            "osteomyelitisItemText"
          );
          osteomyelitis.isTextBoxValueValid =
            isValid!.status === ValidationStatus.INVALID
              ? ValidationStatus.UNTOUCHED
              : isValid!.status;
        }
      }
    });
    return osteomyelitis;
  });
  const isValid =
    selectedOsteomyelitisies.length > 0
      ? finalOsteomyelitisies.some(
          (dt: any) =>
            dt.textBoxValue &&
            dt.textBoxValue !== "" &&
            dt.isTextBoxValueValid === ValidationStatus.UNTOUCHED
        )
        ? ValidationStatus.UNTOUCHED
        : ValidationStatus.VALID
      : ValidationStatus.UNTOUCHED;
  return {
    componentId: "osteomyelitis-check",
    order: 11,
    required:
      vacOrderSummarInfo.clinicalInformation.osteomyelitisPresent ?? false,
    valid: isValid,
    value: finalOsteomyelitisies,
    title: "Treatment Regimen / Antibiotic Name",
  };
};

export const mapWoundDimensionLength = (
  dimension: Wound,
  Validator: NewOrderValidator,
  woundInfoData: any
) => {
  const woundDimensionLength =
    dimension?.length === null
      ? ""
      : dimension?.length?.toString() === "0"
      ? ""
      : dimension?.length?.toString();
  let isValid =
    woundDimensionLength === ""
      ? getUntouchedObj()
      : Validator.validate(woundDimensionLength!, "woundLength");

  return {
    ...woundInfoData.woundLength,
    valid:
      isValid!.status === ValidationStatus.INVALID
        ? ValidationStatus.UNTOUCHED
        : isValid!.status,
    value: dimension?.length === null ? "" : dimension?.length.toString(),
    required: dimension?.length === null ? false : true,
    title: "Length",
  };
};

export const mapWoundDimensionWidth = (
  wound: Wound,
  Validator: NewOrderValidator,
  woundInfoData: any
) => {
  const woundDimensionWidth =
    wound?.width === null
      ? ""
      : wound?.width?.toString() === "0"
      ? ""
      : wound?.width?.toString();
  let isValid =
    woundDimensionWidth === ""
      ? getUntouchedObj()
      : Validator.validate(woundDimensionWidth!, "woundWidth");
  return {
    ...woundInfoData.woundWidth,
    valid:
      isValid!.status === ValidationStatus.INVALID
        ? ValidationStatus.UNTOUCHED
        : isValid!.status,
    value: wound?.width === null ? "" : wound?.width.toString(),
    required: wound?.width === null ? false : true,
    title: "width",
  };
};

export const mapWoundDimensionDepth = (
  wound: Wound,
  Validator: NewOrderValidator,
  woundInfoData: any
) => {
  const woundDimensionDepth =
    wound?.depth === null
      ? ""
      : wound?.depth?.toString() === "0"
      ? ""
      : wound?.depth?.toString();
  let isValid =
    woundDimensionDepth === ""
      ? getUntouchedObj()
      : Validator.validate(woundDimensionDepth!, "woundDepth");
  return {
    ...woundInfoData.woundDepth,
    valid:
      isValid!.status === ValidationStatus.INVALID
        ? ValidationStatus.UNTOUCHED
        : isValid!.status,
    value: wound?.depth === null ? "" : wound?.depth?.toString(),
    required: wound?.depth === null ? false : true,
    title: "Depth",
  };
};

// Woound Undermining
export const mapWoundUnderminingLocationDepth = (
  vacOrderSummarInfo: VacOrderSummaryData,
  Validator: NewOrderValidator,
  isPrimaryWound: boolean,
  isFirstLocation: boolean,
  woundInfoUnderminingData: any
) => {
  const wound = isPrimaryWound
    ? vacOrderSummarInfo.primaryWound
    : vacOrderSummarInfo.secondaryWound;
  const woundUnderminigDepth = isFirstLocation
    ? wound.underminingLocation1
    : wound.underminingLocation2;
  const woundUnderminigDepthValue =
    woundUnderminigDepth.depth === null ||
    woundUnderminigDepth.depth.toString() === "0"
      ? ""
      : woundUnderminigDepth.depth.toString();
  let isValid =
    woundUnderminigDepthValue === ""
      ? getUntouchedObj()
      : Validator.validate(
          woundUnderminigDepthValue,
          isFirstLocation
            ? "underminingLocation1Depth"
            : "underminingLocation2Depth"
        );
  let title = "Location Depth";
  return {
    ...woundInfoUnderminingData,
    valid:
      isValid!.status === ValidationStatus.INVALID
        ? ValidationStatus.UNTOUCHED
        : isValid!.status,
    value: woundUnderminigDepthValue,
    required: false,
    title: title,
    componentId: isFirstLocation
      ? woundInfoUnderminingData.underminingLocation1Depth.componentId
      : woundInfoUnderminingData.underminingLocation2Depth.componentId,
    order: isFirstLocation
      ? woundInfoUnderminingData.underminingLocation1Depth.order
      : woundInfoUnderminingData.underminingLocation2Depth.order,
    id: isFirstLocation
      ? woundInfoUnderminingData.underminingLocation1Depth.id
      : woundInfoUnderminingData.underminingLocation2Depth.id,
  };
};

const mapWoundUnderminingLocationPositionFrom = (
  vacOrderSummary: VacOrderSummaryData,
  Validator: NewOrderValidator,
  isPrimaryWound: boolean,
  isFirstLocation: boolean,
  woundInfoLocationFromData: any
) => {
  const wound = isPrimaryWound
    ? vacOrderSummary.primaryWound
    : vacOrderSummary.secondaryWound;
  const underminingLocation = isFirstLocation
    ? wound?.underminingLocation1
    : wound?.underminingLocation2;
  const underminingLocationFrom =
    underminingLocation?.area && underminingLocation?.area.length > 0
      ? underminingLocation?.area.split(" to ").length > 0
        ? underminingLocation?.area.split(" to ")[0].toString()
        : ""
      : "";
  let isValid = Validator.validate(
    underminingLocationFrom,
    isFirstLocation
      ? "underminingLocation1PositionFrom"
      : "underminingLocation2PositionFrom"
  );
  return {
    valid:
      isValid!.status === ValidationStatus.INVALID
        ? ValidationStatus.UNTOUCHED
        : isValid!.status,
    value: underminingLocationFrom,
    required:
      underminingLocationFrom.toString().length > 0
        ? true
        : isFirstLocation
        ? wound?.underminingPresent ?? false
        : false,
    title: "Starting Position",
    componentId: isFirstLocation
      ? woundInfoLocationFromData.underminingLocation1PositionFrom.componentId
      : woundInfoLocationFromData.underminingLocation2PositionFrom.componentId,
    order: isFirstLocation
      ? woundInfoLocationFromData.underminingLocation1PositionFrom.order
      : woundInfoLocationFromData.underminingLocation2PositionFrom.order,
  };
};

const mapWoundUnderminingLocationPositionTo = (
  vacOrderSummary: VacOrderSummaryData,
  Validator: NewOrderValidator,
  isPrimaryWound: boolean,
  isFirstLocation: boolean,
  woundInfoLocationToData: any
) => {
  const wound = isPrimaryWound
    ? vacOrderSummary.primaryWound
    : vacOrderSummary.secondaryWound;
  const underminingLocation = isFirstLocation
    ? wound?.underminingLocation1
    : wound?.underminingLocation2;
  let underminingLocationTo =
    underminingLocation?.area && underminingLocation?.area.length > 0
      ? underminingLocation?.area.split(" to ").length >= 2
        ? underminingLocation?.area.split(" to ")[1].toString()
        : ""
      : "";
  let isValid = Validator.validate(
    underminingLocationTo,
    isFirstLocation
      ? "underminingLocation1PositionTo"
      : "underminingLocation2PositionTo"
  );
  return {
    valid:
      isValid?.status !== ValidationStatus.VALID
        ? ValidationStatus.UNTOUCHED
        : ValidationStatus.VALID,
    value: underminingLocationTo,
    required:
      underminingLocationTo.toString().length > 0
        ? true
        : isFirstLocation
        ? wound?.underminingPresent ?? false
        : false,
    title: "Ending Position",
    componentId: isFirstLocation
      ? woundInfoLocationToData.underminingLocation1PositionTo.componentId
      : woundInfoLocationToData.underminingLocation2PositionTo.componentId,
    order: isFirstLocation
      ? woundInfoLocationToData.underminingLocation1PositionTo.order
      : woundInfoLocationToData.underminingLocation2PositionTo.order,
  };
};

// Wound Tunneling
export const mapWoundTunnelingDepth = (
  vacOrderSummarInfo: VacOrderSummaryData,
  Validator: NewOrderValidator,
  isPrimaryWound: boolean,
  isFirstLocation: boolean,
  woundTunnelingLocationDepthData: any
) => {
  const wound = isPrimaryWound
    ? vacOrderSummarInfo.primaryWound
    : vacOrderSummarInfo.secondaryWound;
  const tunneling = isFirstLocation
    ? wound?.tunnelingSinusLocation1
    : wound?.tunnelingSinusLocation2;
  const tunnelingDepth =
    tunneling?.depth === null || tunneling?.depth.toString() === "0"
      ? ""
      : tunneling?.depth.toString();
  let isValid =
    tunnelingDepth === ""
      ? getUntouchedObj()
      : Validator.validate(
          tunnelingDepth,
          isFirstLocation ? "location1Depth" : "location2Depth"
        );
  return {
    valid:
      isValid!.status === ValidationStatus.INVALID
        ? ValidationStatus.UNTOUCHED
        : isValid!.status,
    value: tunnelingDepth,
    required: false,
    title: "Location Depth",
    componentId: isFirstLocation
      ? woundTunnelingLocationDepthData.location1Depth.componentId
      : woundTunnelingLocationDepthData.location2Depth.componentId,
    order: isFirstLocation
      ? woundTunnelingLocationDepthData.location1Depth.order
      : woundTunnelingLocationDepthData.location2Depth.order,
    id: isFirstLocation
      ? woundTunnelingLocationDepthData.location1Depth.id
      : woundTunnelingLocationDepthData.location2Depth.id,
  };
};

const mapWoundTunnelingLocation = (
  vacOrderSummary: VacOrderSummaryData,
  Validator: NewOrderValidator,
  isPrimaryWound: boolean,
  isFirstLocation: boolean,
  woundInfoTunnelingLocationData: any
) => {
  const wound = isPrimaryWound
    ? vacOrderSummary.primaryWound
    : vacOrderSummary.secondaryWound;
  const tunnelingLocation = isFirstLocation
    ? wound?.tunnelingSinusLocation1
    : wound?.tunnelingSinusLocation2;
  const tunnelingLocationFrom =
    tunnelingLocation?.area && tunnelingLocation?.area.length > 0
      ? tunnelingLocation?.area.split(" to ").length > 0
        ? tunnelingLocation?.area.split(" to ")[0].toString()
        : ""
      : "";
  let isValid = Validator.validate(
    tunnelingLocationFrom,
    isFirstLocation ? "location1Position" : "location2Position"
  );
  return {
    valid:
      isValid?.status !== ValidationStatus.VALID
        ? ValidationStatus.UNTOUCHED
        : ValidationStatus.VALID,
    value: tunnelingLocationFrom,
    required: false,
    title: "Clock Position",
    componentId: isFirstLocation
      ? woundInfoTunnelingLocationData.location1Position.componentId
      : woundInfoTunnelingLocationData.location2Position.componentId,
    order: isFirstLocation
      ? woundInfoTunnelingLocationData.location1Position.order
      : woundInfoTunnelingLocationData.location2Position.order,
  };
};

export const fetchDeliveryContactSameAsRequesterStatus = (
  vacOrderSummarInfo: VacOrderSummaryData,
  AuthObj: any
) => {
  const AuthProfilePhoneNumber =
    AuthObj.userProfile.phoneNumber || AuthObj.userProfile.mobilePhoneNumber
      ? AuthObj.userProfile.phoneNumber
        ? AuthObj.userProfile.phoneNumber
        : AuthObj.userProfile.mobilePhoneNumber
      : "";
  if (
    vacOrderSummarInfo.customerFirstName.toLowerCase() ===
      vacOrderSummarInfo.deliveryFirstName.toLowerCase() &&
    vacOrderSummarInfo.customerLastName.toLowerCase() ===
      vacOrderSummarInfo.deliveryLastName.toLowerCase() &&
    vacOrderSummarInfo.customerPhoneNo ===
      vacOrderSummarInfo.deliveryPhoneNumber
  ) {
    return "1";
  } else if (
    vacOrderSummarInfo.requestor.name.toLowerCase() ===
      vacOrderSummarInfo.deliveryFirstName.toLowerCase() &&
    vacOrderSummarInfo.requestor.lastName.toLowerCase() ===
      vacOrderSummarInfo.deliveryLastName.toLowerCase() &&
    vacOrderSummarInfo.requestor.phoneNumber ===
      vacOrderSummarInfo.deliveryPhoneNumber
  ) {
    return "2";
  } else if (
    AuthObj?.userProfile?.firstName.toLowerCase() ===
      vacOrderSummarInfo.deliveryFirstName.toLowerCase() &&
    AuthObj?.userProfile?.lastName.toLowerCase() ===
      vacOrderSummarInfo.deliveryLastName.toLowerCase() &&
    AuthProfilePhoneNumber === vacOrderSummarInfo.deliveryPhoneNumber
  ) {
    return "3";
  } else {
    return "4";
  }
};
