import "./ssosideNav.css";
import {
  NavigationBarDiv,
  OptionLink,
  OptionLinkActive,
  OptionLinkActivePopup,
  OptionLinkPopup,
  OptionLinkWrapper,
} from "./ssosideNav.style";
import {
  ISSOSideNavInput,
  SSOSideNavPageType,
} from "./ssoSideNavMenuOption.interface";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../context/InternalSignOnContext";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../context/RolesPermissionContext";
import { useMediaQuery } from "@mui/material";
import { Popup } from "../../core/popup/popup.component";
import { getProperty } from "../../util/ObjectFunctions";
import { useHistory, useLocation } from "react-router-dom";
import { ReactNode, useContext, useEffect, useState } from "react";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import { CustomDropDown } from "../../core/customDropdown/customDropdown.component";
import { InputWithLabel } from "../../core/inputWithLabel/inputWithLabel.component";
import { ReactComponent as ArrowDropDownIcon } from "../../assets/ArrowBlueDropDownIcon.svg";
import { SearchHomeCareOrderContainer } from "../searchHomeCareOrder/searchHomeCareOrderContainer.component";
import { PageSection } from "../searchHomeCareOrder/searchHomeCareOrderContainer.enum";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../context/NewOrderContext";
import {
  ISalesRoundingContextType,
  SaleRoundingToolContext,
} from "../../context/SalesRoundingToolContext";

export const SSoSideNav = () => {
  const history = useHistory();
  const location = useLocation();
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const [open, setOpen] = useState(false);
  const [selectedPopup, setSelectedPopup] = useState("");
  const [menuOptions, setMenuOptions] = useState<ISSOSideNavInput[]>([]);
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const sideNavData = internalObj?.ssoSideNavMenuOptionModelData!;
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const salesRoundingObj = useContext<ISalesRoundingContextType | null>(
    SaleRoundingToolContext
  );
  const defaultPageSection = PageSection.SEARCH_HOME_CARE_ORDER_FORM;
  const [currentSection, setCurrentSection] =
    useState<PageSection>(defaultPageSection);
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);

  useEffect(() => {
    let tempMenuOptions: ISSOSideNavInput[] = Object.keys(sideNavData)
      ?.map((x: any) => {
        const sideNav = getProperty(sideNavData, x);
        return sideNav.isVisible ? sideNav : null;
      })
      .filter((option: ISSOSideNavInput | null) => {
        return option !== null;
      });
    setMenuOptions(tempMenuOptions);
  }, [internalObj?.ssoSideNavMenuOptionModelData]);

  useEffect(() => {
    if (open) {
      setCurrentSection(defaultPageSection);
    }
  }, [open]);

  const handleButtonClick = (selectedOption: any) => {
    let value = selectedOption;
    if (value.type === "div") {
      value = value.props?.children[0];
    }
    if (value === "Administration") {
      internalObj?.setIsInternalAdministration(true);
      authObj?.setregisteredFaciltyAddress(undefined);
      authObj?.setIsInternalUserFacilitySelected(false);
    }
    if (value === "Order V.A.C.® Therapy") {
      internalObj?.setIsVACOrderSSO(true);
      newOrderObj?.resetNewOrderForm();
    }
    if (value === "Sales Rounding") {
      salesRoundingObj?.resetContext();
    }
  };

  const handlePopupClick = (label: string) => {
    setOpen(true);
    setSelectedPopup(label);
  };

  const handleMenuAction = (e: any) => {
    let value = e.target.value;
    if (value.type === "div") {
      value = value.props?.children[0];
    }
    let selectedSideNav: string | null = null;
    let selectedSideNavInput: ISSOSideNavInput | null = null;
    const sideNavs = Object.keys(sideNavData);
    for (const x of sideNavs) {
      const sideNav: ISSOSideNavInput = getProperty(sideNavData, x);
      let labelText: any = sideNav.labelText;
      if (labelText.type === "div") {
        labelText = labelText.props?.children[0];
      }
      if (value === labelText) {
        selectedSideNav = x;
        selectedSideNavInput = sideNav;
        break;
      }
    }
    if (selectedSideNavInput && selectedSideNavInput.isVisible === true) {
      if (selectedSideNavInput.pageType === SSOSideNavPageType.POPUP) {
        handlePopupClick(selectedSideNavInput.labelText);
      } else {
        if (selectedSideNav === "internalAdministration") {
          internalObj?.setIsInternalAdministration(true);
          authObj?.setregisteredFaciltyAddress(undefined);
          authObj?.setIsInternalUserFacilitySelected(false);
          history.push(`/${selectedSideNav}`);
        }
        if (selectedSideNav === "orderVACTherapy") {
          internalObj?.setIsVACOrderSSO(true);
          newOrderObj?.resetNewOrderForm();
          history.push("/orders/newOrder");
        }
        if (selectedSideNav === "viewReports") {
          history.push("/viewReports");
        }
        if (selectedSideNav === "salesRoundingTool") {
          salesRoundingObj?.resetContext();
          history.push({
            pathname: "/salesRounding",
            state: { stateData: internalObj?.selectedTerritoryObj },
          });
        }
      }
    }
  };

  const navOptionList = Object.keys(sideNavData)?.map((x: any, index) => {
    if (
      getProperty(sideNavData, x).isVisible === true &&
      getProperty(sideNavData, x).pageType === SSOSideNavPageType.POPUP
    ) {
      return open ? (
        <OptionLinkWrapper
          className="sideNavBar"
          data-testid={`sideNavBar${index + 1}`}
          key={x}
        >
          <OptionLinkActivePopup
            onClick={() =>
              handlePopupClick(getProperty(sideNavData, x).labelText)
            }
          >
            {getProperty(sideNavData, x).labelText}
          </OptionLinkActivePopup>
        </OptionLinkWrapper>
      ) : (
        <OptionLinkWrapper
          className="sideNavBar"
          data-testid={`sideNavBar${index + 1}`}
          key={x}
        >
          <OptionLinkPopup
            onClick={() =>
              handlePopupClick(getProperty(sideNavData, x).labelText)
            }
          >
            {getProperty(sideNavData, x).labelText}
          </OptionLinkPopup>
        </OptionLinkWrapper>
      );
    }
    if (getProperty(sideNavData, x).isVisible === true) {
      return location.pathname.includes(
        `/${getProperty(sideNavData, x).path}`
      ) ? (
        <OptionLinkWrapper
          className="sideNavBar"
          data-testid={`sideNavBar${index + 1}`}
          key={x}
        >
          <OptionLinkActive
            to={{
              pathname: `/${getProperty(sideNavData, x).path}`,
              state: { stateData: internalObj?.selectedTerritoryObj },
            }}
            onClick={() =>
              handleButtonClick(getProperty(sideNavData, x).labelText)
            }
          >
            {getProperty(sideNavData, x).labelText}
          </OptionLinkActive>
        </OptionLinkWrapper>
      ) : (
        <OptionLinkWrapper
          className="sideNavBar"
          data-testid={`sideNavBar${index + 1}`}
          key={x}
        >
          <OptionLink
            to={{
              pathname: `/${getProperty(sideNavData, x).path}`,
              state: { stateData: internalObj?.selectedTerritoryObj },
            }}
            onClick={() =>
              handleButtonClick(getProperty(sideNavData, x).labelText)
            }
          >
            {getProperty(sideNavData, x).labelText}
          </OptionLink>
        </OptionLinkWrapper>
      );
    }
  });

  const desktopSideNav = (
    <NavigationBarDiv data-testid="test-nav-bar">
      {navOptionList}
    </NavigationBarDiv>
  );

  const mobileSideNav = (
    <InputWithLabel
      componentClassName="sso-side-nav-menu-input-label"
      isDropdown={true}
    >
      <CustomDropDown
        handleChange={handleMenuAction}
        iconPropsClassName="arrow-dropdown-icon"
        iconComponent={ArrowDropDownIcon}
        menuItem={menuOptions.map(
          (sideNav: ISSOSideNavInput) => sideNav.labelText
        )}
        menuOptionClassName="sso-side-name-menu-option"
        name="ssoSideNavMenu"
        paperPropsClassName="sso-side-nav-paper-props"
        placeHolder="Actions"
        selectClassName="sso-side-name-menu-input"
        selectpropsClassName="sso-side-name-menu-select"
        testId="ssoSideNavMenu-DropDown"
        value=""
      />
    </InputWithLabel>
  );

  const renderPopupDisplay = (value: string) => {
    let page: ReactNode;
    switch (value) {
      case "Search for Home Care Order":
        page = (
          <SearchHomeCareOrderContainer
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
          />
        );
        break;
    }
    return page;
  };

  const closeAllPopUp = () => {
    setOpen(false);
  };
  return (
    <>
      {isMobileScreen ? mobileSideNav : desktopSideNav}
      <Popup
        openFlag={open}
        closeHandler={closeAllPopUp}
        dialogParentClass={currentSection}
      >
        {renderPopupDisplay(selectedPopup)}
      </Popup>
    </>
  );
};
