import { Tab, Typography } from "@mui/material";
import "./inventoryTabContainer.css";
import { ScrollableTabHeader } from "../../core/scrollableTabs/scrollableTabHeader.component";
import { ReactNode, useContext, useEffect, useState } from "react";
import {
  ICustomerPlusListingReqBody,
  IInventoryMenuActionItem,
  IInventoryProductDetails,
  InventoryContainerTabs,
} from "./inventoryTabContainer.enum";
import { AuthContextType, AuthContext } from "../../context/AuthContext";
import {
  getCustomerPlusListData,
  getInventoryInfoList,
} from "../../util/inventoryMgrService";
import { LoadingSpinner } from "../../core/loader/LoadingSpinner";
import { Popup } from "../../core/popup/popup.component";
import { GET_INVENTORY_LIST_FAILED } from "../../util/staticText";
import { useHistory } from "react-router-dom";
import VacReadyCareInventory from "./vacReadyCareInventory/vacReadyCareInventory.component";
import { StoredProductInventory } from "./storedProductInventory/storedProductInventory.component";
import ErrorPopup, {
  IErrorPopUp,
} from "../../core/errorPopup/errorPopup.component";
import { getCSVDownload } from "../../util/3meService";
import { ICSVReqBody } from "../myPatients/patient.interface";
import {
  getAcuteMenuActionPermission,
  getCSV,
  getFacilityAccountNumber,
  getSiteUseId,
} from "../../util/utilityFunctions";
import { ERROR_MSG_DOWNLOAD_INVENTORY_READY_CARE } from "../../util/errorMsg";
import {
  InventoryContextType,
  InventoryContext,
} from "../../context/InventoryContext";
import { REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE } from "../../util/errorCode";
import {
  RolesPermissionContextType,
  RolesPermissionContext,
} from "../../context/RolesPermissionContext";
import CustomerPlusInventory from "./customerPlusInventory/customerPlusInventory.component";
import {
  CustomerPlusListingTableTypes,
  ICustomerListingData,
  ICustomerPlusListingDetails,
} from "./customerPlusInventory/customerPlusInventory.interface";

const initialCustomerListingData: ICustomerListingData = {
  availableUnits: [],
  unitsInUse: [],
  unitsNeedingPreparation: [],
};

export const InventoryTabContainer = () => {
  const [isToShowErrorCode, setIsToShowErrorCode] = useState<boolean>(true);
  const [isToShowPhoneNumber, setIsToShowPhoneNumber] = useState<boolean>(true);
  const inventoryObj = useContext<InventoryContextType | null>(
    InventoryContext
  );
  const selectedTab = inventoryObj!.selectedTab;
  const isFromSideNav = inventoryObj!.isFromSideNav;
  const scrollTabValue = inventoryObj!.scrollTabValue;
  const setSelectedTab = inventoryObj!.setSelectedTab;
  const setIsFromSideNav = inventoryObj!.setIsFromSideNav;
  const setScrollTabValue = inventoryObj!.setScrollTabValue;
  const history = useHistory();

  const setOrderSelectedTab = async (selectedTabDetails: any) => {
    setSelectedTab(selectedTabDetails);
    const index = inventoryPermissionArray.indexOf(selectedTabDetails);
    setScrollTabValue(index !== -1 ? index : 0);
  };
  const [readyCareData, setReadyCareData] = useState<
    IInventoryProductDetails[] | null
  >(null);
  const [storedProductData, setStoredProductData] = useState<
    IInventoryProductDetails[] | null
  >(null);
  const [isInventoryAPIFailed, setIsInventoryAPIFailed] =
    useState<boolean>(false);
  const [customerPlusListingData, setCustomerPlusListingData] =
    useState<ICustomerListingData>(initialCustomerListingData);
  const [originalCustomerPlusData, setoriginalCustomerPlusData] = useState<
    ICustomerPlusListingDetails[]
  >([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorPopupFlag, setErrorPopUpFlag] = useState<boolean>(false);
  const [isCustomerPlusDataLoaded, setIsCustomerPlusDataLoaded] =
    useState<boolean>(false);

  const authObj = useContext<AuthContextType | null>(AuthContext);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const [inventoryPermissionArray, setInventoryPermissionArray] = useState<any>(
    []
  );

  const getUpdatedMenuActionsProductData = (
    customerObj: ICustomerPlusListingDetails
  ): ICustomerPlusListingDetails => {
    let tempCustomerpluseData = customerObj;
    tempCustomerpluseData.menuItems = tempCustomerpluseData.menuItems
      .map((x) => {
        let menuItemPermission = getAcuteMenuActionPermission(
          x.sequenceText,
          permissionObj!.mappedRolesPermissionData
        );
        if (menuItemPermission) {
          return x;
        } else return null;
      })
      .filter((x) => x !== null) as IInventoryMenuActionItem[];
    return tempCustomerpluseData;
  };

  const getCustomerPlusListingData = async () => {
    setLoader(true);
    const customerPlusListingReqBody: ICustomerPlusListingReqBody = {
      siteUseId: authObj?.registeredFaciltyAddress?.siteUseId!,
      customerPlusFlag: true,
      FacilityAccountNumber: getFacilityAccountNumber(authObj),
    };
    const customerPlusServiceReponse = await getCustomerPlusListData(
      customerPlusListingReqBody
    );
    setLoader(false);
    if (customerPlusServiceReponse && customerPlusServiceReponse.succeeded) {
      setIsCustomerPlusDataLoaded(true);
      if (customerPlusServiceReponse.items === null) {
        setoriginalCustomerPlusData([]);
        setCustomerPlusListingData(initialCustomerListingData);
      } else {
        let availableUnitsArr: ICustomerPlusListingDetails[] = [];
        let unitsInUseArr: ICustomerPlusListingDetails[] = [];
        let unitsNeedingPreparationArr: ICustomerPlusListingDetails[] = [];
        customerPlusServiceReponse.items.forEach(
          (x: ICustomerPlusListingDetails) => {
            const customerPlusProductInfo = getUpdatedMenuActionsProductData(x);
            if (
              customerPlusProductInfo.status ===
              CustomerPlusListingTableTypes.AVAILABLE_UNITS
            ) {
              availableUnitsArr.push(customerPlusProductInfo);
            } else if (
              customerPlusProductInfo.status ===
              CustomerPlusListingTableTypes.UNITS_IN_USE
            ) {
              unitsInUseArr.push(customerPlusProductInfo);
            } else if (
              customerPlusProductInfo.status ===
              CustomerPlusListingTableTypes.UNITS_NEEDING_PREPARATION
            ) {
              unitsNeedingPreparationArr.push(customerPlusProductInfo);
            }
          }
        );
        setoriginalCustomerPlusData([
          ...availableUnitsArr,
          ...unitsInUseArr,
          ...unitsNeedingPreparationArr,
        ]);
        setCustomerPlusListingData({
          availableUnits: availableUnitsArr,
          unitsInUse: unitsInUseArr,
          unitsNeedingPreparation: unitsNeedingPreparationArr,
        });
      }
    } else {
      setErrorCode(
        customerPlusServiceReponse?.error?.errorCode ||
          customerPlusServiceReponse?.status
      );
      setErrorMessage(GET_INVENTORY_LIST_FAILED);
      setErrorPopUpFlag(true);
    }
  };

  const getInventoryList = async (
    programType: number,
    setTableData: Function
  ) => {
    if (authObj && authObj.registeredFaciltyAddress) {
      const customerNumber = authObj.registeredFaciltyAddress.accountNumber;
      const readyCareFlag =
        authObj.registeredFaciltyAddress.readyCareFlag?.toUpperCase() === "Y"
          ? true
          : false;
      const reqParams = {
        siteUseId: getSiteUseId(authObj),
        customerNumber: customerNumber,
        programType: programType,
        ...(programType === 2 && { isReadyCare: readyCareFlag }),
      };
      setLoader(true);
      const response = await getInventoryInfoList(reqParams);
      if (response && response.succeeded) {
        setLoader(false);
        let inventoryList = response.items;
        if (programType.toString() === "2" && inventoryList.length > 0) {
          inventoryList = inventoryList.map((x: IInventoryProductDetails) => {
            return {
              ...x,
              menuItems: x
                .menuItems!.map((menuItem) => {
                  let menuItemPermission = getAcuteMenuActionPermission(
                    menuItem.sequenceText,
                    permissionObj!.mappedRolesPermissionData
                  );
                  if (menuItemPermission) {
                    return menuItem;
                  } else return null;
                })
                .filter((x) => x !== null) as IInventoryMenuActionItem[],
            };
          });
        }
        if (inventoryList === null) {
          setTableData([]);
        } else {
          setTableData(inventoryList);
        }
      } else {
        setLoader(false);
        setIsInventoryAPIFailed(true);
        setErrorMessage(GET_INVENTORY_LIST_FAILED);
        setErrorCode(
          response?.error?.code ||
            response?.error?.errorCode ||
            response?.status
        );
        setErrorPopUpFlag(true);
      }
    } else {
      if (!authObj || (authObj && !authObj.registeredFaciltyAddress)) {
        setErrorCode(REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE);
      }
      setErrorMessage(GET_INVENTORY_LIST_FAILED);
      setErrorPopUpFlag(true);
    }
  };

  const handleChange = () => {};

  const closePopup = () => {
    setErrorPopUpFlag(false);
    setIsToShowErrorCode(true);
    setIsToShowPhoneNumber(true);
    if (isInventoryAPIFailed) {
      history.push("/home");
    }
  };

  const refreshVacReadyCareData = () => {
    getInventoryList(1, setReadyCareData);
  };

  const refreshStoredProductData = () => {
    getInventoryList(2, setStoredProductData);
  };

  const downloadBtnAction = async (
    reqsBody: ICSVReqBody,
    csvFileName: string
  ) => {
    setLoader(true);
    const response = await getCSVDownload(reqsBody);
    setLoader(false);
    if (response && response.succeeded) {
      const base64String = response.item;
      await getCSV(base64String, csvFileName);
    } else {
      setErrorPopUpFlag(true);
      setErrorMessage(ERROR_MSG_DOWNLOAD_INVENTORY_READY_CARE);
      setErrorCode(
        response?.error?.code || response?.error?.errorCode || response?.status
      );
    }
  };

  const manageStorageLocationsButtonAction = () => {
    history.push("/administration/myLists");
  };

  useEffect(() => {
    let tabVisibilityArrayBasedOnVisibility = [];
    if (
      permissionObj?.mappedRolesPermissionData?.IsShowInventoryStoredProductTab
    ) {
      tabVisibilityArrayBasedOnVisibility.push(
        InventoryContainerTabs.STORED_PRODUCT_LISTING
      );
    }
    if (
      permissionObj?.mappedRolesPermissionData?.IsShowInventoryCustomerPlusTab
    ) {
      tabVisibilityArrayBasedOnVisibility.push(
        InventoryContainerTabs.CUSTOMER_PLUS_LISTING
      );
    }
    if (permissionObj?.mappedRolesPermissionData?.IsShowInventoryReadyCareTab) {
      tabVisibilityArrayBasedOnVisibility.push(
        InventoryContainerTabs.VAC_READY_CARE_PROGRAM_LISTING
      );
    }
    setInventoryPermissionArray(tabVisibilityArrayBasedOnVisibility);
    if (isFromSideNav || isFromSideNav === null) {
      if (
        permissionObj?.mappedRolesPermissionData?.IsShowInventoryReadyCareTab
      ) {
        setSelectedTab(InventoryContainerTabs.VAC_READY_CARE_PROGRAM_LISTING);
        setScrollTabValue(
          tabVisibilityArrayBasedOnVisibility.indexOf(
            InventoryContainerTabs.VAC_READY_CARE_PROGRAM_LISTING
          )
        );
      } else {
        setSelectedTab(tabVisibilityArrayBasedOnVisibility[0]);
        setScrollTabValue(0);
      }
      setIsFromSideNav(false);
    }
  }, []);

  useEffect(() => {
    if (
      selectedTab === InventoryContainerTabs.VAC_READY_CARE_PROGRAM_LISTING &&
      readyCareData === null
    ) {
      getInventoryList(1, setReadyCareData);
    }
    if (
      selectedTab === InventoryContainerTabs.STORED_PRODUCT_LISTING &&
      storedProductData === null
    ) {
      getInventoryList(2, setStoredProductData);
    }
    if (
      selectedTab === InventoryContainerTabs.CUSTOMER_PLUS_LISTING &&
      !isCustomerPlusDataLoaded
    ) {
      getCustomerPlusListingData();
    }
  }, [selectedTab]);

  const handleSetErrorInformation = (
    errorInfo: IErrorPopUp,
    showErrorCode: boolean = true,
    showPhoneNumber: boolean = true
  ) => {
    setIsToShowErrorCode(showErrorCode);
    setIsToShowPhoneNumber(showPhoneNumber);
    setErrorCode(errorInfo.errorCode);
    setErrorMessage(errorInfo.errorMessage);
    setErrorPopUpFlag(errorInfo.errorFlag);
  };

  const sectionsToDisplay = () => {
    let page: ReactNode;
    switch (selectedTab) {
      case InventoryContainerTabs.STORED_PRODUCT_LISTING:
        if (
          permissionObj?.mappedRolesPermissionData
            .IsShowInventoryStoredProductTab
        ) {
          page = (
            <StoredProductInventory
              downloadBtnAction={downloadBtnAction}
              refreshStoredProductData={refreshStoredProductData}
              setReadyCareProductData={setReadyCareData}
              storedProductData={storedProductData ?? []}
              manageStorageLocationsButtonAction={
                manageStorageLocationsButtonAction
              }
            />
          );
        }
        break;
      case InventoryContainerTabs.VAC_READY_CARE_PROGRAM_LISTING:
        if (
          permissionObj?.mappedRolesPermissionData.IsShowInventoryReadyCareTab
        ) {
          page = (
            <VacReadyCareInventory
              downloadBtnAction={downloadBtnAction}
              readyCareProducts={readyCareData ?? []}
              refreshVacReadyCareData={refreshVacReadyCareData}
              setStoredProductData={setStoredProductData}
              manageStorageLocationsButtonAction={
                manageStorageLocationsButtonAction
              }
            />
          );
        }
        break;
      case InventoryContainerTabs.CUSTOMER_PLUS_LISTING:
        if (
          permissionObj?.mappedRolesPermissionData
            .IsShowInventoryCustomerPlusTab
        ) {
          page = (
            <CustomerPlusInventory
              downloadBtnAction={downloadBtnAction}
              customerPlusListingData={customerPlusListingData}
              originalCustomerPlusData={originalCustomerPlusData}
              getCustomerPlusListingData={getCustomerPlusListingData}
              handleSetErrorInformation={handleSetErrorInformation}
            />
          );
        }
    }
    return page;
  };

  return (
    <div className="inventory-main-container-div">
      <div className="inventory-sub-container-div">
        <Typography
          className="inventory-title"
          data-testid="inventory-title-test"
        >
          Inventory
        </Typography>
        <div className="inventory-scroll-table-header">
          <ScrollableTabHeader
            scrollTabValue={scrollTabValue}
            handleChange={handleChange}
            tabsIndicator="scroll-tabs-indicator"
            tabsRoot="scroll-tabs"
            variant="scrollable"
          >
            {permissionObj?.mappedRolesPermissionData
              .IsShowInventoryStoredProductTab && (
              <Tab
                classes={{
                  textColorPrimary: "inventory-scroll-tabs-textlabel",
                  selected: "inventory-scroll-tabs-textlabel-select",
                  root: "scroll-tabs-height",
                }}
                disableRipple={true}
                iconPosition="end"
                label="Stored Product Listing"
                data-testid="inventory-stored-product-tab"
                onClick={() => {
                  setOrderSelectedTab("stored-product-listing");
                }}
              />
            )}
            {permissionObj?.mappedRolesPermissionData
              .IsShowInventoryCustomerPlusTab && (
              <Tab
                classes={{
                  textColorPrimary: "inventory-scroll-tabs-textlabel",
                  selected: "inventory-scroll-tabs-textlabel-select",
                  root: "scroll-tabs-height",
                }}
                disableRipple={true}
                iconPosition="end"
                label="Customer Plus Listing"
                data-testid="inventory-customer-plus-listing-tab"
                onClick={() => {
                  setOrderSelectedTab("customer-plus-listing");
                }}
              />
            )}
            {permissionObj?.mappedRolesPermissionData
              .IsShowInventoryReadyCareTab && (
              <Tab
                classes={{
                  textColorPrimary: "inventory-scroll-tabs-textlabel",
                  selected: "inventory-scroll-tabs-textlabel-select",
                  root: "scroll-tabs-height",
                }}
                disableRipple={true}
                iconPosition="end"
                label="V.A.C.® Ready Care Program Listing"
                data-testid="inventory-ready-care-tab"
                onClick={() => {
                  setOrderSelectedTab("vac-ready-care-program-listing");
                }}
              />
            )}
          </ScrollableTabHeader>
        </div>
        <div className="inventory-section-to-display">
          {sectionsToDisplay()}
        </div>
      </div>
      <Popup
        closeHandler={() => setLoader(false)}
        hideCloseButton={true}
        openFlag={loader}
      >
        <div className="inventory-container-spinner">
          <LoadingSpinner />
        </div>
      </Popup>
      <ErrorPopup
        errorPopupFlag={errorPopupFlag}
        handleBackButton={closePopup}
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={true}
        errorCode={errorCode}
        isErrorCodeVisible={isToShowErrorCode}
        isSupportPhoneRequired={isToShowPhoneNumber}
      />
    </div>
  );
};
