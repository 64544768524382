import {
  Validation,
  ValidationStatus,
} from "../../../../core/interfaces/input.interface";
import { getDeepClone } from "../../../../util/ObjectFunctions";
import { Validator } from "../../../../util/order.validations";
import {
  getValidObj,
  updateErrorMessage,
} from "../../../../util/utilityFunctions";

export class OrderOverviewValidator {
  private _validator;
  constructor(defValidator = new Validator()) {
    this._validator = defValidator;
  }

  private noValidation(txt: string): Validation {
    return getValidObj();
  }

  private fieldToMethodMapping(
    field: string
  ): ((txt: string) => Validation) | undefined {
    const mapping = new Map<string, (txt: string) => Validation>([
      ["patientfirstname", this._validator.emptyCheck],
      ["documentType", this._validator.emptyCheck],
      // Patient Information
      ["firstName", this._validator.inPatientOrderNameValidation],
      ["lastName", this._validator.inPatientOrderNameValidation],
      ["dateOfBirth", this._validator.dobValidation],
      ["room", this._validator.roomValidation],
      ["patientId", this._validator.patientIdValidation],
    ]);
    const validator = mapping.get(field);
    return validator ? validator : this.noValidation;
  }

  public validate(input: string, field: string) {
    try {
      const validator = this.fieldToMethodMapping(field)!;
      return validator(input);
    } catch (error) {
      console.log(`validator method for field ${field} is not configured`);
    }
  }

  public validateAll(data: any, setData: Function) {
    let temp = getDeepClone(data);
    let smallestOrderWithInvalidStatus: any;
    Object.keys(temp).map((x) => {
      if (
        temp[x].isOptional === true &&
        temp[x].valid !== ValidationStatus.VALID
      ) {
        temp[x].valid = ValidationStatus.VALID;
      } else if (
        temp[x].required &&
        temp[x].valid === ValidationStatus.UNTOUCHED
      ) {
        temp[x].valid = ValidationStatus.INVALID;
      } else if (
        temp[x].isDefaultValid &&
        temp[x].valid !== ValidationStatus.VALID
      ) {
        temp[x].valid = ValidationStatus.INVALID;
      }
      if (temp[x].valid === ValidationStatus.INVALID && temp[x].order) {
        if (
          !smallestOrderWithInvalidStatus ||
          (smallestOrderWithInvalidStatus.order &&
            temp[x].order &&
            smallestOrderWithInvalidStatus.order > temp[x].order)
        ) {
          smallestOrderWithInvalidStatus = temp[x];
        }
      }
      updateErrorMessage(temp[x]);
    });
    setData(temp);
    const ifAllValid = Object.keys(temp)
      .filter((x) => data[x].required)
      .every((x: string) => data[x].valid === ValidationStatus.VALID);
    return ifAllValid;
  }
}
