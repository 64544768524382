import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { getDeepClone } from "../../../../../util/ObjectFunctions";
import { exposedStructuresData } from "../../../../newOrder/exposedStructures/exposedStructures.data";
import { IAddWoundAssessment } from "./addWoundAssessment.interface";
import { woundAssessComorboditiesData } from "./woundAssessComorbidities/woundAssessComorbodities.data";
import {
  mwptreatmentRegimenData,
  treatmentRegimenData,
} from "./woundAssessmentInfection/woundInfection.data";

export let defaultAddWoundAssessment: IAddWoundAssessment = {
  patientFirstName: {
    componentId: "addWound-patient-information-first-name",
    id: "addWound-patient-information-first-name-input",
    title: "Patient First Name",
    order: 1,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  patientLastName: {
    componentId: "addWound-patient-information-last-name",
    id: "addWound-patient-information-last-name-input",
    title: "Patient Last Name",
    order: 2,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  woundID: {
    componentId: "addWound-wound-assessment-id",
    id: "addWound-wound-assessment-id-input",
    title: "Wound ID",
    order: 3,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  rentalOrderNumber: {
    componentId: "addWound-wound-assessment-rental-order-number",
    id: "addWound-wound-assessment-rental-order-number-input",
    title: "Rental Order #",
    order: 4,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  dateOfBirth: {
    componentId: "addWound-patient-information-date-of-birth",
    id: "addWound-patient-information-date-of-birth-input",
    title: "Date of Birth",
    order: 5,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  productName: {
    componentId: "addWound-wound-assessment-product-name",
    id: "addWound-wound-assessment-product-name-input",
    title: "Product",
    order: 6,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  placementDate: {
    componentId: "addWound-wound-assessment-placement-date",
    id: "addWound-wound-assessment-placement-date-input",
    title: "Placement Date",
    order: 7,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  woundLocation: {
    componentId: "addWound-wound-assessment-location",
    id: "addWound-wound-assessment-location-input",
    title: "Wound Location",
    order: 8,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  woundType: {
    componentId: "addWound-wound-assessment-type",
    id: "addWound-wound-assessment-type-input",
    title: "Wound Type",
    order: 9,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  woundDirection: {
    componentId: "addWound-wound-assessment-direction",
    id: "addWound-wound-assessment-direction-input",
    title: "Wound Direction",
    order: 10,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  woundOrientation: {
    componentId: "addWound-wound-assessment-orientation",
    id: "addWound-wound-assessment-orientation-input",
    title: "Wound Orientation",
    order: 11,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  assessmentType: {
    componentId: "addWound-wound-assessment-type",
    id: "addWound-wound-assessment-type-input",
    title: "Assessment Type",
    order: 12,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  woundAssessmentDateTo: {
    componentId: "addWound-wound-assessment-date-to",
    id: "addWound-wound-assessment-date-to-input",
    title: "Wound Assessment Date To",
    order: 13,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  woundAssessmentDateFrom: {
    componentId: "addWound-wound-assessment-date-from",
    id: "addWound-wound-assessment-date-from-input",
    title: "Wound Assessment Date From",
    order: 14,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  previousEvaluationDate: {
    componentId: "addWound-wound-assessment-previous-evaluation-date",
    id: "addWound-wound-assessment-previous-evaluation-date-input",
    title: "Previous Evaluation Date",
    order: 15,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  previousWoundDepth: {
    componentId: "addWound-wound-assessment-previous-depth",
    id: "addWound-wound-assessment-previous-depth-input",
    title: "Previous Wound Depth",
    order: 16,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  previousWoundLength: {
    componentId: "addWound-wound-assessment-previous-length",
    id: "addWound-wound-assessment-previous-length-input",
    title: "Previous Wound Length",
    order: 17,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  previousWoundWidth: {
    componentId: "addWound-wound-assessment-previous-width",
    id: "addWound-wound-assessment-previous-width-input",
    title: "Previous Wound Width",
    order: 18,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  woundTherapyStatus: {
    componentId: "addWound-wound-assessment-therapy-status-radio-button-check",
    id: "addWound-wound-assessment-therapy-status-input",
    title: "Therapy Status",
    order: 19,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: true,
  },
  woundDiscontinuedDate: {
    componentId: "addWound-wound-assessment-discontinued-date-picker",
    id: "addWound-wound-assessment-discontinued-date-input",
    title: "Discontinued Date",
    order: 20,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundDiscontinuedReason: {
    componentId: "addWound-wound-assessment-discontinued-reason",
    id: "addWound-wound-assessment-discontinued-reason-input",
    title: "Wound Discontinued Reason",
    order: 21,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundMeasurementTaken: {
    componentId: "addWound-wound-assessment-measurement-taken",
    id: "addWound-wound-assessment-measurement-taken-input",
    title: "Wound Measurements",
    order: 22,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundMeasurementDate: {
    componentId: "addWound-wound-assessment-measurement-date-picker",
    id: "addWound-wound-assessment-measurement-date-input",
    title: "Wound Measurement Date",
    order: 23,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundMeasurementLenght: {
    componentId: "addWound-wound-assessment-measurement-length",
    id: "addWound-wound-assessment-measurement-length-input",
    title: "Length (cm)",
    order: 24,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundMeasurementDepth: {
    componentId: "addWound-wound-assessment-measurement-depth",
    id: "addWound-wound-assessment-measurement-depth-input",
    title: "Depth (cm)",
    order: 26,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundMeasurementWidth: {
    componentId: "addWound-wound-assessment-measurement-width",
    id: "addWound-wound-assessment-measurement-width-input",
    title: "Width (cm)",
    order: 25,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundAssessorStatus: {
    componentId: "addWound-wound-assessor-status-radio-button-check",
    id: "addWound-wound-assessor-status-input",
    title: "Did someone other than yourself perform this assessment?",
    order: 67,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundAssessorName: {
    componentId: "addWound-wound-assessor-name",
    id: "addWound-wound-assessor-name-input",
    title: "Name of Wound Assessor",
    order: 68,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundAssessorFacilityName: {
    componentId: "addWound-wound-assessor-facility-name",
    id: "addWound-wound-assessor-facility-name-input",
    title: "Facility Name",
    order: 70,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundAssessorPhoneNumber: {
    componentId: "addWound-wound-assessor-phone-number",
    id: "addWound-wound-assessor-phone-number-input",
    title: "Phone Number",
    order: 71,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundAssessorLicenseType: {
    componentId: "addWound-wound-assessor-license-type",
    id: "addWound-wound-assessor-license-type-input",
    title: "License Type / Job Role",
    order: 69,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  provideAdditionalWoundInfo: {
    componentId: "addWound-provide-additional-wound-info",
    id: "addWound-provide-additional-wound-info-input",
    title: "Additional Notes",
    order: 72,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundEscharStatus: {
    componentId: "addWound-wound-eschar-status-radio-button-check",
    id: "addWound-wound-eschar-status-input",
    title: "Eschar Tissue Present",
    order: 27,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundDebridementStatus: {
    componentId: "addWound-wound-debridement-status-radio-button-check",
    id: "addWound-wound-debridement-status-input",
    title: "Debridement",
    order: 59,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundDebridementDate: {
    componentId: "addWound-wound-debridement-date-picker",
    id: "addWound-wound-debridement-date-input",
    title: "Debridement Date",
    order: 60,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundDebridementType: {
    componentId: "addWound-wound-debridement-type",
    id: "addWound-wound-debridement-type-input",
    title: "Type of Debridement",
    order: 61,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundInfectionInLast30Days: {
    componentId: "addWound-wound-infection-in-last-30-days-radio-button-check",
    id: "addWound-wound-infection-in-last-30-days-input",
    title: "Infection present in the last 30 days",
    order: 62,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  selectedInfectionType: {
    componentId: "addWound-selected-infection-type-radio-button-check",
    id: "addWound-selected-infection-type-input",
    title: "Type of infection",
    order: 63,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  selectedInfectionTypeOther: {
    componentId: "addWound-selected-infection-type-other",
    id: "addWound-selected-infection-type-other-input",
    title: "Other",
    order: 64,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  treatmentRegimen: {
    componentId: "addWound-treatment-regimen-check-boxes",
    title: "Treatment Regimen / Antibiotic Name",
    order: 65,
    value: mwptreatmentRegimenData,
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  // here we have some more fields after clicking on checkbox
  vacTherapyBeenHold: {
    componentId: "addWound-vac-therapy-been-hold-radio-button-check",
    id: "addWound-vac-therapy-been-hold-input",
    title: "V.A.C.® Therapy placed on hold",
    order: 50,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  vacHoldStartDate: {
    componentId: "addWound-vac-hold-start-date-picker",
    id: "addWound-vac-hold-start-date-input",
    title: "Hold Start Date",
    order: 51,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  vacHoldReason: {
    componentId: "addWound-vac-hold-reason",
    id: "addWound-vac-hold-reason-input",
    title: "Reason for hold",
    order: 52,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  vacResumeStatus: {
    componentId: "addWound-vac-resume-status-radio-button-check",
    id: "addWound-vac-resume-status-input",
    title: "V.A.C.® Therapy resumed",
    order: 53,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  vacResumeDate: {
    componentId: "addWound-vac-resume-date-picker",
    id: "addWound-vac-resume-date-input",
    title: "Resumption Start Date",
    order: 54,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  resumptionMeasureStatus: {
    componentId: "addWound-resumption-measure-status-radio-button-check",
    id: "addWound-resumption-measure-status-input",
    title: "Measurements taken at resumption",
    order: 55,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  resumptionMeasureLenght: {
    componentId: "addWound-resumption-measure-length",
    id: "addWound-resumption-measure-length-input",
    title: "Length (cm)",
    order: 56,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  resumptionMeasureWidth: {
    componentId: "addWound-resumption-measure-width",
    id: "addWound-resumption-measure-width-input",
    title: "Width (cm)",
    order: 57,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  resumptionMeasureDepth: {
    componentId: "addWound-resumption-measure-depth",
    id: "addWound-resumption-measure-depth-input",
    title: "Depth (cm)",
    order: 58,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  exudateAmount: {
    componentId: "addWound-exudate-amount",
    id: "addWound-exudate-amount-input",
    title: "Exudate Amount",
    order: 33,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  exudateAppearance: {
    componentId: "addWound-exudate-appearance",
    id: "addWound-exudate-appearance-input",
    title: "Exudate Appearance",
    order: 34,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
  },
  // exposed structures
  exposedStructures: {
    componentId: "addWound-exposed-structures-check-boxes",
    title: "",
    order: 47,
    valid: ValidationStatus.UNTOUCHED,
    value: exposedStructuresData,
    required: false,
  },
  // Wound Undermining
  woundUndermining: {
    componentId: "addWound-wound-undermining-radio-button-check",
    id: "addWound-wound-undermining-input",
    title: "Wound Undermining",
    order: 35,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  underminingLocation1Depth: {
    componentId: "addWound-undermining-location1-depth",
    id: "addWound-undermining-location1-depth-input",
    title: "Location 1 Depth (cm)",
    order: 36,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  underminingLocation1PositionFrom: {
    componentId: "addWound-undermining-location1-position-from",
    id: "addWound-undermining-location1-position-from-input",
    title: "Starting Position",
    order: 37,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  underminingLocation1PositionTo: {
    componentId: "addWound-undermining-location1-position-to",
    id: "addWound-undermining-location1-position-to-input",
    title: "Ending Position",
    order: 38,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  underminingLocation2Depth: {
    componentId: "addWound-undermining-location2-depth",
    id: "addWound-undermining-location2-depth-input",
    title: "Location 2 Depth (cm)",
    order: 39,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  underminingLocation2PositionFrom: {
    componentId: "addWound-undermining-location2-position-from",
    id: "addWound-undermining-location2-position-from-input",
    title: "Starting Position",
    order: 40,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  underminingLocation2PositionTo: {
    componentId: "addWound-undermining-location2-position-to",
    id: "addWound-undermining-location2-position-to-input",
    title: "Ending Position",
    order: 41,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  // woundbed
  granulationValue: {
    componentId: "addWound-granulation-value",
    id: "addWound-granulation-value-input",
    title: "Beefy, bright red granulation tissue",
    order: 28,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  epthilizationValue: {
    componentId: "addWound-epithilization-value",
    id: "addWound-epithilization-value-input",
    title: "Dull pink/red, minimal or no granulation tissue",
    order: 29,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  sloughValue: {
    componentId: "addWound-slough-value",
    id: "addWound-slough-value-input",
    title: "White, gray, yellow or brown non-viable tissue",
    order: 30,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  escharValue: {
    componentId: "addWound-eschar-value",
    id: "addWound-eschar-value-input",
    title: "Black eschar, not covered by Medicare",
    order: 31,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  woundBedTotal: {
    componentId: "addWound-wound-bed-total",
    id: "addWound-wound-bed-total-input",
    title: "Percent of wound described",
    order: 32,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  // woundTunneling
  woundTunneling: {
    componentId: "addWound-wound-tunneling-radio-button-check",
    id: "addWound-wound-tunneling-input",
    title: "Wound Tunneling",
    order: 42,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  location1Depth: {
    componentId: "addWound-location1-depth",
    id: "addWound-location1-depth-input",
    title: "Location 1 Depth (cm)",
    order: 43,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  location1Position: {
    componentId: "addWound-location1-position",
    id: "addWound-location1-position-input",
    title: "Clock Position",
    order: 44,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  location2Depth: {
    componentId: "addWound-location2-depth",
    id: "addWound-location2-depth-input",
    title: "Location 2 Depth (cm)",
    order: 45,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  location2Position: {
    componentId: "addWound-location2-position",
    id: "addWound-location2-position-input",
    title: "Clock Position",
    order: 46,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  woundAssessComorbodities: {
    componentId: "addWound-wound-assess-comorbodities-check-boxes",
    title: "Comorbidities",
    order: 48,
    valid: ValidationStatus.UNTOUCHED,
    value: getDeepClone(woundAssessComorboditiesData),
    required: false,
  },
  isComorbititiesDiabeticProgram: {
    componentId: "addWound-is-comorbitities-diabetic-program-check-boxes",
    id: "addWound-is-comorbitities-diabetic-program-input",
    title: "",
    order: 49,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
};

export let defaultNoMWPAddWoundAssessment: IAddWoundAssessment = {
  patientFirstName: {
    componentId: "addWound-patient-information-first-name",
    id: "addWound-patient-information-first-name-input",
    title: "Patient First Name",
    order: 1,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  patientLastName: {
    componentId: "addWound-patient-information-last-name",
    id: "addWound-patient-information-last-name-input",
    title: "Patient Last Name",
    order: 2,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  woundID: {
    componentId: "addWound-wound-assessment-id",
    id: "addWound-wound-assessment-id-input",
    title: "Wound ID",
    order: 3,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  rentalOrderNumber: {
    componentId: "addWound-wound-assessment-rental-order-number",
    id: "addWound-wound-assessment-rental-order-number-input",
    title: "Rental Order #",
    order: 4,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  dateOfBirth: {
    componentId: "addWound-patient-information-date-of-birth",
    id: "addWound-patient-information-date-of-birth-input",
    title: "Date of Birth",
    order: 5,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  productName: {
    componentId: "addWound-wound-assessment-product-name",
    id: "addWound-wound-assessment-product-name-input",
    title: "Product",
    order: 6,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  placementDate: {
    componentId: "addWound-wound-assessment-placement-date",
    id: "addWound-wound-assessment-placement-date-input",
    title: "Placement Date",
    order: 7,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  woundLocation: {
    componentId: "addWound-wound-assessment-location",
    id: "addWound-wound-assessment-location-input",
    title: "Wound Location",
    order: 8,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  woundType: {
    componentId: "addWound-wound-assessment-type",
    id: "addWound-wound-assessment-type-input",
    title: "Wound Type",
    order: 9,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  woundDirection: {
    componentId: "addWound-wound-assessment-direction",
    id: "addWound-wound-assessment-direction-input",
    title: "Wound Direction",
    order: 10,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  woundOrientation: {
    componentId: "addWound-wound-assessment-orientation",
    id: "addWound-wound-assessment-orientation-input",
    title: "Wound Orientation",
    order: 11,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  assessmentType: {
    componentId: "addWound-wound-assessment-type",
    id: "addWound-wound-assessment-type-input",
    title: "Assessment Type",
    order: 12,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  woundAssessmentDateTo: {
    componentId: "addWound-wound-assessment-date-to",
    id: "addWound-wound-assessment-date-to-input",
    title: "Wound Assessment Date To",
    order: 13,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  woundAssessmentDateFrom: {
    componentId: "addWound-wound-assessment-date-from",
    id: "addWound-wound-assessment-date-from-input",
    title: "Wound Assessment Date From",
    order: 14,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  previousEvaluationDate: {
    componentId: "addWound-wound-assessment-previous-evaluation-date",
    id: "addWound-wound-assessment-previous-evaluation-date-input",
    title: "Previous Evaluation Date",
    order: 15,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  previousWoundDepth: {
    componentId: "addWound-wound-assessment-previous-depth",
    id: "addWound-wound-assessment-previous-depth-input",
    title: "Previous Wound Depth",
    order: 16,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  previousWoundLength: {
    componentId: "addWound-wound-assessment-previous-length",
    id: "addWound-wound-assessment-previous-length-input",
    title: "Previous Wound Length",
    order: 17,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  previousWoundWidth: {
    componentId: "addWound-wound-assessment-previous-width",
    id: "addWound-wound-assessment-previous-width-input",
    title: "Previous Wound Width",
    order: 18,
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  woundTherapyStatus: {
    componentId: "addWound-wound-assessment-therapy-status-radio-button-check",
    id: "addWound-wound-assessment-therapy-status-input",
    title: "Therapy Status",
    order: 19,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: true,
  },
  woundDiscontinuedDate: {
    componentId: "addWound-wound-assessment-discontinued-date-picker",
    id: "addWound-wound-assessment-discontinued-date-input",
    title: "Discontinued Date",
    order: 20,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundDiscontinuedReason: {
    componentId: "addWound-wound-assessment-discontinued-reason",
    id: "addWound-wound-assessment-discontinued-reason-input",
    title: "Wound Discontinued Reason",
    order: 21,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundMeasurementTaken: {
    componentId: "addWound-wound-assessment-measurement-taken",
    id: "addWound-wound-assessment-measurement-taken-input",
    title: "Wound Measurements",
    order: 38,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundMeasurementDate: {
    componentId: "addWound-wound-assessment-measurement-date-picker",
    id: "addWound-wound-assessment-measurement-date-input",
    title: "Wound Measurement Date",
    order: 39,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundMeasurementLenght: {
    componentId: "addWound-wound-assessment-measurement-length",
    id: "addWound-wound-assessment-measurement-length-input",
    title: "Length (cm)",
    order: 40,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundMeasurementDepth: {
    componentId: "addWound-wound-assessment-measurement-depth",
    id: "addWound-wound-assessment-measurement-depth-input",
    title: "Depth (cm)",
    order: 42,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundMeasurementWidth: {
    componentId: "addWound-wound-assessment-measurement-width",
    id: "addWound-wound-assessment-measurement-width-input",
    title: "Width (cm)",
    order: 41,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundAssessorStatus: {
    componentId: "addWound-wound-assessor-status-radio-button-check",
    id: "addWound-wound-assessor-status-input",
    title: "Did someone other than yourself perform this assessment?",
    order: 75,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundAssessorName: {
    componentId: "addWound-wound-assessor-name",
    id: "addWound-wound-assessor-name-input",
    title: "Name of Wound Assessor",
    order: 76,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundAssessorFacilityName: {
    componentId: "addWound-wound-assessor-facility-name",
    id: "addWound-wound-assessor-facility-name-input",
    title: "Facility Name",
    order: 78,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundAssessorPhoneNumber: {
    componentId: "addWound-wound-assessor-phone-number",
    id: "addWound-wound-assessor-phone-number-input",
    title: "Phone Number",
    order: 79,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundAssessorLicenseType: {
    componentId: "addWound-wound-assessor-license-type",
    id: "addWound-wound-assessor-license-type-input",
    title: "License Type / Job Role",
    order: 77,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  provideAdditionalWoundInfo: {
    componentId: "addWound-provide-additional-wound-info",
    id: "addWound-provide-additional-wound-info-input",
    title: "Additional Notes",
    order: 80,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundEscharStatus: {
    componentId: "addWound-wound-eschar-status-radio-button-check",
    id: "addWound-wound-eschar-status-input",
    title: "Eschar Tissue Present",
    order: 43,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundDebridementStatus: {
    componentId: "addWound-wound-debridement-status-radio-button-check",
    id: "addWound-wound-debridement-status-input",
    title: "Debridement",
    order: 22,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundDebridementDate: {
    componentId: "addWound-wound-debridement-date-picker",
    id: "addWound-wound-debridement-date-input",
    title: "Debridement Date",
    order: 23,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundDebridementType: {
    componentId: "addWound-wound-debridement-type",
    id: "addWound-wound-debridement-type-input",
    title: "Type of Debridement",
    order: 24,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  woundInfectionInLast30Days: {
    componentId: "addWound-wound-infection-in-last-30-days-radio-button-check",
    id: "addWound-wound-infection-in-last-30-days-input",
    title: "Infection present in the last 30 days",
    order: 25,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  selectedInfectionType: {
    componentId: "addWound-selected-infection-type-radio-button-check",
    id: "addWound-selected-infection-type-other-input",
    title: "Type of infection",
    order: 26,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  selectedInfectionTypeOther: {
    componentId: "addWound-selected-infection-type-other",
    id: "addWound-selected-infection-type-other-input",
    title: "Other",
    order: 27,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  treatmentRegimen: {
    componentId: "addWound-treatment-regimen-check-boxes",
    title: "Treatment Regimen / Antibiotic Name",
    order: 28,
    value: treatmentRegimenData,
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  // here we have some more fields after clicking on checkbox
  vacTherapyBeenHold: {
    componentId: "addWound-vac-therapy-been-hold-radio-button-check",
    id: "addWound-vac-therapy-been-hold-input",
    title: "V.A.C.® Therapy placed on hold",
    order: 66,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  vacHoldStartDate: {
    componentId: "addWound-vac-hold-start-date-picker",
    id: "addWound-vac-hold-start-date-input",
    title: "Hold Start Date",
    order: 67,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  vacHoldReason: {
    componentId: "addWound-vac-hold-reason",
    id: "addWound-vac-hold-reason-input",
    title: "Reason for hold",
    order: 68,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  vacResumeStatus: {
    componentId: "addWound-vac-resume-status-radio-button-check",
    id: "addWound-vac-resume-status-input",
    title: "V.A.C.® Therapy resumed",
    order: 69,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  vacResumeDate: {
    componentId: "addWound-vac-resume-date-picker",
    id: "addWound-vac-resume-date-input",
    title: "Resumption Start Date",
    order: 70,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  resumptionMeasureStatus: {
    componentId: "addWound-resumption-measure-status-radio-button-check",
    id: "addWound-resumption-measure-status-input",
    title: "Measurements taken at resumption",
    order: 71,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  resumptionMeasureLenght: {
    componentId: "addWound-resumption-measure-length",
    id: "addWound-resumption-measure-length-input",
    title: "Length (cm)",
    order: 72,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  resumptionMeasureWidth: {
    componentId: "addWound-resumption-measure-width",
    id: "addWound-resumption-measure-width-input",
    title: "Width (cm)",
    order: 73,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  resumptionMeasureDepth: {
    componentId: "addWound-resumption-measure-depth",
    id: "addWound-resumption-measure-depth-input",
    title: "Depth (cm)",
    order: 74,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  exudateAmount: {
    componentId: "addWound-exudate-amount",
    id: "addWound-exudate-amount-input",
    title: "Exudate Amount",
    order: 49,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  },
  exudateAppearance: {
    componentId: "addWound-exudate-appearance",
    id: "addWound-exudate-appearance-input",
    title: "Exudate Appearance",
    order: 50,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
  },
  // exposed structures
  exposedStructures: {
    componentId: "addWound-exposed-structures-check-boxes",
    title: "",
    order: 63,
    valid: ValidationStatus.UNTOUCHED,
    value: exposedStructuresData,
    required: false,
  },
  // Wound Undermining
  woundUndermining: {
    componentId: "addWound-wound-undermining-radio-button-check",
    id: "addWound-wound-undermining-input",
    title: "Wound Undermining",
    order: 51,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  underminingLocation1Depth: {
    componentId: "addWound-undermining-location1-depth",
    id: "addWound-undermining-location1-depth-input",
    title: "Location 1 Depth (cm)",
    order: 52,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  underminingLocation1PositionFrom: {
    componentId: "addWound-undermining-location1-position-from",
    id: "addWound-undermining-location1-position-from-input",
    title: "Starting Position",
    order: 53,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  underminingLocation1PositionTo: {
    componentId: "addWound-undermining-location1-position-to",
    id: "addWound-undermining-location1-position-to-input",
    title: "Ending Position",
    order: 54,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  underminingLocation2Depth: {
    componentId: "addWound-undermining-location2-depth",
    id: "addWound-undermining-location2-depth-input",
    title: "Location 2 Depth (cm)",
    order: 55,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  underminingLocation2PositionFrom: {
    componentId: "addWound-undermining-location2-position-from",
    id: "addWound-undermining-location2-position-from-input",
    title: "Starting Position",
    order: 56,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  underminingLocation2PositionTo: {
    componentId: "addWound-undermining-location2-position-to",
    id: "addWound-undermining-location2-position-to-input",
    title: "Ending Position",
    order: 57,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  // woundbed
  granulationValue: {
    componentId: "addWound-granulation-value",
    id: "addWound-granulation-value-input",
    title: "Beefy, bright red granulation tissue",
    order: 44,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  epthilizationValue: {
    componentId: "addWound-epithilization-value",
    id: "addWound-epithilization-value-input",
    title: "Dull pink/red, minimal or no granulation tissue",
    order: 45,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  sloughValue: {
    componentId: "addWound-slough-value",
    id: "addWound-slough-value-input",
    title: "White, gray, yellow or brown non-viable tissue",
    order: 46,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  escharValue: {
    componentId: "addWound-eschar-value",
    id: "addWound-eschar-value-input",
    title: "Black eschar, not covered by Medicare",
    order: 47,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  woundBedTotal: {
    componentId: "addWound-wound-bed-total",
    id: "addWound-wound-bed-total-input",
    title: "Percent of wound described",
    order: 48,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  // woundTunneling
  woundTunneling: {
    componentId: "addWound-wound-tunneling-radio-button-check",
    id: "addWound-wound-tunneling-input",
    title: "Wound Tunneling",
    order: 58,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  location1Depth: {
    componentId: "addWound-location1-depth",
    id: "addWound-location1-depth-input",
    title: "Location 1 Depth (cm)",
    order: 59,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  location1Position: {
    componentId: "addWound-location1-position",
    id: "addWound-location1-position-input",
    title: "Clock Position",
    order: 60,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  location2Depth: {
    componentId: "addWound-location2-depth",
    id: "addWound-location2-depth-input",
    title: "Location 2 Depth (cm)",
    order: 61,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  location2Position: {
    componentId: "addWound-location2-position",
    id: "addWound-location2-position-input",
    title: "Clock Position",
    order: 62,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  woundAssessComorbodities: {
    componentId: "addWound-wound-assess-comorbodities-check-boxes",
    title: "Comorbidities",
    order: 64,
    valid: ValidationStatus.UNTOUCHED,
    value: getDeepClone(woundAssessComorboditiesData),
    required: false,
  },
  isComorbititiesDiabeticProgram: {
    componentId: "addWound-is-comorbitities-diabetic-program-check-boxes",
    id: "addWound-is-comorbitities-diabetic-program-input",
    title: "",
    order: 65,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
};
