import React, { useContext, useRef, useState } from "react";
import { MemoedAgGridTable } from "../memoedAgGridTable/memoedAgGridTable.component";
import { Grid, useMediaQuery } from "@mui/material";
import { useCart } from "../../../../context/CartContext";
import { AgGridReact } from "ag-grid-react";
import {
  agGridGetRowStyle,
  convertStringToDate,
} from "../../../../util/utilityFunctions";
import ProductName from "../productName/productName.component";
import "./selectedSupplies.css";
import { CartState } from "../cart/cart.reducer";
interface ISelectedSupplies {
  isComingFromReview: boolean;
  cartItemsData: CartState;
  isFromInpatientOrderFlow?: boolean;
  isFromInPatientSupplyOrderDetails?: boolean;
  isSummaryOrDetail?: boolean;
}

const SelectedSupplies = ({
  isComingFromReview = false,
  cartItemsData,
  isFromInpatientOrderFlow = false,
  isFromInPatientSupplyOrderDetails = false,
  isSummaryOrDetail = false,
}: ISelectedSupplies) => {
  const isMobileScreen = useMediaQuery("(max-width:926px)");
  const isSmallMobile = useMediaQuery("(max-width: 600px)");
  const isIpadScreen = useMediaQuery("(max-width: 1100px)");
  const isDesktopBigScreen = useMediaQuery("(min-width: 1370px)");
  const gridRef = useRef<AgGridReact>(null);

  const getSupplyStatusText = (status: number): string => {
    switch (status) {
      case 0:
        return "Successful";
      case -1:
        return "Failed to Process";
      default:
        return "";
    }
  };
  const defaultColDef = {
    sortable: false,
    unSortIcon: false,
    suppressMenu: true,
    autoHeight: true,
  };
  const columnDefs: any[] = [
    {
      headerName: "Additional Supplies",
      field: "name",
      minWidth: isMobileScreen ? 170 : 150,
      width: isMobileScreen ? (isSmallMobile ? 170 : 230) : 350,
      sortable: false,
      suppressMovable: true,
      wrapText: true,
      flex: isSmallMobile ? 1 : 0,
      pinned: isMobileScreen ? "left" : null,
      resizable: true,
      cellRendererSelector: (params: any) => {
        return {
          component: ProductName,
          params: {
            productData: params.data,
            isSmallMobile: true,
          },
        };
      },
    },
    {
      headerName: "Price/Unit",
      field: "sku",
      minWidth: isMobileScreen ? 70 : 100,
      width: isMobileScreen ? 110 : 100,
      sortable: false,
      flex: isFromInpatientOrderFlow ?? 0,
      suppressMovable: true,
      resizable: true,
      cellRenderer: (params: any) => (
        <>$ {Number(params.data.price).toFixed(2)}</>
      ),
    },
    !isFromInpatientOrderFlow && {
      headerName: "Estimated Arrival Date",
      field: "packageQuantity",
      minWidth: isMobileScreen ? 170 : 150,
      width: isMobileScreen ? 110 : 180,
      sortable: true,
      suppressMovable: true,
      resizable: true,
      cellRenderer: (params: any) => (
        <>{convertStringToDate(params.data.estimatedArrivalDate)}</>
      ),
    },
    {
      headerName: "Qty",
      field: "packageType",
      minWidth: isMobileScreen ? 70 : 100,
      width: isMobileScreen ? 110 : 100,
      sortable: true,
      resizable: true,
      suppressMovable: true,
      cellRenderer: (params: any) => (
        <>{params.data.quantity !== "" ? params.data.quantity : "--"}</>
      ),
    },
    {
      headerName: "Status",
      field: "supplyStatus",
      minWidth: isMobileScreen ? 60 : 120,
      width: isMobileScreen ? (isSmallMobile ? 140 : 150) : 160,
      sortable: true,
      suppressMovable: true,
      resizable: true,
      hide: !isSummaryOrDetail,
      cellRenderer: (params: any) => (
        <>{getSupplyStatusText(params.data.supplyStatus)}</>
      ),
    },
    {
      headerName: "Price",
      field: "price",
      minWidth: isMobileScreen ? 60 : 120,
      width: isMobileScreen ? (isSmallMobile ? 75 : 100) : 157,
      sortable: true,
      suppressMovable: true,
      resizable: true,
      flex: isMobileScreen ? 0 : isFromInpatientOrderFlow ? 0 : 1,
      cellRenderer: (params: any) => (
        <>$ {(params.data.price * params.data.quantity).toFixed(2)}</>
      ),
    },
  ];

  const getTotalPrice = (totalPrice: number, shippingPrice: number) => {
    let totalPriceWithoutDecimal = totalPrice + shippingPrice;
    return totalPriceWithoutDecimal.toFixed(2);
  };
  return (
    <div
      className={
        !isComingFromReview
          ? "inpatient-supplyorder-selectedsupplies-main-div"
          : isFromInpatientOrderFlow
          ? "inpatient-order-selectedsupplies-main-review-div"
          : "inpatient-supplyorder-selectedsupplies-main-review-div"
      }
      data-testid="inpatient-supplyorder-selectedsupplies-main-div-testid"
    >
      {!isComingFromReview && (
        <div
          className="inpatient-supplyorder-selectedsupplies-header"
          data-testid="inpatient-supplyorder-selectedsupplies-header"
        >
          {isFromInPatientSupplyOrderDetails
            ? "Supplies Ordered"
            : "Selected Supplies"}
        </div>
      )}

      <div
        id="myGrid"
        className="ag-theme-quartz"
        data-testid="inpatient-supply-order-products-ag-grid"
        style={{
          width: "100%",
        }}
      >
        <AgGridReact
          rowData={cartItemsData.cartItems}
          columnDefs={columnDefs}
          getRowStyle={agGridGetRowStyle}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          suppressRowClickSelection={true}
          suppressDragLeaveHidesColumns={true}
          rowHeight={52}
          ref={gridRef}
        />
      </div>
      <div></div>
      <Grid container flexDirection={"row"} className="selectedSupplies-main">
        <Grid className="selectedSupplies-price-grid-main" xs={12}>
          <Grid
            item
            xs={isMobileScreen ? 12 : 7}
            className="selectedSupplies-price-grid-sub-note"
            data-testid="selectedSupplies-price-grid-sub-note-test"
          >
            <div> Note: The total shown is before tax has been applied. </div>
          </Grid>
          <div
            className="selectedSupplies-price-div"
            data-testid="selectedSupplies-price-grid-total-test"
          >
            <Grid
              item
              xs={
                isFromInpatientOrderFlow && isSmallMobile
                  ? 6
                  : isMobileScreen
                  ? 5
                  : isIpadScreen
                  ? 5
                  : 3
              }
              className="selectedSupplies-price-grid-sub-totalpricelabel"
            >
              Total Product Price
            </Grid>
            <Grid
              item
              xs={isFromInpatientOrderFlow && isSmallMobile ? 5 : 3}
              className="selectedSupplies-price-grid-sub-totalprice"
            >
              $ {cartItemsData.totalCartPrice.toFixed(2)}
            </Grid>
          </div>
        </Grid>
        {!isFromInpatientOrderFlow && (
          <Grid className="selectedSupplies-price-grid-main" xs={12}>
            <Grid item xs={6}></Grid>
            <div
              className="selectedSupplies-price-div"
              data-testid="selectedSupplies-price-grid-shipping-test"
            >
              <Grid
                item
                xs={isMobileScreen ? 5 : isIpadScreen ? 5 : 3}
                className="selectedSupplies-price-grid-sub-totalpricelabel"
              >
                Shipping Total
              </Grid>
              <Grid
                item
                xs={3}
                className="selectedSupplies-price-grid-sub-totalprice"
              >
                ${" "}
                {cartItemsData && cartItemsData.shippingTotal
                  ? cartItemsData.shippingTotal.toFixed(2)
                  : "0.00"}
              </Grid>
            </div>
          </Grid>
        )}

        <Grid className="selectedSupplies-price-grid-main" xs={12}>
          <Grid item xs={6}></Grid>
          <div
            className="selectedSupplies-price-div"
            data-testid="selectedSupplies-price-grid-ordertotal-test"
          >
            <Grid
              item
              xs={
                isFromInpatientOrderFlow && isSmallMobile
                  ? 5
                  : isMobileScreen
                  ? 5
                  : isIpadScreen
                  ? 5
                  : 3
              }
              className="selectedSupplies-price-grid-final-totalprice"
            >
              Order Total
            </Grid>
            <Grid
              item
              xs={isFromInpatientOrderFlow && isSmallMobile ? 5 : 3}
              className="selectedSupplies-price-grid-final-totalprice"
            >
              ${" "}
              {getTotalPrice(
                cartItemsData.totalCartPrice,
                cartItemsData.shippingTotal!
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectedSupplies;
