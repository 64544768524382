import { createContext, useState } from "react";
import { SalesRoundingToolPageSection } from "../sso/salesRoundingTool/salesRoundingTool.enum";
import { ITerritoryFacility } from "../sso/salesRoundingTool/salesRoundingTool.interface";

export type ISalesRoundingContextType = {
  pageSection: SalesRoundingToolPageSection;
  setPageSection: React.Dispatch<
    React.SetStateAction<SalesRoundingToolPageSection>
  >;
  selectedFacility: ITerritoryFacility | null;
  setSelectedFacility: React.Dispatch<
    React.SetStateAction<ITerritoryFacility | null>
  >;
  resetContext: Function;
};

type SalesRoundingToolContextProviderProps = {
  children: React.ReactNode;
};

export const SaleRoundingToolContext =
  createContext<ISalesRoundingContextType | null>(null);

export const SaleRoundingToolContextProvider = ({
  children,
}: SalesRoundingToolContextProviderProps) => {
  const [pageSection, setPageSection] = useState<SalesRoundingToolPageSection>(
    SalesRoundingToolPageSection.FACILITY_SELECTION
  );
  const [selectedFacility, setSelectedFacility] =
    useState<ITerritoryFacility | null>(null);
  const resetContext = () => {
    setPageSection(SalesRoundingToolPageSection.FACILITY_SELECTION);
    setSelectedFacility(null);
  };
  return (
    <SaleRoundingToolContext.Provider
      value={{
        pageSection,
        setPageSection,
        selectedFacility,
        setSelectedFacility,
        resetContext,
      }}
    >
      {children}
    </SaleRoundingToolContext.Provider>
  );
};
