import React, { useEffect, useState, useContext } from "react";
import "./territoryDetailFacilityListTable.css";
import SearchIcon from "@mui/icons-material/Search";
import { InputBase } from "@mui/material";
import { CustomDropDown } from "../../../../../../core/customDropdown/customDropdown.component";
import { format } from "react-string-format";
import { getdropDownContent } from "../../../../../../util/dropDownService";
import {
  DD_CARE_SETTING_CONTENT,
  DD_CLASSIFICATION_CONTENT,
} from "../../../../../../util/staticText";
import { Popup } from "../../../../../../core/popup/popup.component";
import { LoadingSpinner } from "../../../../../../core/loader/LoadingSpinner";
import {
  getFacilitySearchResultByTerritory,
  selectFacility,
} from "../../../../../../util/userService";
import {
  AuthContext,
  AuthContextType,
} from "../../../../../../context/AuthContext";
import {
  getCodeFromText,
  getTextFromCode,
  makeCapitalEachOfWordInString,
  makeCapitalEachWordInString,
  showVacOrderMenuOption,
  useSortableTable,
} from "../../../../../../util/utilityFunctions";
import Table from "../../../../../../core/customSortingTable/table.component";
import { defaultSalesRoleTestData } from "../../../../../../sso/sales/salesRole.test.data";
import { ISalesRole } from "../../../../../../sso/sales/salesRole.interface";
import { ITerritoryDetailList } from "../../territoryListTable/territoryListTable.interface";
import { ExpressButton } from "../../../../../../core/expressButton/expressButton.component";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import { useHistory, useLocation } from "react-router-dom";
import { getCareGiverId } from "../../../../../../util/3meService";
import { mapUserRolesAndPermissionData } from "../../../../../../RolesPermission/RolesPermission.Mapper";
import { ISideNav } from "../../../../../sideNav/sideNavMenuOption.interface";
import { searchFacility } from "../../../../../manageProfile/facilityInformation/addFacility/addFacility.service";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../../../../context/RolesPermissionContext";
import { defaultErrorObj } from "../../../../../myPatients/allAlerts/missingRx/missingRx.component";
import ErrorPopup, {
  IErrorPopUp,
} from "../../../../../../core/errorPopup/errorPopup.component";
import { SVC_GET_TERRITORY_FACILITY_ERROR } from "../../../../../../util/errorMsg";

interface Props {
  selectedTerritory: ITerritoryDetailList;
}

export const TerritoryDetailFacilityListTable = ({
  selectedTerritory,
}: Props) => {
  const [openLoaderPopUp, setOpenLoaderPopUp] = useState<boolean>(true);
  const [searchInput, setSearchInput] = useState<string>("");
  const [selectedClassificationType, setSelectedClassificationType] =
    useState<string>("");
  const [classification, setClassification] = useState([]);
  const [classificationText, setClassificationText] = useState([]);
  const [careSetting, setCareSetting] = useState([]);
  const [careSettingText, setCareSettingText] = useState([]);
  const AuthObj = useContext<AuthContextType | null>(AuthContext);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState<any>(25);
  const [currentPosts, setCurrentPost] = useState<any>();
  const [numberOfPages, setNumberOfPages] = useState<any[]>([]);
  const [currentButton, setCurrentButton] = useState(1);
  const [arrOfCurrButtons, setArrOfCurrButtons] = useState<any[]>([]);
  const [totalLength, setTotalLenght] = useState<any[]>([]);
  const [data, setData] = useState([]);
  const [error, setErorr] = useState<IErrorPopUp>(defaultErrorObj);
  const [salesRolesData, setSalesRolesData] = useState(
    defaultSalesRoleTestData
  );
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const [currentPath, setCurrentPath] = useState<string | null>(null);
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== currentPath) {
      setCurrentPath(location.pathname);
    }
  }, []);
  const columns = [
    { label: "", accessor: "select", sortable: false },
    {
      label: "Facility Site Name",
      accessor: "accountName",
      sortable: true,
    },
    { label: "Facility No.", accessor: "accountNumber", sortable: true },
    {
      label: "Classification",
      accessor: "typeName",
      sortable: true,
    },
    { label: "Setting", accessor: "careSetting", sortable: true },
  ];
  const [sortedData, setSortedData, handleSorting] = useSortableTable(
    [],
    columns
  );

  const getFecilityAndDropDown = async () => {
    await Promise.all([fetchDropDownContent(), getFacilityListByTerritory()]);
    setOpenLoaderPopUp(false);
  };

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format(
        "{0},{1}",
        DD_CLASSIFICATION_CONTENT,
        DD_CARE_SETTING_CONTENT ?? ""
      );
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const classificationObject = data.items.filter(
          (item: { name: string }) => item.name === DD_CLASSIFICATION_CONTENT
        );
        const classificationData = classificationObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setClassification(classificationData);
        setClassificationText(
          classificationData.map((x: { text: string }) => x.text)
        );
        const careSettingObject = data.items.filter(
          (item: { name: string }) => item.name === DD_CARE_SETTING_CONTENT
        );
        const careSettingData = careSettingObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setCareSetting(careSettingData);
        setCareSettingText(
          careSettingData.map((x: { text: string }) => x.text)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getFacilityListByTerritory = async (code?: string) => {
    const reqBody = {
      userName: AuthObj?.userProfile?.userName,
      territoryCode: selectedTerritory.territoryCode,
    };
    setSortedData([]);
    const searchResult = await getFacilitySearchResultByTerritory(reqBody);
    if (searchResult && searchResult.succeeded) {
      setData(searchResult.data.result);
      setSortedData(searchResult.data.result);
    } else {
      const apiFailure = {
        errorCode:
          searchResult?.error?.errorCode ||
          searchResult?.error?.code ||
          searchResult?.status,
        errorFlag: true,
        errorMessage: SVC_GET_TERRITORY_FACILITY_ERROR,
      };
      setErorr(apiFailure);
    }
  };

  const handleFacilitySearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const re = /^[a-zA-Z0-9-]+$/;
    const { value } = e.target;
    if (value === "" || re.test(value)) {
      setSearchInput(e.target.value);
    }
  };

  const validateAndSetData = (e: any) => {
    let { value } = e.target;
    setSalesRolesData((salesRolesData: ISalesRole) => ({
      ...salesRolesData,
      [e.target.name]: {
        value: value,
        valid: true,
        required: true,
      },
    }));
  };

  useEffect(() => {
    const indexOfLastPost = currentButton * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    if (sortedData.slice(indexOfFirstPost, indexOfLastPost).length === 0) {
      setCurrentButton(1);
      const indexOfLastPost = currentPage2 * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const temp = sortedData.slice(indexOfFirstPost, indexOfLastPost);
      setCurrentPost(temp);
      setTotalLenght(temp ? temp : 0);
    } else if (
      sortedData.slice(indexOfFirstPost, indexOfLastPost).length !== 0
    ) {
      const temp = sortedData.slice(indexOfFirstPost, indexOfLastPost);
      setCurrentPost(temp);
      setTotalLenght(temp ? temp : 0);
    }
    const howManyPages = Math.ceil(sortedData.length / postsPerPage);
    const numberOfPages: any[] = [];
    for (let i = 1; i <= howManyPages; i++) {
      numberOfPages.push(i);
    }
    setNumberOfPages(numberOfPages);
    var tempNumberOfPages: any[] = [];
    tempNumberOfPages = [...arrOfCurrButtons];
    let dotsInitial: any = "...";
    let dotsRight: any = " ...";
    if (numberOfPages.length < 5) {
      tempNumberOfPages = numberOfPages;
    } else if (currentButton >= 1 && currentButton < 5) {
      tempNumberOfPages = [1, 2, 3, 4, 5, dotsInitial, numberOfPages.length];
    } else if (
      currentButton >= 5 &&
      currentButton <= Number(numberOfPages.length)
    ) {
      const sliced1 = numberOfPages.slice(currentButton - 1, currentButton);
      const sliced2 = numberOfPages.slice(currentButton, currentButton + 4);
      tempNumberOfPages = [
        ...sliced1,
        ...sliced2,
        dotsRight,
        numberOfPages.length,
      ];
    } else if (
      currentButton >= Number(numberOfPages.length) - 6 &&
      currentButton < Number(numberOfPages.length) - 1
    ) {
      const sliced1 = numberOfPages.slice(currentButton - 1, currentButton);
      const sliced2 = numberOfPages.slice(currentButton, currentButton + 3);
      tempNumberOfPages = [
        ...sliced1,
        ...sliced2,
        dotsRight,
        numberOfPages.length,
      ];
    } else if (currentButton === dotsInitial) {
      setCurrentButton(arrOfCurrButtons[arrOfCurrButtons.length - 3] + 1);
    } else if (currentButton === dotsRight) {
      setCurrentButton(arrOfCurrButtons[arrOfCurrButtons.length - 3] + 1);
    }
    setArrOfCurrButtons(tempNumberOfPages);
    setCurrentPage2(currentButton);
  }, [
    currentButton,
    postsPerPage,
    JSON.stringify(arrOfCurrButtons),
    JSON.stringify(sortedData),
  ]);

  const handlePrevClick = (prev: any) => {
    setCurrentButton((prev) => (prev <= 1 ? prev : prev - 1));
  };
  const handleNextClick = (prev: any) => {
    setCurrentButton((prev) =>
      prev >= numberOfPages.length ? prev : prev + 1
    );
  };

  const handleSelectChange = (e: any) => {
    e.preventDefault();
    setPostsPerPage(e.target.value);
  };

  const filterTerritoryFacilityList = (
    searchInputParam: string,
    facilityClassificationParam: string,
    careSettingParam: string
  ) => {
    let filterData = data;
    if (data && data.length > 0) {
      if (
        facilityClassificationParam !== "All classifications" &&
        facilityClassificationParam !== ""
      ) {
        filterData = filterData.filter((x: any) =>
          x.typeCode.includes(
            getCodeFromText(classification, facilityClassificationParam)
          )
        );
      }
      if (careSettingParam !== "All care settings" && careSettingParam !== "") {
        filterData = filterData.filter((x: any) =>
          x.careSetting.toLowerCase().includes(careSettingParam.toLowerCase())
        );
      }
      if (searchInputParam.length > 0) {
        filterData = filterData.filter(
          (facility: any) =>
            facility.accountName
              .toLowerCase()
              .includes(searchInputParam.toLowerCase()) ||
            facility.accountNumber.includes(searchInputParam)
        );
      }
      setSortedData(filterData);
    }
  };

  useEffect(() => {
    filterTerritoryFacilityList(
      searchInput,
      salesRolesData.FacilityClassification.value,
      salesRolesData.CareSetting.value
    );
  }, [
    searchInput,
    salesRolesData.FacilityClassification.value,
    salesRolesData.CareSetting.value,
  ]);

  const handleSelectClick = async (e: any, isFavourite: boolean) => {
    const reqBody = {
      UserName: AuthObj?.userProfile?.userName,
      SiteUseId: e.target.id,
    };
    if (!openLoaderPopUp) {
      setOpenLoaderPopUp(true);
    }
    const userPermissions = await selectFacility(reqBody);
    const data = sortedData.filter((x: any) => x.siteUseId === e.target.id)[0];
    AuthObj?.setuserRolePermissionData(userPermissions);
    await searchFacilityAddr(data, userPermissions, isFavourite);
    setOpenLoaderPopUp(false);
  };
  const searchFacilityAddr = async (
    data: any,
    userPermissions: any,
    isFavourite: boolean
  ) => {
    let tempSideNavMenuOption: ISideNav;
    let readyCareFlag = "N";

    var facilitySearchRequest = {
      customerNumber: data.accountNumber,
      customerName: "",
      postalCode: "",
    };
    const searchedFacilities = await searchFacility(facilitySearchRequest);
    if (searchedFacilities) {
      const facilityRes = searchedFacilities.filter((x) => {
        if (x.siteUseId === data.siteUseId.toString()) {
          return x;
        }
      })[0];

      readyCareFlag = facilityRes.readyCareFlag!;
      facilityRes.isFavourite = isFavourite;
      // Set CareGiverId
      const caregiverId = await getCareGiverId(
        facilityRes.accountNumber?.toString(),
        facilityRes.typeCode
      );
      facilityRes.careGiverId = caregiverId;
      AuthObj?.setregisteredFaciltyAddress(facilityRes);

      const rolesPermissionRes = await mapUserRolesAndPermissionData(
        userPermissions,
        readyCareFlag
      );

      permissionObj?.setMappedRolesPermissionData(rolesPermissionRes);
      let adminText: any = "Administration";
      if (
        (AuthObj?.unLinkedFacilitesCount && rolesPermissionRes.Is3MAdminRole) ||
        (AuthObj?.unLinkedFacilitesCount &&
          rolesPermissionRes.IsProdManagerRole) ||
        (AuthObj?.unLinkedFacilitesCount && rolesPermissionRes.IsSupportRole)
      ) {
        adminText = (
          <div className="sideNavmadminBtnMain">
            Administration
            <span className="sideNavmadminBtnStyle">
              {AuthObj?.unLinkedFacilitesCount}{" "}
            </span>{" "}
          </div>
        );
      }

      tempSideNavMenuOption = {
        ...AuthObj?.sideNavMenuOptionModelData!,
        orders: {
          labelText: "Start New Order",
          isVisible: showVacOrderMenuOption(rolesPermissionRes),
        },
        inventory: {
          labelText: "Inventory",
          isVisible: rolesPermissionRes.IsShowInventoryOption,
        },
        administration: {
          labelText: adminText,
          isVisible: rolesPermissionRes.IsShowAdminstrationOption,
        },
      };
      if (currentPath === "/ssoRedirect") {
        localStorage.setItem("isComingFromSSO", "false");
      }
      AuthObj?.setuserRolesAndPermissionLoaded(true);
      AuthObj?.setIsInternalUserFacilitySelected(true);
      AuthObj?.setUpdateFacilityBannerFacility(true);
      history.replace("/home");
    }

    AuthObj?.setsideNavMenuOptionModelData(tempSideNavMenuOption!);
  };

  useEffect(() => {
    setOpenLoaderPopUp(true);
    getFecilityAndDropDown();
  }, []);

  return (
    <>
      <div className="territoryDetail-filter-mng-usr">
        <div className="territoryDetail-user-searchbar">
          <div className="territoryDetail-search-icon-div">
            <SearchIcon className="territoryDetail-search-icon" />
          </div>
          <InputBase
            className="territoryDetail-user-search-input"
            data-testid="territoryDetail-user-search-input"
            name="search-input"
            onChange={handleFacilitySearch}
            placeholder="Filter by Facility Name or Number"
            value={searchInput}
          />
        </div>
        <CustomDropDown
          handleChange={validateAndSetData}
          menuItem={classificationText}
          name="FacilityClassification"
          placeHolder="All classifications"
          selectpropsClassName={
            selectedClassificationType
              ? "territoryDetail-user-status-select"
              : "placeHolder"
          }
          selectClassName={
            selectedClassificationType
              ? "territoryDetail-user-status-input"
              : "placeHolder"
          }
          testId="territoryDetail-classifications"
          value={
            salesRolesData.FacilityClassification.value
              ? getTextFromCode(
                  classification,
                  salesRolesData.FacilityClassification.value
                )
              : null
          }
        />
        <CustomDropDown
          handleChange={validateAndSetData}
          menuItem={careSettingText}
          name="CareSetting"
          placeHolder="All care settings"
          selectpropsClassName={
            selectedClassificationType
              ? "territoryDetail-user-status-select"
              : "placeHolder"
          }
          selectClassName={
            selectedClassificationType
              ? "territoryDetail-user-status-input"
              : "placeHolder"
          }
          testId="territoryDetail-classifications"
          value={
            salesRolesData.CareSetting.value
              ? getTextFromCode(careSetting, salesRolesData.CareSetting.value)
              : null
          }
        />
      </div>
      <div
        className="territoryDetail_tables_container"
        data-testid="tr-search-facility-result-test"
      >
        <Table
          tableClassName="territory-tables"
          tableColumns={columns}
          handleSorting={handleSorting}
        >
          {columns && currentPosts && currentPosts.length > 0 ? (
            <tbody>
              {currentPosts.map((data: any) => {
                return (
                  <tr key={data.id}>
                    <td
                      className="territory-select-result-facility"
                      id={data.siteUseId}
                      data-testid="tr-selectLinktest"
                      onClick={(e) => {
                        handleSelectClick(e, data.isFavourite);
                      }}
                    >
                      View patients
                    </td>
                    <td
                      className="territory-search-result-tables-static-data"
                      data-testid="selectFacilityAccountNametest"
                    >
                      {makeCapitalEachWordInString(data.accountName)}
                      <br />
                      {data.address1
                        ? makeCapitalEachWordInString(data.address1) + ","
                        : ""}{" "}
                      {data.address2
                        ? makeCapitalEachWordInString(data.address2) + ","
                        : ""}{" "}
                      {makeCapitalEachWordInString(data.city)},{" "}
                      {makeCapitalEachOfWordInString(data.state)},{" "}
                      {makeCapitalEachWordInString(data.zip)}
                    </td>
                    <td
                      className="territory-search-result-tables-static-data"
                      data-testid="selectFacilityAccountNumbertest"
                    >
                      {makeCapitalEachWordInString(`${data.accountNumber}`)}
                    </td>
                    <td
                      className="territory-search-result-tables-static-data"
                      data-testid="selectFacilityTypeNametest"
                    >
                      {makeCapitalEachWordInString(`${data.typeName}`)}
                    </td>
                    <td
                      className="territory-search-result-tables-static-data"
                      data-testid="selectFacilityCareSettingtest"
                    >
                      {makeCapitalEachWordInString(`${data.careSetting}`)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody></tbody>
          )}
        </Table>
      </div>
      <div className="territory-parentDiv">
        <div
          className="paginationTable"
          data-testid="tr-paginationTablePresent"
        >
          <ExpressButton
            clickHandler={(e) => handlePrevClick(e)}
            disabled={currentButton === 1 ? true : false}
            variant="text"
            parentClass={`${currentButton === 1 ? "disabled" : ""}`}
          >
            <IoMdArrowDropleft size={20} />
          </ExpressButton>

          {arrOfCurrButtons.map((item, index) => {
            return (
              <li
                key={index}
                className={`${currentButton === item ? "active" : ""} list`}
                onClick={() => setCurrentButton(item)}
              >
                {item}
              </li>
            );
          })}

          <ExpressButton
            clickHandler={handleNextClick}
            disabled={currentButton === numberOfPages.length ? true : false}
            parentClass={`${
              currentButton === numberOfPages.length ? "disabled" : ""
            } prevbutton`}
            variant="text"
            id="buttonClass"
          >
            <IoMdArrowDropright size={20} />
          </ExpressButton>
        </div>
        <div className="itemsPerPage" data-testid="tr-itemsPerPage">
          <p className="ptag">Items per page:</p>
          <CustomDropDown
            value={`${postsPerPage}`}
            handleChange={handleSelectChange}
            menuItem={["25", "50", "75", "100"]}
            selectClassName="dropdown"
            selectpropsClassName="dropDown"
          />
        </div>
        <div className="totalCounts" data-testid="totalCounts">
          <p className="last-section-right">{`1-${totalLength.length} of ${sortedData.length} items`}</p>
        </div>
      </div>

      <Popup
        openFlag={openLoaderPopUp}
        closeHandler={() => setOpenLoaderPopUp(false)}
        dialogParentClass={"territory-fetch-users-loader-pop-up"}
        data-testid="territory-detail-pop-up"
        hideCloseButton={true}
      >
        <div className="territory-fetch-users-loader">
          <LoadingSpinner />
        </div>
      </Popup>
      <ErrorPopup
        buttontitle={"Done"}
        errorCode={error.errorCode}
        errorPopupFlag={error.errorFlag}
        errorMessage={error.errorMessage}
        handleBackButton={() => history.goBack()}
        popUpStyles="errorPopup"
        showTitle={false}
      />
    </>
  );
};
