import "./supplyOrderList.css";
import { TextField, useMediaQuery } from "@mui/material";
import {
  MyPatientContext,
  MyPatientContextType,
} from "../../../context/MyPatientContext";
import { getFaciityPatients } from "../../../util/3meService";
import { Validator } from "../../../util/order.validations";
import { Popup } from "../../../core/popup/popup.component";
import React, { useState, useEffect, useContext } from "react";
import {
  sendAnalyticsData,
  useSortableTable,
  IAnalyticsData,
  getSiteUseId,
  getCareGiverId,
  getFacilityAccountNumber,
} from "../../../util/utilityFunctions";
import SupplyOrderListTable from "./supplyOrderListTable.component";
import { ReactComponent as SearchIconSvg } from "../../../assets/blackSearchIcon.svg";
import { AddPatientButton } from "../../myPatients/addPatientButton/addPatientButton.component";
import { AddPatientContext } from "../../myPatients/addPatientContainer/addPatientContainer.context";
import { AddPatientContainer } from "../../myPatients/addPatientContainer/addPatientContainer.component";
import { SupplyOrderContextType } from "../../../context/SupplyOrderContext";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { IPatient } from "../../myPatients/patient.interface";
import {
  USER_PROFILE_OBJ_FROM_AUTH_CONTEXT_FAILURE,
  REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE,
  SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE,
} from "../../../util/errorCode";

const columns = [
  { label: "", accessor: "orderID", sortable: false },
  { label: "Name", accessor: "lastName", sortable: true },
  { label: "Date of Birth", accessor: "dob", sortable: true },
  { label: "RO #", accessor: "roNumber", sortable: true },
];

type Props = { supplyOrderContextObj: SupplyOrderContextType | null };

export const SupplyOrderList = ({ supplyOrderContextObj }: Props) => {
  supplyOrderContextObj?.setSupplyOrderPageTitle("Home Patient Supply Order");
  supplyOrderContextObj?.setSupplyOrderProgress(10);
  const [patientSearchKeyWords, setPatientSearchKeyWords] =
    useState<string>("");
  const [sortedData, setSortedData, handleSorting] = useSortableTable(
    [],
    columns
  );
  const [error, setError] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const MyPatientObj = useContext<MyPatientContextType | null>(
    MyPatientContext
  );
  const [open, setOpen] = useState(false);
  const [openSupplyOrderTable, setOpenSupplyOrderTable] = useState(true);
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const [patientList, setPatientList] = React.useState<Array<IPatient>>();

  const isStandardMobileScreen = useMediaQuery("(max-width:926px)");

  const handleAddPatient = () => {
    let data: IAnalyticsData = {
      page_type: "react",
      view_name: "supplyOrderComponent",
      event_type: "click",
      event_name: "addPatient_SupplyOrder",
      tealium_event: "Order_Supplies_Button",
      mmmex_userrecordid: authObj?.userProfile?.userID!,
      mmmex_facilityid: authObj?.registeredFaciltyAddress?.siteUseId!,
      mmmex_pagename: "Supply Order Select Patient",
      mmmex_roleid: permissionObj?.mappedRolesPermissionData?.roleName!,
    };
    sendAnalyticsData(data);
    setOpen(true);
  };

  const handleSearchPatient = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    let searchDataLocal = patientSearchKeyWords;
    const re = /^[a-zA-Z0-9-]+$/;
    let { value } = e.target;
    if (value === "" || re.test(value)) {
      searchDataLocal = value;
    }
    setPatientSearchKeyWords(searchDataLocal);
    if (searchDataLocal.length >= 3) doLocalSearchForPatient(searchDataLocal);
    else if (
      searchDataLocal.length === 0 &&
      patientSearchKeyWords !== searchDataLocal
    )
      doLocalSearchForPatient("");
  };

  const doLocalSearchForPatient = (searchParam: string) => {
    if (searchParam.length > 0 && patientList) {
      const filtedPatient = patientList.filter(
        (patient: IPatient) =>
          patient.lastName.toLowerCase().includes(searchParam.toLowerCase()) ||
          patient.firstName.toLowerCase().includes(searchParam.toLowerCase()) ||
          patient.roNumber.toString().includes(searchParam)
      );
      setSortedData(filtedPatient);
    } else {
      setSortedData(patientList);
    }
  };

  const loadPatientData = async () => {
    setOpenSupplyOrderTable(true);
    if (
      authObj &&
      authObj.userProfile &&
      authObj.userProfile.userName &&
      authObj.userProfile.userName !== "" &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.siteUseId !== ""
    ) {
      const response = await getFaciityPatients(
        getSiteUseId(authObj),
        getCareGiverId(authObj),
        authObj.registeredFaciltyAddress.careGiverSiteUseId,
        authObj.userProfile.userName,
        true,
        getFacilityAccountNumber(authObj),
        true,
        false
      );
      setOpenSupplyOrderTable(false);
      if (response && response.succeeded) {
        const data = response.data ?? [];
        if (data.length >= 0) {
          setPatientList(data);
          setSortedData(data);
        }
      } else {
        setError(true);
        setErrorPopUp(true);
        setErrorCode(
          response?.error?.code ||
            response?.error?.errorCode ||
            response?.status
        );
      }
    } else {
      if (!authObj || (authObj && !authObj.userProfile)) {
        setErrorCode(USER_PROFILE_OBJ_FROM_AUTH_CONTEXT_FAILURE);
      } else if (!authObj || (authObj && !authObj.registeredFaciltyAddress)) {
        setErrorCode(REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE);
      } else if (
        !authObj ||
        (authObj && !authObj.registeredFaciltyAddress?.siteUseId) ||
        (authObj && authObj.registeredFaciltyAddress?.siteUseId === "")
      ) {
        setErrorCode(SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE);
      }
      setError(true);
      setErrorPopUp(true);
    }
  };

  useEffect(() => {
    loadPatientData();
  }, []);

  return (
    <>
      <div className="supplyOrder-container">
        <div className="supplyOrder-List">
          <div className="select-patient" data-testid="select-patient">
            Select Patient
          </div>
          <div style={{ display: "flex" }}>
            <TextField
              autoComplete="off"
              autoFocus={!isStandardMobileScreen}
              data-testid="filter-patients"
              className="filter-so-list"
              inputProps={{ style: { fontSize: 14 } }}
              placeholder="Filter by Name or RO#"
              InputProps={{
                startAdornment: (
                  <SearchIconSvg style={{ marginRight: "5px" }} />
                ),
              }}
              value={patientSearchKeyWords}
              onChange={handleSearchPatient}
              variant="outlined"
            />
            <AddPatientButton
              isBtnVisible={true}
              onClickHandler={handleAddPatient}
              isBtnDisabled={
                permissionObj?.mappedRolesPermissionData.IsSupportRole
              }
            />
          </div>
          {sortedData && (
            <SupplyOrderListTable
              columns={columns}
              error={error}
              errorPopUp={errorPopUp}
              setErrorPopUp={setErrorPopUp}
              errorCode={errorCode}
              handleSorting={handleSorting}
              sortedData={sortedData}
              spinnerPatientList={openSupplyOrderTable}
            />
          )}
        </div>
      </div>
      <AddPatientContext.Provider
        value={{
          closePopup: () => setOpen(false),
          patientSearchValidator: new Validator(),
          addPatientToList: () => {
            loadPatientData();
          },
        }}
      >
        <Popup
          dialogParentClass="add-patient-popup"
          openFlag={open}
          closeHandler={() => setOpen(false)}
        >
          <AddPatientContainer />
        </Popup>
      </AddPatientContext.Provider>
    </>
  );
};
