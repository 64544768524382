import { IAttestationAndSign } from "../../../../../../core/attestationAndSignature/attestationAndSign.interface";
import { ValidationStatus } from "../../../../../../core/interfaces/input.interface";

export let woundAssessmentAttest: IAttestationAndSign = {
  firstNameLastName: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    componentId: "woundAssessment-firstNameLastName-input",
    id: "woundAssessment-firstNameLastName-id",
    title: "First & Last Name",
    order: 1,
  },
  employer: {
    valid: ValidationStatus.VALID,
    value: "",
    required: false,
    componentId: "woundAssessment-employer-input",
    id: "woundAssessment-employer-id",
    title: "Employer",
    order: 2,
  },
  phoneNumber: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    componentId: "woundAssessment-phoneNumber-input",
    id: "woundAssessment-phoneNumber-id",
    title: "Phone Number",
    order: 3,
  },
  confirmationData: {
    valid: ValidationStatus.VALID,
    value: "",
    required: false,
    componentId: "woundAssessment-confirmationData-input",
    id: "woundAssessment-confirmationData-id",
    title: "Confirmation Data",
    order: 4,
  },
  _3MRepresentativeName: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    componentId: "woundAssessment-3MRepresentativeName-input",
    id: "woundAssessment-3MRepresentativeName-id",
    title: "3M Representative Name",
    order: 5,
  },
  attestationDate: {
    valid: ValidationStatus.VALID,
    value: "",
    required: false,
    componentId: "woundAssessment-attestationDate-input",
    id: "woundAssessment-attestationDate-id",
    title: "Attestation Date",
    order: 6,
  },
};
