export interface ISalesRoundingPatient {
  color: string;
  firstName: string;
  lastName: string;
  dob: string;
  roNumber: string;
  placementType: any;
  dischargeStatus: any;
  reasonNotTransitioningHome: any;
  transitionTherapyStartDate: string;
  lastUpdatedDate: string;
  productName: string;
  leadGeneration: any;
  attendingPhysician: any;
  prescribingPhysician: any;
  patientId: any;
  patientLocation: string;
  serviceCovering: any;
  primaryWoundType: any;
  primaryWoundLocation: any;
  productSerialNumber: any;
  status: string;
  type: string;
  isSaleRoundingCompleted: Boolean;
}

export const salesRoundingCSVHeaders: string[] = [
  "RO Number",
  "First Name",
  "Last Name",
  "DOB",
  "Location",
  "Patient ID",
  "Status",
  "Product Name",
  "Serial Number",
  "Type",
  "Color",
  "Placement Type",
  "Discharge Status",
  "Reason Not Transitioning Home",
  "Transition Therapy Start Date",
  "Last Updated Date",
  "Lead Generation",
  "Attending Physician",
  "Prescribing Physician",
  "Service Covering",
  "Primary Wound Type",
  "Primary Wound Location",
  "Is Sales Rounding Completed",
];
