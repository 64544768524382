import React, { useState } from "react";
import { Snackbar, IconButton, SnackbarContent } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CircleSuccess from "../../assets/success.svg";
import "./successSnackBar.css";

type Props = {
  showSnackbar: boolean;
  handleCloseAlert: () => void;
  alertMessage: string;
};
const SuccessSnackbar = ({
  showSnackbar,
  handleCloseAlert,
  alertMessage,
}: Props) => {
  return (
    <Snackbar
      className="success-snack-bar"
      open={showSnackbar}
      autoHideDuration={3000}
      onClose={handleCloseAlert}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <SnackbarContent
        className="success-snack-bar-content"
        message={
          <div
            className="snackbar-content-message"
            data-testid="snackbar-content-message"
            id="snackbar-content-message"
          >
            <img
              className="snackbar-success-check-icon"
              src={CircleSuccess}
              alt={CircleSuccess}
            />
            {alertMessage}
          </div>
        }
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseAlert}
          >
            <CloseIcon />
          </IconButton>
        }
      />
    </Snackbar>
  );
};

export default SuccessSnackbar;
