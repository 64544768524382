import React from "react";
import "../woundAssessmentUndermining.css";
import { Box, Grid, InputBase, useMediaQuery } from "@mui/material";
import { IAddWoundAssessment } from "../../addWoundAssessment.interface";
import { formatedWoundValue } from "../../../../../../../util/utilityFunctions";
import { AddWoundAssessmentValidator } from "../../addWoundAssessment.validator";
import { ValidationStatus } from "../../../../../../../core/interfaces/input.interface";
import { woundAssessmentUnderminingProps } from "../woundAssessmentUndermining.interface";
import { CustomDropDown } from "../../../../../../../core/customDropdown/customDropdown.component";
import { InputWithLabel } from "../../../../../../../core/inputWithLabel/inputWithLabel.component";

const AssessmentUnderminingDetails = ({
  data,
  setData,
  positionDropDownData,
  Validator = new AddWoundAssessmentValidator(),
}: woundAssessmentUnderminingProps) => {
  const [validator] = React.useState<AddWoundAssessmentValidator>(Validator!);
  const isMobileScreen = useMediaQuery("(max-width:700px)");
  const isSmalledMobileScreen = useMediaQuery("(max-width:375px)");
  const formatWoundZeros = async (e: any) => {
    let { value, name, required } = e.target;
    let key: keyof typeof data = name;
    let formatedValue = formatedWoundValue(data[key].value);
    setData(
      Object.assign({}, data, {
        [name]: {
          ...Object(data)[name],
          value: formatedValue,
          valid:
            value.length === 0 && data[key].required
              ? ValidationStatus.INVALID
              : ValidationStatus.VALID,
          required: required,
        },
      })
    );
    validateDepthValueNotZero(formatedValue, key);
  };

  const validateAndSetData = (e: any) => {
    let { value, name, required } = e.target;
    let isValid = validator.validate(value, name);
    let key: keyof typeof data = name;
    setData(
      Object.assign({}, data, {
        [name]: {
          ...Object(data)[name],
          errorMessage: null,
          value: value,
          valid: isValid?.status,
          required: required,
        },
      })
    );
    if (
      name === "underminingLocation1PositionFrom" ||
      name === "underminingLocation2PositionFrom"
    ) {
      validateClockPositionNotSame(value, key);
    } else {
      validateClockEndingPositionNotSame(value, key);
    }
  };

  const validateAndSetDepthData = (e: any) => {
    let { value, name, required } = e.target;
    let key: keyof typeof data = name;
    let isValid = validator.validate(value, name);
    if (isValid?.status === ValidationStatus.VALID) {
      setData(
        Object.assign({}, data, {
          [name]: {
            ...Object(data)[name],
            errorMessage: null,
            value: value,
            valid:
              value.length === 0 && data[key].required
                ? ValidationStatus.INVALID
                : isValid?.status,
            required: required,
          },
        })
      );
    }
  };

  const validateClockPositionNotSame = (
    value: string,
    name: keyof typeof data
  ) => {
    if (
      value ===
        data[
          name === "underminingLocation1PositionFrom"
            ? "underminingLocation2PositionFrom"
            : "underminingLocation1PositionFrom"
        ].value &&
      value !== ""
    ) {
      setDefaultClockPositionValue(ValidationStatus.INVALID, value, name);
    } else if (
      value ===
        data[
          name === "underminingLocation1PositionFrom"
            ? "underminingLocation1PositionTo"
            : "underminingLocation2PositionTo"
        ].value &&
      value !== ""
    ) {
      if (name === "underminingLocation1PositionFrom") {
        setDefaultFromClockPosition1FromToValue(
          ValidationStatus.INVALID,
          value,
          name
        );
      } else {
        setDefaultFromClockPosition2FromToValue(
          ValidationStatus.INVALID,
          value,
          name
        );
      }
    } else {
      if (data[name].value) {
        setDefaultClockPositionValue(ValidationStatus.VALID, value, name);
      }
      if (
        value !==
          data[
            name === "underminingLocation1PositionFrom"
              ? "underminingLocation1PositionTo"
              : "underminingLocation2PositionTo"
          ].value &&
        value !== "" &&
        data[
          name === "underminingLocation1PositionFrom"
            ? "underminingLocation1PositionTo"
            : "underminingLocation2PositionTo"
        ].value !== ""
      ) {
        if (name === "underminingLocation1PositionFrom") {
          setDefaultFromClockPosition1FromToValue(
            ValidationStatus.VALID,
            value,
            name
          );
        } else {
          setDefaultFromClockPosition2FromToValue(
            ValidationStatus.VALID,
            value,
            name
          );
        }
      }
    }
  };

  const validateClockEndingPositionNotSame = (
    value: string,
    name: keyof typeof data
  ) => {
    if (
      value ===
        data[
          name === "underminingLocation1PositionTo"
            ? "underminingLocation2PositionTo"
            : "underminingLocation1PositionTo"
        ].value &&
      value !== ""
    ) {
      setDefaultClockEndingPositionValue(ValidationStatus.INVALID, value, name);
    } else if (
      value ===
        data[
          name === "underminingLocation1PositionTo"
            ? "underminingLocation1PositionFrom"
            : "underminingLocation2PositionFrom"
        ].value &&
      value !== ""
    ) {
      if (name === "underminingLocation1PositionTo") {
        setDefaultFromClockPosition1FromToValue(
          ValidationStatus.INVALID,
          value,
          name
        );
      } else {
        setDefaultFromClockPosition2FromToValue(
          ValidationStatus.INVALID,
          value,
          name
        );
      }
    } else {
      if (data[name].value) {
        setDefaultClockEndingPositionValue(ValidationStatus.VALID, value, name);
      }
      if (
        value !==
          data[
            name === "underminingLocation1PositionTo"
              ? "underminingLocation2PositionTo"
              : "underminingLocation1PositionTo"
          ].value &&
        data[
          name === "underminingLocation1PositionTo"
            ? "underminingLocation2PositionTo"
            : "underminingLocation1PositionTo"
        ].value !== ""
      ) {
        if (name === "underminingLocation1PositionTo") {
          setDefaultFromClockPosition1FromToValue(
            ValidationStatus.VALID,
            value,
            name
          );
        } else {
          setDefaultFromClockPosition2FromToValue(
            ValidationStatus.VALID,
            value,
            name
          );
        }
      }

      if (
        value !==
          data[
            name === "underminingLocation1PositionTo"
              ? "underminingLocation2PositionFrom"
              : "underminingLocation1PositionFrom"
          ].value &&
        data[
          name === "underminingLocation1PositionTo"
            ? "underminingLocation2PositionFrom"
            : "underminingLocation1PositionFrom"
        ].value === ""
      ) {
        if (name === "underminingLocation1PositionTo") {
          setDefaultFromClockPosition1FromToValue(
            ValidationStatus.VALID,
            value,
            name
          );
        }
      }
    }
  };

  const setDefaultFromClockPosition1FromToValue = (
    ValidationStatus: ValidationStatus,
    value: string,
    name: keyof typeof data
  ) => {
    data.underminingLocation1PositionFrom.valid = ValidationStatus;
    data.underminingLocation1PositionTo.valid = ValidationStatus;
    data[name].value = value;
    setData(Object.assign({}, data));
  };

  const setDefaultFromClockPosition2FromToValue = (
    ValidationStatus: ValidationStatus,
    value: string,
    name: keyof typeof data
  ) => {
    data.underminingLocation2PositionFrom.valid = ValidationStatus;
    data.underminingLocation2PositionTo.valid = ValidationStatus;
    data[name].value = value;
    setData(Object.assign({}, data));
  };

  const setDefaultClockPositionValue = (
    ValidationStatus: ValidationStatus,
    value: string,
    name: keyof typeof data
  ) => {
    if (name) data.underminingLocation1PositionFrom.valid = ValidationStatus;
    data.underminingLocation2PositionFrom.valid = ValidationStatus;
    data[name].value = value;
    setData(Object.assign({}, data));
  };

  const setDefaultClockEndingPositionValue = (
    ValidationStatus: ValidationStatus,
    value: string,
    name: keyof typeof data
  ) => {
    data.underminingLocation1PositionTo.valid = ValidationStatus;
    data.underminingLocation2PositionTo.valid = ValidationStatus;
    data[name].value = value;
    setData(Object.assign({}, data));
  };

  const validateDepthValueNotZero = (
    value: string,
    key: keyof IAddWoundAssessment
  ) => {
    let convertValue = parseFloat(value);
    if (convertValue === 0) {
      data[key].value = value;
      data[key].valid = ValidationStatus.INVALID;
      setData(Object.assign({}, data));
    }
  };

  return (
    <div>
      <Box className="undermining-box-container" sx={{ flexGrow: 1 }}>
        <Grid className="undermining-grid-container" container spacing={2}>
          <Grid
            className="undermining-grid-item-u"
            item
            xs={isSmalledMobileScreen ? 8 : isMobileScreen ? 9 : 3.2}
          >
            <InputWithLabel
              label={"Location 1 Depth (cm)"}
              isRequired={data?.underminingLocation1Depth.required}
              error={
                data?.underminingLocation1Depth.valid ===
                ValidationStatus.INVALID
              }
              id={data?.underminingLocation1Depth?.componentId}
              isShowWarning={true}
              warningMessage={data?.underminingLocation1Depth?.errorMessage}
            >
              <InputBase
                className="depth-input"
                name="underminingLocation1Depth"
                value={data?.underminingLocation1Depth.value}
                onChange={validateAndSetDepthData}
                onBlur={formatWoundZeros}
                required={data?.underminingLocation1Depth.required}
                data-testid="underminingLocation1Depth"
                type="text"
                inputProps={{
                  inputMode: "decimal",
                  pattern: "^[0-9.]+$",
                }}
                id={data?.underminingLocation1Depth?.id!}
              />
            </InputWithLabel>
          </Grid>
          <Grid
            className="undermining-grid-item-u"
            item
            xs={isSmalledMobileScreen ? 2 : isMobileScreen ? 2 : 0.8}
          >
            <div className="text-div" data-testid="undermining-fromtest">
              <p className="text">from</p>
            </div>
          </Grid>
          <Grid
            className="undermining-grid-item-u"
            item
            xs={isSmalledMobileScreen ? 4 : isMobileScreen ? 3.5 : 2.2}
          >
            <InputWithLabel
              label={"Starting Position"}
              isRequired={data?.underminingLocation1PositionFrom.required}
              error={
                data?.underminingLocation1PositionFrom.valid ===
                ValidationStatus.INVALID
              }
              testId="test-location1Position"
              isDropdown={true}
              id={data?.underminingLocation1PositionFrom?.componentId}
              isShowWarning={true}
              warningMessage={
                data?.underminingLocation1PositionFrom?.errorMessage
              }
            >
              <CustomDropDown
                handleChange={validateAndSetData}
                menuItem={positionDropDownData}
                name="underminingLocation1PositionFrom"
                placeHolder="00:00"
                selectpropsClassName={
                  data.underminingLocation1PositionFrom.value
                    ? "clockPosition-select"
                    : "placeHolder"
                }
                selectClassName={
                  data.underminingLocation1PositionFrom.value
                    ? "clockPosition-input"
                    : "placeHolder"
                }
                testId="test-location1Position-dropdown"
                value={data?.underminingLocation1PositionFrom.value}
              />
            </InputWithLabel>
          </Grid>
          <Grid
            className="undermining-grid-item-u"
            item
            xs={isSmalledMobileScreen ? 7 : isMobileScreen ? 2 : 0.5}
          >
            <div className="text-div" data-testid="undermining-totest-1">
              <p className="text">to</p>
            </div>
          </Grid>

          <Grid
            className="undermining-grid-item-u"
            item
            xs={isSmalledMobileScreen ? 4 : isMobileScreen ? 3.5 : 2.2}
          >
            <InputWithLabel
              label={"Ending Position"}
              isRequired={data?.underminingLocation1PositionTo.required}
              error={
                data?.underminingLocation1PositionTo.valid ===
                ValidationStatus.INVALID
              }
              testId="test-location1Position"
              isDropdown={true}
              id={data?.underminingLocation1PositionTo?.componentId}
              isShowWarning={true}
              warningMessage={
                data?.underminingLocation1PositionTo?.errorMessage
              }
            >
              <CustomDropDown
                handleChange={validateAndSetData}
                menuItem={positionDropDownData}
                name="underminingLocation1PositionTo"
                placeHolder="00:00"
                selectpropsClassName={
                  data.underminingLocation1PositionTo.value
                    ? "clockPosition-select"
                    : "placeHolder"
                }
                selectClassName={
                  data.underminingLocation1PositionTo.value
                    ? "clockPosition-input"
                    : "placeHolder"
                }
                testId="test-underminingLocation1PositionTo-dropdown"
                value={data?.underminingLocation1PositionTo.value}
              />
            </InputWithLabel>
          </Grid>
          <Grid
            className="undermining-grid-item-u"
            item
            xs={isSmalledMobileScreen ? 7 : isMobileScreen ? 2 : 0.5}
          >
            <div className="text-div" data-testid="underminingoclock-1">
              <p className="text">o’clock</p>
            </div>
          </Grid>
        </Grid>
      </Box>
      <Box className="undermining-box-container" sx={{ flexGrow: 1 }}>
        <Grid className="undermining-grid-container" container spacing={2}>
          <Grid
            className="undermining-grid-item-u"
            item
            xs={isSmalledMobileScreen ? 8 : isMobileScreen ? 9 : 3.2}
          >
            <InputWithLabel
              label="Location 2 Depth (cm)"
              isRequired={data?.underminingLocation2Depth.required}
              error={
                data?.underminingLocation2Depth.valid ===
                ValidationStatus.INVALID
              }
              id={data?.underminingLocation2Depth?.componentId!}
              isShowWarning={true}
              warningMessage={data?.underminingLocation2Depth?.errorMessage}
            >
              <InputBase
                className="depth-input"
                name="underminingLocation2Depth"
                value={data?.underminingLocation2Depth.value}
                onChange={validateAndSetDepthData}
                onBlur={formatWoundZeros}
                required={data?.underminingLocation2Depth.required}
                data-testid="underminingLocation2Depth"
                type="text"
                inputProps={{
                  inputMode: "decimal",
                  pattern: "^[0-9.]+$",
                }}
                id={data?.underminingLocation2Depth?.id!}
              />
            </InputWithLabel>
          </Grid>
          <Grid
            className="undermining-grid-item-u"
            item
            xs={isSmalledMobileScreen ? 2 : isMobileScreen ? 2 : 0.8}
          >
            <div className="text-div" data-testid="undermining-fromtest-2">
              <p className="text">from</p>
            </div>
          </Grid>
          <Grid
            className="undermining-grid-item-u"
            item
            xs={isSmalledMobileScreen ? 4 : isMobileScreen ? 3.5 : 2.2}
          >
            <InputWithLabel
              label="Starting Position"
              isRequired={data?.underminingLocation2PositionFrom.required}
              error={
                data?.underminingLocation2PositionFrom.valid ===
                ValidationStatus.INVALID
              }
              testId="test-underminingLocation2PositionFrom"
              isDropdown={true}
              id={data?.underminingLocation2PositionFrom?.componentId!}
              isShowWarning={true}
              warningMessage={
                data?.underminingLocation2PositionFrom?.errorMessage
              }
            >
              <CustomDropDown
                handleChange={validateAndSetData}
                menuItem={positionDropDownData}
                name="underminingLocation2PositionFrom"
                placeHolder="00:00"
                selectpropsClassName={
                  data.underminingLocation2PositionFrom.value
                    ? "clockPosition-select"
                    : "placeHolder"
                }
                selectClassName={
                  data.underminingLocation2PositionFrom.value
                    ? "clockPosition-input"
                    : "placeHolder"
                }
                testId="test-location2Position-dropdown"
                value={data?.underminingLocation2PositionFrom.value}
              />
            </InputWithLabel>
          </Grid>
          <Grid
            className="undermining-grid-item-u"
            item
            xs={isSmalledMobileScreen ? 7 : isMobileScreen ? 2 : 0.5}
          >
            <div className="text-div" data-testid="undermining-totest-2">
              <p className="text">to</p>
            </div>
          </Grid>

          <Grid
            className="undermining-grid-item-u"
            item
            xs={isSmalledMobileScreen ? 4 : isMobileScreen ? 3.5 : 2.2}
          >
            <InputWithLabel
              label="Ending Position"
              isRequired={data?.underminingLocation2PositionTo.required}
              error={
                data?.underminingLocation2PositionTo.valid ===
                ValidationStatus.INVALID
              }
              testId="test-underminingLocation2PositionTo"
              isDropdown={true}
              id={data?.underminingLocation2PositionTo?.componentId!}
              isShowWarning={true}
              warningMessage={
                data?.underminingLocation2PositionTo?.errorMessage
              }
            >
              <CustomDropDown
                handleChange={validateAndSetData}
                menuItem={positionDropDownData}
                name="underminingLocation2PositionTo"
                placeHolder="00:00"
                selectpropsClassName={
                  data.underminingLocation2PositionTo.value
                    ? "clockPosition-select"
                    : "placeHolder"
                }
                selectClassName={
                  data.underminingLocation2PositionTo.value
                    ? "clockPosition-input"
                    : "placeHolder"
                }
                testId="test-underminingLocation2PositionTo-dropdown"
                value={data?.underminingLocation2PositionTo.value}
              />
            </InputWithLabel>
          </Grid>
          <Grid
            className="undermining-grid-item-u"
            item
            xs={isSmalledMobileScreen ? 7 : isMobileScreen ? 2 : 0.5}
          >
            <div className="text-div" data-testid="underminingoclock-2">
              <p className="text">o’clock</p>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default AssessmentUnderminingDetails;
