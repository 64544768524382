import { ICartItem } from "./cartItem/cartItem.interface";
import {
  DeliveryDetail,
  EstimatedDeliveryDetail,
  IInPatientSupplyOrderProduct,
} from "../../inpatientSupplyOrder.interface";
import { MAX_AVAILABLE_CART_QUANTITY } from "../../../../util/staticText";

// CartState will hold an array of cart items
export interface CartState {
  cartItems: ICartItem[];
  invalidQuantity: boolean;
  totalCartPrice: number;
  totalQuantity: number;
  shippingTotal?: number;
}

// Define possible cart actions
export type CartAction =
  | {
      type: "ADD_PRODUCT";
      product: ICartItem;
      isProductNotAvailable?: boolean;
    }
  | { type: "DELETE"; sku: string }
  | { type: "RESET_CART" }
  | { type: "UPDATE_QUANTITY"; sku: string; quantity: number }
  | {
      type: "UPDATE_ESTIMATEDATE";
      inPatientDeliveryDetails: any;
      shippingTotal: number;
      shippingMethod: string;
    }
  | {
      type: "UPDATE_SUPPLY_STATUS";
      returnCodes: number[];
    };

// Reducer function to manage the cart state
export const cartReducer = (
  state: CartState,
  action: CartAction
): CartState => {
  let cartItems = state.cartItems;
  let invalidQuantity = state.invalidQuantity;
  let totalCartPrice = state.totalCartPrice;
  let totalQuantity = state.totalQuantity;
  let shippingTotal = state.shippingTotal;
  let updatedInvalidQuantity = false;
  switch (action.type) {
    case "ADD_PRODUCT":
      const maxQuantity = action.isProductNotAvailable
        ? 0
        : MAX_AVAILABLE_CART_QUANTITY;
      const productTotalPrice = action.product.price; // Initial total price is equal to the unit price
      cartItems = [
        ...cartItems,
        {
          ...action.product,
          quantity: 1,
          totalPrice: productTotalPrice,
          maxAvailableQuantity: maxQuantity,
        },
      ];
      totalQuantity += 1;
      totalCartPrice += action.product.price;
      break;

    case "DELETE":
      let isItemRequired = true;
      cartItems = state.cartItems.filter((item) => {
        if (item.sku === action.sku) {
          totalQuantity -= item.quantity;
          totalCartPrice -= item.totalPrice;
          isItemRequired = false;
        } else {
          isItemRequired = true;
        }
        if (
          updatedInvalidQuantity === false &&
          item.sku !== action.sku &&
          (item.quantity === 0 || item.quantity > item.maxAvailableQuantity)
        ) {
          updatedInvalidQuantity = true;
        }
        return isItemRequired;
      });
      invalidQuantity = updatedInvalidQuantity;
      break;

    case "RESET_CART":
      cartItems = [];
      totalQuantity = 0;
      totalCartPrice = 0;
      invalidQuantity = false;
      shippingTotal = 0;
      break;

    case "UPDATE_QUANTITY":
      cartItems = state.cartItems.map((item) => {
        if (item.sku === action.sku) {
          const newQuantity = action.quantity;
          const updatedTotalPrice = newQuantity * item.price;
          totalCartPrice += updatedTotalPrice - item.totalPrice;
          totalQuantity += newQuantity - item.quantity;
          item.quantity = newQuantity;
          item.totalPrice = updatedTotalPrice;
        }
        if (
          updatedInvalidQuantity === false &&
          (item.quantity === 0 || item.quantity > item.maxAvailableQuantity)
        ) {
          updatedInvalidQuantity = true;
        }
        return item;
      });
      invalidQuantity = updatedInvalidQuantity;
      break;

    case "UPDATE_ESTIMATEDATE":
      cartItems = state.cartItems.map((item) => {
        action.inPatientDeliveryDetails.map((x: DeliveryDetail) => {
          if (x.wareHouseId.toString() === item.wareHouseID?.toString()) {
            const estDate = x.estimatedDeliveryDetails.filter(
              (y: EstimatedDeliveryDetail) =>
                y.kciDeliveryCode === action.shippingMethod
            )[0];
            item.estimatedArrivalDate = estDate.estArrivalDate.toString();
          }
        });
        return item;
      });
      shippingTotal = action.shippingTotal;
      break;
    case "UPDATE_SUPPLY_STATUS":
      const responseReturnCodes = action.returnCodes;
      cartItems = state.cartItems.map((item, index) => {
        return { ...item, supplyStatus: responseReturnCodes[index] };
      });
      break;
    default:
      return state; // Return the unchanged state if action is not recognized
  }

  return {
    cartItems,
    invalidQuantity,
    totalQuantity,
    totalCartPrice,
    shippingTotal,
  };
};
