import React, { ReactNode, useContext, useState } from "react";
import {
  ISalesRoundingContextType,
  SaleRoundingToolContext,
} from "../../../context/SalesRoundingToolContext";
import { SalesRoundingToolPageSection } from "../salesRoundingTool.enum";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { ReactComponent as BackIcon } from "../../../assets/LPBackButton.svg";
import "./salesRoundingToolContainer.css";
import { useHistory, useLocation } from "react-router-dom";
import SalesRoundingFacilityList from "../SalesRoundingTerritoryFacilitiesList/SalesRoundingTerritoryFacilitiesList.component";
import { useMediaQuery } from "@mui/material";
import {
  ISelectedTerritory,
  ITerritoryFacility,
} from "../salesRoundingTool.interface";
import SalesRoundingDashboard from "../salesRoundingDashboard/salesRoundingDashboard.component";
import { Navigator } from "../../../components/helpAndSupport/Navigator/navigator.component";
import {
  INavigator,
  IRouter,
} from "../../../components/helpAndSupport/Navigator/navigator.interface";

const SalesRoundingToolContainer: React.FC = () => {
  const location = useLocation<any>();
  const [selectedTerritory, setSelectedTerritory] =
    useState<ISelectedTerritory>(location?.state?.stateData);

  const history = useHistory();
  const isMobileScreen = useMediaQuery("(max-width:768px)");

  const salesRoundingObj = useContext<ISalesRoundingContextType | null>(
    SaleRoundingToolContext
  );

  const pageSection = salesRoundingObj!.pageSection;
  const setPageSection = salesRoundingObj!.setPageSection;
  const [selectedFacility, setSelectedFacility] =
    useState<ITerritoryFacility | null>(null);

  const sectionsToDisplay = (): React.ReactNode => {
    let section: ReactNode;
    switch (pageSection) {
      case SalesRoundingToolPageSection.FACILITY_SELECTION:
        section = (
          <SalesRoundingFacilityList
            selectedTerritory={selectedTerritory}
            setSelectedTerritory={setSelectedTerritory}
            setPageSection={setPageSection}
          />
        );
        break;
      case SalesRoundingToolPageSection.SRT_PATIENTS_DASHBOARD:
        section = <SalesRoundingDashboard setPageSection={setPageSection} />;
        break;
    }
    return section;
  };

  const getNavigatorArrayProps = (
    pageSection: SalesRoundingToolPageSection
  ): INavigator => {
    let navigatorProps: INavigator = {
      title: "",
      array: [],
    };
    switch (pageSection) {
      case SalesRoundingToolPageSection.FACILITY_SELECTION:
        navigatorProps = {
          title: "Sales Rounding",
          array: [],
        };
        break;
      case SalesRoundingToolPageSection.SRT_PATIENTS_DASHBOARD:
        navigatorProps = {
          title: "Facility Details",
          array: [
            {
              pageName: "Sales Rounding",
              route: "/salesRounding",
              onLinkClick: () =>
                setPageSection(SalesRoundingToolPageSection.FACILITY_SELECTION),
            },
          ],
        };
        break;
    }
    return navigatorProps;
  };

  return (
    <div
      className="sales-rounding-tool-component-container"
      data-testid="sales-rounding-tool-component-container"
      id="sales-rounding-tool-component-container"
    >
      <ExpressButton
        variant="text"
        parentClass="sales-rounding-tool-container-back-button"
        startIcon={<BackIcon className="backIcon-SelectaFacility" />}
        clickHandler={() => history.goBack()}
        testId="sales-rounding-tool-container-back-button"
      >
        Return to Sales Dashboard
      </ExpressButton>
      <div className="sales-rounding-component">
        {!isMobileScreen && (
          <Navigator
            {...getNavigatorArrayProps(pageSection)}
            isStateDataPresent={true}
            stateData={selectedTerritory}
          />
        )}
        {sectionsToDisplay()}
      </div>
    </div>
  );
};

export default SalesRoundingToolContainer;
