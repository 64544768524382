import { Circle } from "@mui/icons-material";
import { Grid, IconButton, Menu, MenuItem, useMediaQuery } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { ReactComponent as ThreeDotsIcon } from "../../../assets/patientAction.svg";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import {
  FaciliitySupplyOrdersContext,
  IFacilitysupplyOrdersContextType,
} from "../../../context/FacilitySupplyOrdersContext";
import {
  InpatientSupplyOrderContext,
  InpatientSupplyOrderContextType,
} from "../../../context/InpatientSupplyOrderContext";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import ErrorPopup, {
  defaultErrorPopUp,
  IErrorPopUp,
} from "../../../core/errorPopup/errorPopup.component";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { Popup } from "../../../core/popup/popup.component";
import { IPermissionData } from "../../../RolesPermission/RolesPermission.model";
import { getCSVDownload, getPatient, getPatientMenuAction } from "../../../util/3meService";
import {
  ERROR_MSG_FACILITY_SUPPLY_ORDER_DOWNLOAD_CSV,
  ERROR_MSG_FACILITY_SUPPLY_ORDER_LOAD,
} from "../../../util/errorMsg";
import { getFacilitySupplyOrders } from "../../../util/facilityManagerService";
import {
  agGridGetRowStyle,
  convertStringToDate,
  getCSV,
  getFacilityAccountNumber,
  getSiteUseId,
  makeCapitalEachWordInString,
} from "../../../util/utilityFunctions";
import { Navigator } from "../../helpAndSupport/Navigator/navigator.component";
import { InpatientSupplyOrderPageSection } from "../../inpatientSupplyOrder/inpatientSupplyOrder.enum";
import { FacilitySupplyOrderFilter } from "../facilitySupplyOrderFilter/facilitySupplyOrdersFilter.component";
import {
  IButtonAction,
  IFacilitySupplyOrderList,
} from "../facilitySupplyOrders.interface";
import {
  buttonAction,
  facilitySupplyOrderNavigatorArray,
} from "../facilitySupplyOrders.model";
import "./facilitySupplyOrderListPage.css";
import { OrderDetailContextType, OrderDetailContext } from "../../../context/OrderDetailsContext";
import { ISupplyOrdersInfo } from "../../myPatients/patientAndTherapyDetails/orderSupplyDetail/orderSupplyDetails.interface";
import { ORDER_OVERVIEW_NAVIGATION_EXCEPTION } from "../../../util/errorCode";

const FacilitySupplyOrderListPage = () => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const history = useHistory();
  const isMobileScreen = useMediaQuery("(max-width:926px)");
  const [openMenuFlag, setOpenMenuFlag] = useState<boolean>(false);
  const permissionsObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const isSmallMobile = useMediaQuery("(max-width: 600px)");
  const isIpadScreen = useMediaQuery("(max-width: 1100px)");
  const [error, setError] = useState<IErrorPopUp>(defaultErrorPopUp);
  const roles = permissionsObj?.mappedRolesPermissionData!;
  const [facilitySupplyOrderData, setFacilitySupplyOrderData] = useState<
    IFacilitySupplyOrderList[]
  >([]);
  const [sortedFacilitySupplyOrderData, setSortedFacilitySupplyOrderData] =
    useState<IFacilitySupplyOrderList[]>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showCsvLoader, setCsvShowLoader] = useState<boolean>(false);
  const [numberOfPages, setNumberOfPages] = useState<any[]>([]);
  const [allPageNumbers, setAllPageNumbers] = useState<any[]>([]);
  const [perPageCount, setPerPageCount] = useState(25);
  const [selectedPageNumber, setSelectedPageNumber] = useState(1);
  const startIndex = (selectedPageNumber - 1) * perPageCount;
  const endIndex = selectedPageNumber * perPageCount;
  const inpatientSupplyOrderObj =
    useContext<InpatientSupplyOrderContextType | null>(
      InpatientSupplyOrderContext
    );
  const [searchInput, setSearchInput] = useState<string>("");
  const facilitySupplyOrderContextObj =
    useContext<IFacilitysupplyOrdersContextType | null>(
      FaciliitySupplyOrdersContext
    );
  const data = facilitySupplyOrderContextObj!.data;
  const setData = facilitySupplyOrderContextObj!.setData;
  const orderOverViewObj = useContext<OrderDetailContextType | null>(
    OrderDetailContext
  );
  const handleOpenActionMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setOpenMenuFlag(!openMenuFlag);
    setAnchorElement(anchorElement ? null : event.currentTarget);
  };
  const handleCloseActionMenu = () => {
    setOpenMenuFlag(false);
    setAnchorElement(null);
  };
  const handleButtonOptionClick = (buttonAction: IButtonAction) => {
    switch (buttonAction.label.toLowerCase()) {
      case "download":
        handleDownloadMyPatientsCSV();
        break;
      default:
        if (buttonAction.path) {
          if (
            buttonAction.path === "/orders/inpatientSupplyOrder" ||
            buttonAction.path.includes("/orders/inpatientStockSupplyOrder")
          ) {
            let isFromStockSupplies = buttonAction.path.includes(
              "/orders/inpatientStockSupplyOrder"
            );
            inpatientSupplyOrderObj?.resetContext();
            inpatientSupplyOrderObj?.setPage(
              isFromStockSupplies
                ? InpatientSupplyOrderPageSection.FIRST_PAGE
                : InpatientSupplyOrderPageSection.LIST_PAGE
            );
            history.push({
              pathname: buttonAction.path,
              state: {
                isFromStockSupplies: isFromStockSupplies,
              },
            });
          } else {
            history.push(buttonAction.path);
          }
        }
        break;
    }
    handleCloseActionMenu();
  };

  const handleROClick = async(selectedData: IFacilitySupplyOrderList) => {
    if(selectedData.orderType === "Stock")
    {
    facilitySupplyOrderContextObj?.setIsFromFacilitySupplyOrder(true);
    history.push({
      pathname: "/orders/supplyOrderDetail",
      state: {
        stateData: selectedData,
      },
    });
  }
  else
  {
    setCsvShowLoader(true);
    let patientData: any;
    let menuActions: any;
    try{
      const getPatientAPIResponse = await getPatient(
        selectedData.roNumber,
        null,
        getSiteUseId(authObj),
        true,
        false
      );
      if (
        getPatientAPIResponse.succeeded &&
        getPatientAPIResponse.data !== null
      ) {
        patientData = getPatientAPIResponse.data;
        patientData.type = selectedData.orderType;
        patientData.isAcuteOrder=true;
    
    menuActions = await getPatientMenuAction(
     "solventumProduct",
      patientData.isFromAllFacility,
      selectedData.roNumber,
      null,
      true,
      getSiteUseId(authObj)
    );
    patientData.color = menuActions.data.color;
    patientData.siteUseId = getSiteUseId(authObj);
    patientData.statusColor = menuActions.data.color + "Circle";
    patientData.menuActions = menuActions.data.menuActions;
    patientData.isAcuteOrder = true;
    orderOverViewObj?.resetData();
    orderOverViewObj?.resetSeletedSupplyOrderData();
    orderOverViewObj?.resetWoundData();
    orderOverViewObj?.setSelectedOrderTab("Orders");
    setCsvShowLoader(false);
    history.push( {
      pathname: "/home/orderOverview",
      state: {
        stateData: patientData,
      },
    });
  }
  else
  {
    let error: IErrorPopUp = {
      errorCode:
      getPatientAPIResponse?.error?.code ||
      getPatientAPIResponse?.error?.errorCode ||
      getPatientAPIResponse?.status,
      errorMessage: ERROR_MSG_FACILITY_SUPPLY_ORDER_LOAD,
      errorFlag: true,
    };
    setCsvShowLoader(false);
    setError(error);
  }

  }
    catch(error)
    {
      let excepObject: IErrorPopUp = {
        errorCode:ORDER_OVERVIEW_NAVIGATION_EXCEPTION,
        errorMessage: ERROR_MSG_FACILITY_SUPPLY_ORDER_LOAD,
        errorFlag: true,
      };
      setError(excepObject);
      setCsvShowLoader(false);
    }
  }
  };

  const defaultColDef = {
    sortable: true,
    unSortIcon: true,
    suppressMenu: true,
    autoHeight: true,
    flex: 1,
    cellStyle: {
      whiteSpace: "normal",
      wordWrap: "break-word",
    },
  };
  const columnDefs: any[] = [
    {
      headerName: "Order Date",
      field: "orderDate",
      minWidth: isMobileScreen ? 100 : 150,
      width: isMobileScreen ? 100 : 150,
      sortable: true,
      suppressMovable: true,
      wrapText: true,
      flex: isSmallMobile ? 1 : 0,
      pinned: isMobileScreen ? "left" : null,
      lockPinned: true,
      cellRenderer: (params: any) => (
        <>{convertStringToDate(params.data.orderDate)}</>
      ),
    },
    {
      headerName: "Order#",
      field: "roNumber",
      minWidth: isMobileScreen ? 100 : 150,
      width: isMobileScreen ? 100 : 150,
      sortable: true,
      suppressMovable: true,
      pinned: isMobileScreen ? "left" : null,
      lockPinned: true,
      cellRenderer: (params: any) => (
        <>
          <span
            className={"inpatient-facility-supplyorder-ro"}
            id={params.data.roNumber}
            onClick={() => handleROClick(params.data)}
          >
            {params.data.roNumber}
          </span>
        </>
      ),
    },
    {
      headerName: "Sales PO#",
      field: "salesPo",
      minWidth: 200,
      width: 200,
      sortable: true,
      suppressMovable: true,
      cellRenderer: (params: any) => <>{params.data.salesPo}</>,
    },
    {
      headerName: "Order Type",
      field: "orderType",
      minWidth: 100,
      width: isMobileScreen ? 150 : 100,
      sortable: true,
      suppressMovable: true,
      cellRenderer: (params: any) => <>{params.data.orderType}</>,
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: isMobileScreen ? 110 : 120,
      width: isMobileScreen ? 110 : 120,
      sortable: true,
      suppressMovable: true,
      flex: isMobileScreen ? 0 : 1,
      cellRenderer: (params: any) => (
        <div className="facilitySupplyOrderStatus">
          <Circle
            className={
              params.data.status === "Received"
                ? "facility-supply-order-blueCircle"
                : "facility-supply-order-greenCircle"
            }
          />
          {params.data.status}
        </div>
      ),
    },
    {
      headerName: "Ordered By",
      field: "orderBy",
      minWidth: isMobileScreen ? 180 : 200,
      width: isMobileScreen ? 180 : 200,
      sortable: true,
      suppressMovable: true,
      flex: isMobileScreen ? 0 : 1,
      cellRenderer: (params: any) => (
        <>{makeCapitalEachWordInString(params.data.orderBy)}</>
      ),
    },
  ];

  useEffect(() => {
    getFacilitySupplyOrderData();
  }, []);

  const setdownloadButtonAction = (disabled: boolean) => {
    buttonAction.map((x) => {
      if (x.label === "Download") {
        x.disabled = disabled;
      }
      return x;
    });
  };

  const getFacilitySupplyOrderData = async () => {
    setdownloadButtonAction(false);
    setShowLoader(true);
    let requestBody = {
      SiteUseID: getSiteUseId(authObj),
      FacilityAccountNumber: getFacilityAccountNumber(authObj),
    };
    const response = await getFacilitySupplyOrders(requestBody);
    if (response && response.succeeded) {
      if (response.items?.length === 0) {
        setdownloadButtonAction(true);
      } else {
        setFacilitySupplyOrderData(response.items);
        let filteredDataBasedOnDateRnage = handleApplyFilterBasedOnDates(
          response.items
        );
        let sortedData = filteredDataBasedOnDateRnage.sort(
          (a: IFacilitySupplyOrderList, b: IFacilitySupplyOrderList) => {
            return a.ropNumber > b.ropNumber ? -1 : 1;
          }
        );
        setSortedFacilitySupplyOrderData(sortedData);
      }
      setShowLoader(false);
    } else {
      let error: IErrorPopUp = {
        errorCode:
          response?.error?.code ||
          response?.error?.errorCode ||
          response?.status,
        errorMessage: ERROR_MSG_FACILITY_SUPPLY_ORDER_LOAD,
        errorFlag: true,
      };
      setShowLoader(false);
      setError(error);
      setShowLoader(false);
    }
  };

  const inPatientFacilitysupplyOrderCSVColumns: string[] = [
    "Order Date",
    "RO Order#",
    "Sales PO#",
    "Order Type",
    "Status",
    "Ordered By",
  ];

  const handleDownloadMyPatientsCSV = async () => {
    setCsvShowLoader(true);
    //csv patients mapping with required columns in sequence
    //this sequence should be and similar to the columns array sequence
    const csvPatients = sortedFacilitySupplyOrderData?.map(
      (eachPatient: IFacilitySupplyOrderList) => {
        return {
          orderDate: eachPatient.orderDate,
          roNumber: eachPatient.roNumber,
          salesPo: eachPatient.salesPo ? eachPatient.salesPo : "",
          orderType: eachPatient.orderType,
          status: eachPatient.status,
          orderBy: makeCapitalEachWordInString(eachPatient.orderBy) ?? "",
        };
      }
    );
    const registeredFacilty = authObj?.registeredFaciltyAddress;
    //FileName Format -> Facility Name Facility ID Patients mmddyyyy Export.csv
    const csvFileName: string = `${registeredFacilty?.accountName
      .split(" ")
      .join("_")}_${registeredFacilty?.accountNumber}_Facility_Supply_Orders_`;
    const reqsBody = {
      Columns: inPatientFacilitysupplyOrderCSVColumns,
      Data: csvPatients,
    };
    const csvDownloadResponse = await getCSVDownload(reqsBody);
    if (csvDownloadResponse && csvDownloadResponse.succeeded) {
      const base64String = csvDownloadResponse.item; //item --> base64
      await getCSV(base64String, csvFileName);
      setCsvShowLoader(false);
    } else {
      setCsvShowLoader(false);
      let error: IErrorPopUp = {
        errorCode:
          csvDownloadResponse?.error?.code ||
          csvDownloadResponse?.error?.errorCode ||
          csvDownloadResponse?.status,
        errorMessage: ERROR_MSG_FACILITY_SUPPLY_ORDER_DOWNLOAD_CSV,
        errorFlag: true,
      };
      setError(error);
      setShowLoader(false);
    }
  };
  const closeErrorPopUpButtonAction = () => {
    history.goBack();
  };
  const returnPermissionButton = (action: IButtonAction) => {
    return (
      action.accessorKey === "IsShowInPatientDownloadButton" ||
      roles[action.accessorKey as keyof IPermissionData]
    );
  };

  const handleApplyFilter = () => {
    const { orderStatus, orderType, startDate, endDate, searchInput } = data;
    const start = moment(startDate.value).startOf("day");
    const end = moment(endDate.value).endOf("day");
    let responseListData =
      facilitySupplyOrderData.length > 0 ? facilitySupplyOrderData : [];
    let filterSupplyPatientData = responseListData.filter(
      (supplyPatientOrder: IFacilitySupplyOrderList) => {
        const orderDate = moment(supplyPatientOrder.orderDate).startOf("day");
        const orderStatusMatches =
          !orderStatus.value ||
          orderStatus.value === "All statuses" ||
          (supplyPatientOrder.status &&
            supplyPatientOrder.status
              .toLowerCase()
              .includes(orderStatus.value.toLowerCase()));

        const orderTypeMatches =
          !orderType.value ||
          orderType.value === "All order types" ||
          (supplyPatientOrder.orderType &&
            supplyPatientOrder.orderType
              .toLowerCase()
              .includes(orderType.value.toLowerCase()));

        const dateMatches = orderDate.isBetween(start, end, null, "[]");
        const searchMatches =
          !searchInput.value ||
          supplyPatientOrder.roNumber.includes(searchInput.value) ||
          (supplyPatientOrder.salesPo &&
            supplyPatientOrder.salesPo
              .toLowerCase()
              .includes(searchInput.value.toLowerCase()));

        return (
          orderStatusMatches && orderTypeMatches && dateMatches && searchMatches
        );
      }
    );
    setSortedFacilitySupplyOrderData(filterSupplyPatientData);
  };

  const handleApplyFilterBasedOnDates = (
    dataToBeFiltered: IFacilitySupplyOrderList[]
  ) => {
    const { startDate, endDate } = data;
    const start = moment(startDate.value).startOf("day");
    const end = moment(endDate.value).endOf("day");
    let filterSupplyPatientData = dataToBeFiltered.filter(
      (supplyPatientOrder: IFacilitySupplyOrderList) => {
        const orderDate = moment(supplyPatientOrder.orderDate).startOf("day");
        const dateMatches = orderDate.isBetween(start, end, null, "[]");
        return dateMatches;
      }
    );
    return filterSupplyPatientData;
  };

  useEffect(() => {
    handleApplyFilter();
  }, [data.searchInput.value]);

  return (
    <>
      <div
        className="facility-supply-order-list-page-main-div"
        data-testId="facility-supply-order-list-page-main-div-testId"
      >
        <Navigator
          array={facilitySupplyOrderNavigatorArray}
          className="facility-supply-order-breadCrumbs"
          title="Facility Supply Orders"
          data-testId="facility-supply-order-breadCrumbs-testId"
        />
        <div className="facility-supply-orders-header-and-buttons">
          <div
            className="in-patient-facility-supply-order-header-title"
            id="in-patient-facility-supply-order-header-title-id"
          >
            Facility Supply Orders
          </div>
          <div className="facility-supply-button-group">
            {isMobileScreen || isIpadScreen ? (
              <>
                <IconButton
                  onClick={handleOpenActionMenu}
                  data-testid="facility-supply-order-button-menu-testId"
                  id="facility-supply-order-button-menu-id"
                >
                  <ThreeDotsIcon />
                </IconButton>
                <Menu
                  open={openMenuFlag}
                  anchorEl={anchorElement}
                  onClose={handleCloseActionMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  data-testid="facility-supply-order-button-menu-testId"
                >
                  {buttonAction.map((action, index) => (
                    <>
                      {returnPermissionButton(action) && (
                        <MenuItem
                          key={index}
                          onClick={() => handleButtonOptionClick(action)}
                          data-testid={action.testId}
                          id={action.id}
                          disabled={action?.disabled}
                        >
                          {action.label}
                        </MenuItem>
                      )}
                    </>
                  ))}
                </Menu>
              </>
            ) : (
              <>
                {buttonAction.map((action, index) => (
                  <>
                    {returnPermissionButton(action) && (
                      <ExpressButton
                        key={index}
                        parentClass={action.className}
                        startIcon={
                          <img
                            src={
                              action?.disabled
                                ? action.disabledIcon
                                : action.startIcon
                            }
                          />
                        }
                        clickHandler={() => handleButtonOptionClick(action)}
                        testId={action.testId}
                        id={action.id}
                        variant={action.variant}
                        disabled={action?.disabled}
                      >
                        {action.label}
                      </ExpressButton>
                    )}
                  </>
                ))}
              </>
            )}
          </div>
        </div>
        <Grid container className="facility-supply-order-searchbar-div">
          <FacilitySupplyOrderFilter
            data={data}
            setData={setData}
            handleApplyFilter={handleApplyFilter}
          />
        </Grid>
        <div
          className="facilitySupplyOrderMaindiv-aggrid"
          id="facilitySupplyOrderMaindiv-aggrid-id"
          data-testid="facilitySupplyOrderMaindiv-aggrid-testid"
        >
          {!showLoader && (
            <>
              <div
                className="ag-theme-quartz"
                data-testid="inpatient-supply-order-products-ag-grid"
                style={{
                  width: "100%",
                  height: window.innerHeight - 2 * 130,
                }}
              >
                <AgGridReact
                  rowData={sortedFacilitySupplyOrderData}
                  columnDefs={columnDefs}
                  getRowStyle={agGridGetRowStyle}
                  defaultColDef={defaultColDef}
                  autoSizeStrategy={{
                    type: "fitGridWidth",
                  }}
                  suppressRowClickSelection={true}
                  suppressDragLeaveHidesColumns={true}
                  rowHeight={42}
                  pagination={true}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <Popup
        closeHandler={() => setShowLoader(false)}
        dialogParentClass="in-patient-facility-supply-order-container-popup"
        hideCloseButton={true}
        openFlag={showLoader}
      >
        <div
          className="in-patient-facility-supply-order-container-popup-div"
          id="in-patient-supplyspinner"
        >
          <LoadingSpinner />
        </div>
      </Popup>
      <Popup
        closeHandler={() => setCsvShowLoader(false)}
        dialogParentClass="in-patient-facility-supply-order-container-popup"
        hideCloseButton={true}
        openFlag={showCsvLoader}
      >
        <div
          className="in-patient-facility-supply-order-container-popup-div"
          id="in-patient-supplyspinner"
        >
          <LoadingSpinner />
        </div>
      </Popup>
      <ErrorPopup
        buttontitle={"Done"}
        errorCode={error.errorCode}
        errorPopupFlag={error.errorFlag}
        errorMessage={error.errorMessage}
        isShortSentence={true}
        handleBackButton={() => {
          closeErrorPopUpButtonAction();
        }}
        popUpStyles="errorPopup"
        isSupportPhoneRequired={true}
        showTitle={false}
      />
    </>
  );
};

export default FacilitySupplyOrderListPage;
