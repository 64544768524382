import { Button } from "@mui/material";
import "./prescriberInformationReviewOrder.css";
import {
  formatPhoneNumber,
  makeCapitalEachWordInString,
} from "../../../../util/utilityFunctions";
import { IPrescriberInformationReviewOrder } from "./prescriberInformationReviewOrder.interface";
import { Popup } from "../../../../core/popup/popup.component";
import { PrescriberInformationPopUpContainer } from "../../../inpatientOrder/components/prescriberInformation/popUpContainer/prescriberInformationPopUpContainer.component";
import { PrescriberPopUp } from "../../../inpatientOrder/components/prescriberInformation/popUpContainer/prescriberInformationPopUpContainer.enum";
import { IPrescriberInformation } from "../../../inpatientOrder/components/prescriberInformation/prescriberInformation.interface";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { useContext, useState } from "react";
import {
  RolesPermissionContextType,
  RolesPermissionContext,
} from "../../../../context/RolesPermissionContext";

export const PrescriberInformationReviewOrder = ({
  data,
  editButtonClicked,
  isOrderSummary = false,
  prescriberInfo,
  isPrescriberEditMode = false,
  setIsPrescriberEditMode,
  setPrescriberInfo,
  handleSubmitPrescriberData,
  isAcuteOrder,
  isPrescriberPopupOpen,
  setIsPrescriberPopupOpen,
  setExitPopUp,
  isEditable,
}: IPrescriberInformationReviewOrder) => {
  data = prescriberInfo ? prescriberInfo : data;
  const handleSuccessAndClose = (prescriberData: IPrescriberInformation) => {
    setPrescriberInfo!(prescriberData);
    setIsPrescriberPopupOpen!(false);
  };
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const [currentPopup, setCurrentPopup] = useState<PrescriberPopUp>(
    PrescriberPopUp.SEARCH_PRESCRIBER
  );

  return (
    <div
      className="prescriber-information-review-order"
      id="prescriber-information-review-order"
    >
      <div className="prescriber-information-component-title">
        <h2
          className="prescriber-information-review-order-title"
          data-testid="prescriber-information-review-order-title"
        >
          Prescriber Information
        </h2>
        {(!isOrderSummary || isEditable) && !isPrescriberEditMode && (
          <Button
            classes={{
              root: !permissionObj?.mappedRolesPermissionData?.IsSupportRole!
                ? "prescriber-information-review-order-edit-button"
                : "prescriber-information-review-order-edit-button-disabled",
            }}
            data-testid="prescriber-information-review-order-edit-button"
            onClick={editButtonClicked}
            disabled={permissionObj?.mappedRolesPermissionData?.IsSupportRole!}
          >
            Edit
          </Button>
        )}
      </div>
      <div className="all-content-div">
        <div className="content-div">
          <div className="sub-content-div">
            <h5
              className="prescriber-information-review-order-content-title"
              data-testid="prescriber-name"
            >
              Prescriber Name
            </h5>
            <h5
              className="prescriber-information-review-order-content-value"
              data-testid="prescriber-name-value"
            >
              {data
                ? `${makeCapitalEachWordInString(
                    data.firstName
                  )} ${makeCapitalEachWordInString(data.lastName)}`
                : "--"}
            </h5>
          </div>
          <div className="sub-content-div">
            <h5
              className="prescriber-information-review-order-content-title"
              data-testid="npi"
            >
              NPI
            </h5>
            <h5
              className="prescriber-information-review-order-content-value"
              data-testid="npi-value"
            >
              {data?.npi ? `${data.npi}` : "--"}
            </h5>
          </div>
        </div>
        <div className="content-div">
          <div className="sub-content-div">
            <h5
              className="prescriber-information-review-order-content-title"
              data-testid="phone"
            >
              Phone Number
            </h5>
            <h5
              className="prescriber-information-review-order-content-value"
              data-testid="phone-value"
            >
              {data?.telephoneNumber ? (
                <a href={`tel:${formatPhoneNumber(data.telephoneNumber)}`}>
                  {formatPhoneNumber(data.telephoneNumber)}
                </a>
              ) : (
                "--"
              )}
            </h5>
          </div>
          <div className="sub-content-div">
            <h5
              className="prescriber-information-review-order-content-title"
              data-testid="Fax"
            >
              Fax Number
            </h5>
            <h5
              className="prescriber-information-review-order-content-value"
              data-testid="fax-value"
            >
              {data && data.faxNumber
                ? formatPhoneNumber(data.faxNumber)
                : "--"}
            </h5>
          </div>
        </div>
        <div className="content-div-last">
          <div className="sub-content-div">
            <h5
              className="prescriber-information-review-order-content-title"
              data-testid="email"
            >
              Email
            </h5>
            <h5
              className="prescriber-information-review-order-content-value"
              data-testid="email-value"
            >
              {data && data.email ? (
                <a
                  className="contact-value-email-phone"
                  href={`mailto:${data?.email!}`}
                >
                  {data?.email!}
                </a>
              ) : (
                "--"
              )}
            </h5>
          </div>
          <div className="sub-content-div">
            <h5
              className="prescriber-information-review-order-content-title"
              data-testid="address"
            >
              Address
            </h5>
            <h5
              className="prescriber-information-review-order-content-value"
              data-testid="address1-value"
            >
              {data && data.address1 && data.address1 !== ""
                ? makeCapitalEachWordInString(data.address1)
                : "--"}
            </h5>
            {data && data.address2 && data.address2 !== "" && (
              <h5
                className="prescriber-information-review-order-content-value"
                data-testid="address2-value"
              >
                {makeCapitalEachWordInString(data.address2)}
              </h5>
            )}
            <h5
              className="prescriber-information-review-order-content-value"
              data-testid="city-state-zip-value"
            >
              {makeCapitalEachWordInString(data?.city ?? "")}
              {data?.city ? ", " : ""}
              {data?.state} {data?.zipCode}
            </h5>
          </div>
        </div>
        {isPrescriberEditMode && (
          <div className="edit-info-button-group">
            <ExpressButton
              variant={"outlined"}
              parentClass="edit-button"
              clickHandler={() => {
                setIsPrescriberEditMode!(false);
                setPrescriberInfo!(null);
                setExitPopUp!(false);
              }}
            >
              Cancel
            </ExpressButton>
            <ExpressButton
              variant={"contained"}
              parentClass="edit-button"
              clickHandler={() => handleSubmitPrescriberData!()}
              disabled={prescriberInfo === null}
            >
              Save
            </ExpressButton>
          </div>
        )}
      </div>
      <Popup
        openFlag={isPrescriberPopupOpen}
        dialogParentClass="send-note-prescriber-pop-up-container"
        closeHandler={() => {
          setIsPrescriberPopupOpen!(false);
          setIsPrescriberEditMode!(false);
        }}
        hideCloseButton={currentPopup === PrescriberPopUp.LOADER}
      >
        <PrescriberInformationPopUpContainer
          closePopUp={() => setIsPrescriberPopupOpen!(false)}
          currentPopUp={currentPopup}
          setCurrentPopUp={setCurrentPopup}
          successAndClose={handleSuccessAndClose}
          selectedNpi={data && data.npi ? data.npi : ""}
        />
      </Popup>
    </div>
  );
};
