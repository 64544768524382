import React, { useContext, useEffect, useState } from "react";
import "./SalesRoundingTerritoryFacilitiesList.css";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as ChangeSalesTerrioryIcon } from "../../../assets/ChangeTerritoryIcon.svg";
import { InputBase, useMediaQuery } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  ISelectedTerritory,
  ITerritoryFacility,
} from "../salesRoundingTool.interface";
import SearchIcon from "@mui/icons-material/Search";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import {
  getFacilitySearchResultByTerritory,
  updateFavouriteFacility,
} from "../../../util/userService";
import { FavoriteType } from "../../favouriteFacilityDisplay/favoriteFacilityDisplay.component";
import { SalesRoundingToolPageSection } from "../salesRoundingTool.enum";
import {
  ISalesRoundingContextType,
  SaleRoundingToolContext,
} from "../../../context/SalesRoundingToolContext";
import Favorite from "../../../assets/Favorite.png";
import NotFavourite from "../../../assets/NotFavourite.svg";
import { ShowTitleAndValue } from "../../../core/showTitleAndValue/showTitleAndValue.component";
import { makeCapitalEachWordInString } from "../../../util/utilityFunctions";
import { Popup } from "../../../core/popup/popup.component";
import { ChangeSalesTerritory } from "../changeSalesTerritory/changeSalesTerritory.component";
import { ITerritoryDetails } from "../../../components/administration/salesManageUsers/salesUserProfile/AddSalesTerritory/addSalesTerritory.interface";
import ErrorPopup, {
  defaultErrorPopUp,
  IErrorPopUp,
} from "../../../core/errorPopup/errorPopup.component";
import { SVC_GET_TERRITORY_FACILITY_ERROR } from "../../../util/errorMsg";

export interface ISalesRoudingFacilityList {
  selectedTerritory: ISelectedTerritory;
  setPageSection: Function;
  setSelectedTerritory: Function;
}

const SalesRoundingFacilityList: React.FC<ISalesRoudingFacilityList> = ({
  selectedTerritory,
  setPageSection,
  setSelectedTerritory,
}) => {
  const history = useHistory();
  const isMobileScreen = useMediaQuery("(max-width:768px)");
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const salesRoundingObj = useContext<ISalesRoundingContextType | null>(
    SaleRoundingToolContext
  );
  const [error, setError] = useState<IErrorPopUp>(defaultErrorPopUp);
  const [loader, setLoader] = useState<boolean>(false);
  const [searchInputText, setSearchInputText] = useState<string>("");
  const [territoriesFacilitiesList, setTerritoriesFacilitiesList] = useState<
    ITerritoryFacility[]
  >([]);
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);

  const handleSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const regex = /^[a-zA-Z0-9-\s]+$/;
    if (regex.test(e.target.value) || e.target.value.trim() === "") {
      setSearchInputText(e.target.value.trim() === "" ? "" : e.target.value);
    }
  };

  const getFacilitiesByTerritory = async () => {
    const reqParams = {
      username: authObj?.userProfile?.userName,
      territoryCode: selectedTerritory.code,
    };
    setLoader(true);
    const response = await getFacilitySearchResultByTerritory(reqParams);
    setLoader(false);
    if (response && response.succeeded) {
      const facilitiesList = response.data.result
        .flat()
        .sort((a: any, b: any) => {
          if (b.isFavourite !== a.isFavourite) {
            return b.isFavourite - a.isFavourite;
          }
          return a.accountName.localeCompare(b.accountName);
        });
      setTerritoriesFacilitiesList(facilitiesList);
    } else {
      const apiFailure = {
        errorCode:
          response?.error?.errorCode ||
          response?.error?.code ||
          response?.status,
        errorFlag: true,
        errorMessage: SVC_GET_TERRITORY_FACILITY_ERROR,
      };
      setError(apiFailure);
    }
  };

  const filteredFacilitiesList = (searchText: string): ITerritoryFacility[] => {
    let list: ITerritoryFacility[] = territoriesFacilitiesList;
    if (searchText.length > 2) {
      list = territoriesFacilitiesList.filter((x) => {
        return (
          x.accountName.toLowerCase().includes(searchText.toLowerCase()) ||
          x.accountNumber.toLowerCase().includes(searchText.toLowerCase())
        );
      });
    }
    return list;
  };

  const filteredData = filteredFacilitiesList(searchInputText);

  const handleFavLink = async (
    data: ITerritoryFacility,
    isFavourite: boolean
  ) => {
    const updateFavouriteFacilityRes = await updateFavouriteFacility(
      authObj?.userProfile?.userName,
      data.siteUseId,
      isFavourite ? FavoriteType.REMOVEFAVORITE : FavoriteType.ADDFAVORITE
    );
    if (updateFavouriteFacilityRes) {
      const updatedFacilityData = territoriesFacilitiesList
        .map((facilityObj) => {
          if (facilityObj.siteUseId === data.siteUseId) {
            return {
              ...facilityObj,
              isFavourite: !isFavourite,
            };
          } else {
            return facilityObj;
          }
        })
        .sort((a: any, b: any) => {
          if (b.isFavourite !== a.isFavourite) {
            return b.isFavourite - a.isFavourite;
          }
          return a.accountName.localeCompare(b.accountName);
        });
      setTerritoriesFacilitiesList(updatedFacilityData);
    }
  };
  const handleFacilitySelection = (selectedFacilityObj: ITerritoryFacility) => {
    salesRoundingObj?.setSelectedFacility(selectedFacilityObj);
    setPageSection(SalesRoundingToolPageSection.SRT_PATIENTS_DASHBOARD);
  };

  const handleSetSelectedTerritory = (territory: ITerritoryDetails | null) => {
    let territoryName = territory && territory.code + " " + territory?.name;
    let tempTerritoryObj: ISelectedTerritory = {
      code: territory?.code ?? "",
      name: territoryName ?? "",
    };
    setSelectedTerritory(tempTerritoryObj);
    setOpenPopUp(false);
  };

  useEffect(() => {
    getFacilitiesByTerritory();
  }, [selectedTerritory.code]);

  return (
    <div
      className="sales-rounding-list-page"
      data-testid="sales-rounding-list-page"
      id="sales-rounding-list-page"
    >
      {loader ? (
        <div className="sales-rounding-full-screen-loader">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="sales-rounding-facility-list-div">
          <div className={isMobileScreen ? "sales-rounding-mobile-view" : ""}>
            <div
              className="sales-rounding-facility-name-div"
              data-testid="sales-rounding-facility-name-div"
              id="sales-rounding-facility-name-div"
            >
              {selectedTerritory && (
                <Typography className="sales-rounding-facility-name-header">
                  {!isMobileScreen && "Sales Rounding for"}
                  <span className="sales-rounding-facility-name-header-bold">
                    {" " +
                      selectedTerritory.name.replace(/^(\S+)(\s)/, "$1 - ")}
                  </span>
                </Typography>
              )}
              <span
                data-testid="sales-rounding-change-teritory-button"
                className="sales-rounding-change-teritory-button"
                id="sales-rounding-change-teritory-button"
                onClick={() => setOpenPopUp(true)}
              >
                <ChangeSalesTerrioryIcon className="change-sales-territory-icon" />{" "}
                Change Sales Territory
              </span>
            </div>
            <div
              className="sales-rounding-search-input"
              data-testid="sales-rounding-search-input"
              id="sales-rounding-search-input"
            >
              <div
                className="search-icon-div"
                id="sales-rounding-search-icon-div"
                data-testid="sales-rounding-search-icon-div"
              >
                <SearchIcon className="territory-search-icon" />
              </div>
              <InputBase
                id="sales-rounding-user-search-input"
                className="user-search-input"
                data-testid="user-search-input"
                onChange={handleSearch}
                placeholder="Filter by Facility Name or Number"
                value={searchInputText}
                autoFocus={true}
              />
            </div>
          </div>
          <div
            className="sales-rounding-table-section"
            id="sales-rounding-table-section"
          >
            <div
              className="sales-rounding-card-view"
              id="sales-rounding-card-view"
            >
              {filteredData && filteredData.length > 0 ? (
                filteredData.map((facilityObj: ITerritoryFacility) => (
                  <Grid container className="sales-rounding-grid-container">
                    <Grid
                      item
                      xs={12}
                      className="sales-conatiner-grid-item-facility-name-favourite"
                    >
                      <p
                        className="sales-rounding-facility-name"
                        data-testid="sales-rounding-facility-name-testId"
                        id="sales-rounding-facility-name-testId"
                        onClick={() => handleFacilitySelection(facilityObj)}
                      >
                        {makeCapitalEachWordInString(facilityObj.accountName)}
                      </p>
                      <div
                        className="sales-rounding-favorite"
                        data-testid="sales-rounding-favorite-testId"
                      >
                        <img
                          src={
                            facilityObj.isFavourite ? Favorite : NotFavourite
                          }
                          alt={
                            facilityObj.isFavourite
                              ? "Favorite"
                              : "NotFavourite"
                          }
                          onClick={() =>
                            handleFavLink(facilityObj, facilityObj.isFavourite)
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={isMobileScreen ? 6 : 3}>
                      <ShowTitleAndValue
                        title={"Facility Number"}
                        value={facilityObj.accountNumber}
                        testId={"sales-rounding-facility-number"}
                      />
                    </Grid>
                    <Grid item xs={isMobileScreen ? 6 : 3}>
                      <ShowTitleAndValue
                        title={"Setting"}
                        value={facilityObj.typeName}
                        testId={"sales-rounding-setting"}
                      />
                    </Grid>
                    <Grid item xs={isMobileScreen ? 6 : 3}>
                      <ShowTitleAndValue
                        title={"Classification"}
                        value={facilityObj.careSetting}
                        testId={"sales-rounding-classification"}
                      />
                    </Grid>
                    <Grid item xs={isMobileScreen ? 6 : 3}>
                      <ShowTitleAndValue
                        title={"Ready Care"}
                        value={facilityObj.readyCareFlag === "Y" ? "Yes" : "No"}
                        testId={"sales-rounding-ready-care"}
                      />
                    </Grid>
                  </Grid>
                ))
              ) : (
                <div className="sales-rounding-no-facilities">
                  No facilities to display
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <Popup
        openFlag={openPopUp}
        closeHandler={() => setOpenPopUp(false)}
        dialogParentClass={"norecord-pop-up"}
      >
        <ChangeSalesTerritory
          selectedTerritory={selectedTerritory.code}
          setSelectedTerritory={handleSetSelectedTerritory}
          setShowPopup={() => setOpenPopUp(false)}
        />
      </Popup>
      <ErrorPopup
        buttontitle={"Done"}
        errorCode={error.errorCode}
        errorPopupFlag={error.errorFlag}
        errorMessage={error.errorMessage}
        isShortSentence={true}
        handleBackButton={() => history.goBack()}
        popUpStyles="errorPopup"
        isSupportPhoneRequired={true}
        showTitle={false}
      />
    </div>
  );
};

export default SalesRoundingFacilityList;
