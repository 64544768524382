export enum OrderOverViewTabs {
  ORDER_DETAILS = "Orders",
  DOCUMENTS = "Documents",
  WOUND_PROGRESS = "Wound Progress",
  PATIENT_FINANCIAL_INFO = "Patient Financial Info",
}
export enum acceptedOrderOverviewStatus {
  ORDER_RECEIVED = "Order Received",
  BENEFITS_VERIFIED = "Benefits Verified",
  PLACEMENT_APPROVED = "Placement Approved",
  OUT_FOR_DELIVERY = "Out for Delivery",
  SHIPPED = "Shipped",
  THERAPY = "Therapy",
  CLOSED = "Closed",
  CANCELLED = "Cancelled",
}

export enum alertTypes {
  Missing_Rx = "Missing Rx",
  Confirm_Placement = "Confirm Placement",
  Proof_of_Delivery_Needed = "Proof of Delivery Needed",
  Incomplete_Wound_information = "Incomplete Wound Details",
}

export enum OrderOverViewTabsTitle {
  ORDER_DETAIL_TAB_TITLE = "Order Detail",
  ORDER_TAB_TITLE = "Orders",
  SUPPLY_ORDER_TAB_TITLE = "Supply Orders",
  DOCUMENTS_TAB_TITLE = "Documents",
  WOUND_PROGRESS_TAB_TITLE = "Wound Progress",
  PATIENT_FINANCIAL_INFO_TAB_TITLE = "Patient Financial Info",
  WOUND_DETAIL_TAB_TITLE = "Wound Detail",
}

export enum TherapyStatus {
  CONTINUE = "Continuing",
  ONHOLD = "On Hold",
  DISCONTINUE = "Discontinued",
}
