import { useState, useEffect } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { Location } from "history";
import { ReusablePopUp } from "../../../../../core/resuablePopup/reusablePopUp.component";
import UserConfirmationDialogue from "../../../../newOrder/saveOrderConfirmation/userConfirmationDialogue.component";
import { Popup } from "../../../../../core/popup/popup.component";

interface DProps {
  when?: boolean | undefined;
  navigate?: (path: string, state?: any) => void;
  shouldBlockNavigation: (location: Location) => boolean;
  handleSaveExit?: Function;
}

export const UserPopupConfirmation: React.FC<DProps> = ({
  when,
  navigate,
  shouldBlockNavigation,
  handleSaveExit,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleBlockedNavigation = (
    nextLocation: Location
  ): string | boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setLastLocation(nextLocation);
      setModalVisible(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate!(lastLocation.pathname, lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Popup
        openFlag={modalVisible}
        closeHandler={() => {
          setModalVisible(false);
        }}
      >
        <UserConfirmationDialogue
          closeModal={() => {
            setModalVisible(false);
          }}
          handleConfirmNavigationClick={() => setConfirmedNavigation(true)}
          handleSaveExitNavigationClick={async () => {
            const isToNavigate = await handleSaveExit!();
            setModalVisible(false);
            if (isToNavigate) {
              setConfirmedNavigation(true);
            } else {
              setConfirmedNavigation(false);
            }
          }}
        />
      </Popup>
    </>
  );
};
