import { useMediaQuery } from "@mui/material";
import { DeliveryInformationReview } from "../../inpatientSupplyOrder/component/supplyOrderDeliveryInformation/reviewDeliveryInformation/deliveryInformationReview.component";
import { SalesPoOrderInfoReview } from "../../inpatientSupplyOrder/component/salesPurchaseOrder/reviewSalesPurchaseOrder/salesPurchaseOrderReview.component";
import { ShippingAddressReview } from "../../inpatientSupplyOrder/component/shippingAddress/reviewShippingAddress/shippingAddressReview.component";
import { Navigator } from "../../helpAndSupport/Navigator/navigator.component";
import "./inpatientSupplyOrderDetails.css";
import { useContext, useEffect, useState } from "react";
import SupplyOrderDetailsTracking from "../../myPatients/patientAndTherapyDetails/supplyOrderDetailsTracking/supplyOrderDetailsTracking.component";
import { useHistory, useLocation } from "react-router-dom";
import {
  IFacilitySupplyOrderList,
  IInpatientSupplyOrderDetails,
} from "../facilitySupplyOrders.interface";
import SelectedSupplies from "../../inpatientSupplyOrder/component/selectedSupplies/selectedSupplies.component";
import { mapInpatientSupplyOrderDetails } from "./inpatientSupplyOrderDetailResponseMapper";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { IFacility } from "../../manageProfile/facilityInformation/facility.interface";
import { inpatientSupplyOrderDetailsModelData } from "./inpatientSupplyOrderDetails.model";
import { getAcuteSupplyOrderDetails } from "../../../util/actueService";
import ErrorPopup, {
  IErrorPopUp,
  defaultErrorPopUp,
} from "../../../core/errorPopup/errorPopup.component";
import { ERROR_MSG_GET_ACUTE_SUPPLY_ORDER_DETAILS } from "../../../util/errorMsg";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { Popup } from "../../../core/popup/popup.component";
import {
  OrderDetailContext,
  OrderDetailContextType,
} from "../../../context/OrderDetailsContext";
import { format } from "react-string-format";
import { DD_DELIVERY_METHODS } from "../../../util/staticText";
import { getdropDownContent } from "../../../util/dropDownService";
import {
  getTextFromCode,
  showVacOrderMenuOption,
} from "../../../util/utilityFunctions";
import { getUserRolePermission } from "../../../util/userService";
import { IPermissionData } from "../../../RolesPermission/RolesPermission.model";
import { mapUserRolesAndPermissionData } from "../../../RolesPermission/RolesPermission.Mapper";
import { ISideNav } from "../../sideNav/sideNavMenuOption.interface";
import {
  RolesPermissionContextType,
  RolesPermissionContext,
} from "../../../context/RolesPermissionContext";
import { IRouter } from "../../helpAndSupport/Navigator/navigator.interface";

interface IInpatientSupplyOrderDetailsData {}
export const InPatientSupplyOrderDetail =
  ({}: IInpatientSupplyOrderDetailsData) => {
    const location: any = useLocation();
    const history = useHistory();
    const authObj = useContext<AuthContextType | null>(AuthContext);
    const orderOverViewObj = useContext<OrderDetailContextType | null>(
      OrderDetailContext
    );
    const [inpatientSupplyOrderDetails, setInpatientSupplyOrderDetails] =
      useState<IInpatientSupplyOrderDetails | null>(
        inpatientSupplyOrderDetailsModelData
      );
    const permissionObj = useContext<RolesPermissionContextType | null>(
      RolesPermissionContext
    );
    const selectedPatientData: IFacilitySupplyOrderList =
      location?.state?.stateData ?? "";
    const [deliveryMethodList, setDeliveryMethodList] = useState([]);
    const [error, setError] = useState<IErrorPopUp>(defaultErrorPopUp);
    const [showLoader, setShowLoader] = useState(false);

    const AuthObj = useContext<AuthContextType | null>(AuthContext);
    const facilitySupplyOrderDetailNavigatorArray: IRouter[] = [
      {
        route: "/orders",
        pageName: "Start New Order",
      },
      {
        route:
          AuthObj?.userRolesAndPermissionLoaded &&
          permissionObj?.mappedRolesPermissionData?.IsShowFacilitySupplyOrders
            ? "/orders/facilitySupplyOrders"
            : "/home",
        pageName: "Facility Supply Order",
      },
    ];

    const fetchDeliveryMethodsContent = async () => {
      try {
        const ddContent = format("{0}", DD_DELIVERY_METHODS);
        const data = await getdropDownContent(ddContent);
        const deliveryTypes = data.items.filter(
          (item: { name: string }) => item.name === DD_DELIVERY_METHODS
        );
        if (data.items.length > 0) {
          const deliveryTypesData = deliveryTypes[0].data.sort(
            (a: { order: number }, b: { order: number }) =>
              a.order > b.order ? 1 : -1
          );
          setDeliveryMethodList(deliveryTypesData);
          return deliveryTypesData;
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    const getFacilitiesSuppliesData = async () => {
      const supplyOrderROPNumber = orderOverViewObj?.selectedSupplyOrder?.ropn;
      const reqBody = {
        rentalOrderNumber: selectedPatientData.roNumber,
        siteUseId:
          authObj?.registeredFaciltyAddress?.siteUseId! ??
          selectedPatientData.siteUseID,
        ropn: selectedPatientData.ropNumber ?? supplyOrderROPNumber,
      };
      const apiCollections = [
        fetchDeliveryMethodsContent(),
        getAcuteSupplyOrderDetails(reqBody),
      ];
      setShowLoader(true);
      const result = await Promise.all(apiCollections);
      if (result.length > 0) {
        const response = result[1];
        const comsDAta = result[0];

        if (response && response.succeeded) {
          let responseData = response.item;
          const selectedDeliveryMethod = getTextFromCode(
            comsDAta,
            responseData.deliveryMethod
          );
          const supplyOrderData = await mapInpatientSupplyOrderDetails(
            responseData,
            selectedPatientData,
            authObj?.registeredFaciltyAddress?.accountName!,
            supplyOrderROPNumber!,
            selectedDeliveryMethod
          );
          setInpatientSupplyOrderDetails(supplyOrderData);
        } else {
          const errorFailure = {
            errorCode:
              response?.error?.code ||
              response?.error?.errorCode ||
              response?.status,
            errorFlag: true,
            errorMessage: ERROR_MSG_GET_ACUTE_SUPPLY_ORDER_DETAILS,
          };
          setError(errorFailure);
        }
        setShowLoader(false);
      }
    };

    const checkValidFacility = (
      allFacilities: IFacility[],
      siteUseID: string
    ) => {
      let facilityFound: IFacility[] = allFacilities?.filter(
        (item: IFacility) => item.siteUseId === siteUseID
      );
      console.log(authObj?.allFacilities);
      if (facilityFound.length === 0) {
        history.replace("/home");
      } else {
        if (authObj?.registeredFaciltyAddress?.siteUseId !== siteUseID) {
          authObj?.setregisteredFaciltyAddress(facilityFound[0]);
          callUserRolePermission(facilityFound[0]);
        }
      }
    };

    const callUserRolePermission = async (facilty: IFacility) => {
      let facilityObj = facilty;
      if (facilityObj?.facilityAddressID !== undefined) {
        const userRolesPermissionResponse = await getUserRolePermission(
          facilityObj?.facilityAddressID
        );
        authObj?.setuserRolePermissionData(userRolesPermissionResponse.data);
        const rolesPermissionRes: IPermissionData =
          await mapUserRolesAndPermissionData(
            userRolesPermissionResponse.data!,
            facilityObj?.readyCareFlag
          );
        permissionObj?.setMappedRolesPermissionData(rolesPermissionRes);
        authObj?.setsideNavMenuOptionModelData((dt: ISideNav) => ({
          ...dt,
          orders: {
            ...dt.orders,
            isVisible: showVacOrderMenuOption(rolesPermissionRes),
          },
          inventory: {
            ...dt.inventory,
            isVisible: rolesPermissionRes.IsShowInventoryOption,
          },
          administration: {
            ...dt.administration,
            isVisible: rolesPermissionRes.IsShowAdminstrationOption,
          },
        }));
        authObj?.setuserRolesAndPermissionLoaded(true);
      }
    };
    useEffect(() => {
      getFacilitiesSuppliesData();
    }, []);

    useEffect(() => {
      if (
        authObj &&
        authObj?.allFacilities &&
        authObj.registeredFaciltyAddress &&
        authObj.userRolesAndPermissionLoaded &&
        authObj.registeredFaciltyAddress.siteUseId &&
        authObj.registeredFaciltyAddress.siteUseId !== "" &&
        authObj.allFacilities.length > 0 &&
        authObj?.deepLinkPath?.includes("/orders/supplyOrderDetail/deepLink/")
      ) {
        let urlData: any = authObj?.deepLinkPath?.split("/");
        let onbj: any = JSON.parse(atob(urlData[4]));
        if (
          authObj?.deepLinkPath?.includes("/orders/supplyOrderDetail/deepLink/")
        ) {
          checkValidFacility(authObj?.allFacilities, onbj.siteUseId);
        } else {
          checkValidFacility(
            authObj?.allFacilities,
            authObj?.registeredFaciltyAddress.siteUseId
          );
        }
      }
    }, [
      authObj,
      authObj?.allFacilities,
      authObj?.registeredFaciltyAddress?.siteUseId,
    ]);

    const handleErrorPopUpClose = () => {
      history.goBack();
    };

    return (
      <>
        {deliveryMethodList && (
          <div
            className="in-patient-supply-order-detail-page-component"
            data-testid="in-patient-supply-order-detail-page-component-testId"
            id="in-patient-supply-order-detail-page-component-id"
          >
            {!location.pathname.includes("/home/orderOverview") && (
              <Navigator
                array={facilitySupplyOrderDetailNavigatorArray}
                className="inpatient-supply-order-breadCrumbs"
                title="Supply Orders"
              />
            )}
            {inpatientSupplyOrderDetails && !showLoader && (
              <div
                className="inPatient-order-detail-sections"
                data-testid="inPatient-order-detail-sections-testId"
                id="inPatient-order-detail-sections-id"
              >
                <div className="inPatient-supply-order-detail-orderDetail-section">
                  <SupplyOrderDetailsTracking
                    patientData={
                      inpatientSupplyOrderDetails.orderInformation.patientData
                    }
                    supplyOrderTrackingData={
                      inpatientSupplyOrderDetails.orderInformation
                        .supplyOrderTrackingData
                    }
                    selectedSupplyOrderData={
                      inpatientSupplyOrderDetails.orderInformation
                        .selectedSupplyOrderData
                    }
                    inpatientSupplyOrder={true}
                  />
                </div>
                <div
                  className="inPatient-supply-order-detail-each-section"
                  id="inPatient-supply-order-detail-each-section-id"
                >
                  <SalesPoOrderInfoReview
                    data={inpatientSupplyOrderDetails.salesInformation}
                    isSummaryOrDetail={true}
                    isOrderDetails={true}
                  />
                </div>
                <div
                  className="inPatient-supply-order-detail-each-section"
                  id="inPatient-supply-order-detail-each-section-id"
                >
                  <ShippingAddressReview
                    data={inpatientSupplyOrderDetails.shippingAddress}
                    isSummaryOrDetail={true}
                  />
                </div>
                <div
                  className="inPatient-supply-order-detail-each-section"
                  id="inPatient-supply-order-detail-each-section-id"
                >
                  <DeliveryInformationReview
                    data={inpatientSupplyOrderDetails.deliveryInformation}
                    isSummaryOrDetail={true}
                    isOrderDetails={true}
                  />
                </div>
                <div
                  className="inPatient-supply-order-detail-selectedsupp-section"
                  id="inPatient-selected-supply-order-detail-each-section-id"
                >
                  <SelectedSupplies
                    isComingFromReview={false}
                    cartItemsData={inpatientSupplyOrderDetails.suppliesOrdered}
                    isFromInPatientSupplyOrderDetails={true}
                  />
                </div>
              </div>
            )}
            <Popup
              closeHandler={() => setShowLoader(false)}
              dialogParentClass={"inPatient-supply-order-detail-loader-pop-up"}
              data-testid="inPatient-supply-order-detail-pop-up"
              hideCloseButton={true}
              openFlag={showLoader}
            >
              <div
                className="inPatient-supply-order-detail-loader"
                data-testid="inPatient-supply-order-detail-loader"
              >
                <LoadingSpinner />
              </div>
            </Popup>
            <ErrorPopup
              buttontitle={"Done"}
              errorCode={error.errorCode}
              errorPopupFlag={error.errorFlag}
              errorMessage={error.errorMessage}
              isShortSentence={true}
              handleBackButton={handleErrorPopUpClose}
              popUpStyles="errorPopup"
              isSupportPhoneRequired={true}
              showTitle={false}
            />
          </div>
        )}
      </>
    );
  };
