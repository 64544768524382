import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { mapUserRolesAndPermissionData } from "../../RolesPermission/RolesPermission.Mapper";
import Favorite from "../../assets/Favorite.png";
import { searchFacility } from "../../components/manageProfile/facilityInformation/addFacility/addFacility.service";
import { ISideNav } from "../../components/sideNav/sideNavMenuOption.interface";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../context/RolesPermissionContext";
import Table from "../../core/customSortingTable/table.component";
import { LoadingSpinner } from "../../core/loader/LoadingSpinner";
import { Popup } from "../../core/popup/popup.component";
import {
  caregiverFacilitySearch,
  checkPostAcuteFacility,
  getCareGiverId,
} from "../../util/3meService";
import {
  getUserFavouriteFacilities,
  selectFacility,
  updateFavouriteFacility,
} from "../../util/userService";
import {
  agGridGetRowStyle,
  determineReadyCareFlag,
  makeCapitalEachOfWordInString,
  makeCapitalEachWordInString,
  showVacOrderMenuOption,
  useSortableTable,
} from "../../util/utilityFunctions";
import "./favoriteFacility.css";
import { ReactComponent as CheckIcon } from "../../assets/checkMark.svg";
import { useMediaQuery } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import ErrorPopup, {
  defaultErrorPopUp,
  IErrorPopUp,
} from "../../core/errorPopup/errorPopup.component";
import { ERROR_MSG_SSO_FAVOURITE_FACILITY_ERROR } from "../../util/errorMsg";

const FavoriteFacility = () => {
  const [error, setError] = useState<IErrorPopUp>(defaultErrorPopUp);
  const columns = [
    { label: "", accessor: "orderID", sortable: false },
    {
      label: "Facility Site Name",
      accessor: "accountName",
      sortable: true,
    },
    { label: "Favorite", accessor: "isFavorite", sortable: true },
    { label: "Site Use", accessor: "siteUseCode", sortable: true },
    { label: "Facility No.", accessor: "accountNumber", sortable: true },
    { label: "Ready Care", accessor: "readyCareFlag", sortable: true },
    {
      label: "Classification",
      accessor: "marketingSegmentDesc",
      sortable: true,
    },
    { label: "Setting", accessor: "careSetting", sortable: true },
  ];
  //For AG-Grid Mobile
  const defaultColDef = {
    flex: 1,
    filter: false,
    sortable: true,
    resizable: false,
    unSortIcon: true,
    minWidth: 130,
  };

  const columnDefs: any = [
    {
      headerName: "",
      field: "orderID",
      sortable: false,
      hide: true,
      suppressMovable: true,
    },
    {
      field: "accountName",
      headerName: "Facility Site Name",
      sortable: true,
      pinned: "left",
      lockPinned: true,
      width: 200,
      wrapText: true,
      autoHeight: true,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <>
            <span
              className={"fav-facility-name-mobile-screen"}
              id={params.data.siteUseId}
              onClick={(e) => handleSelectClick(e)}
            >
              {makeCapitalEachWordInString(params.data.accountName)}
            </span>
            <br />
            <span>
              {makeCapitalEachWordInString(params.data.address1)},{" "}
              {params.data.address2
                ? makeCapitalEachWordInString(params.data.address2) + ","
                : ""}{" "}
              {makeCapitalEachWordInString(params.data.city)},{" "}
              {makeCapitalEachOfWordInString(params.data.state)},{" "}
              {makeCapitalEachWordInString(params.data.zip)}
            </span>
          </>
        );
      },
    },
    {
      field: "isFavorite",
      headerName: "Favorite",
      sortable: true,
      filter: false,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <div className="imgclick">
            <img
              src={Favorite}
              alt={Favorite}
              onClick={() => handleFavLink(params.data)}
            ></img>
          </div>
        );
      },
    },
    {
      field: "siteUseCode",
      headerName: "Site Use",
      sortable: true,
      filter: false,
      suppressMovable: true,
    },
    {
      field: "accountNumber",
      headerName: "Facility No.",
      sortable: true,
      filter: false,
      suppressMovable: true,
    },
    {
      field: "readyCareFlag",
      headerName: "Ready Care",
      sortable: true,
      filter: false,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <>
            {determineReadyCareFlag(params.data.readyCareFlag) === "Yes" ? (
              <CheckIcon />
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "marketingSegmentDesc",
      headerName: "Classification",
      sortable: true,
      filter: false,
      width: 127,
      wrapText: true,
      autoHeight: true,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <span className="classificationText">
            {makeCapitalEachWordInString(`${params.data.marketingSegmentDesc}`)}
          </span>
        );
      },
    },
    {
      headerName: "Setting",
      field: "careSetting",
      sortable: true,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <span className="classificationText">
            {makeCapitalEachWordInString(`${params.data.careSetting}`)}
          </span>
        );
      },
    },
  ];
  const [sortedData, setSortedData, handleSorting] = useSortableTable(
    [],
    columns
  );
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const AuthObj = useContext<AuthContextType | null>(AuthContext);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const history = useHistory();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [currentPath, setCurrentPath] = useState<string | null>(null);

  useEffect(() => {
    if (AuthObj?.userProfile) {
      getUserFavFacilities();
    }
  }, [!sortedData, AuthObj?.userProfile]);
  useEffect(() => {
    if (location.pathname !== currentPath) {
      setCurrentPath(location.pathname);
    }
  }, []);
  const getUserFavFacilities = async () => {
    if (!loader) {
      setLoader(true);
    }
    const favFacilities = await getUserFavouriteFacilities(
      AuthObj?.userProfile?.userName
    );
    if (favFacilities.succeeded) {
      setSortedData(favFacilities.data);
      setLoader(false);
    } else {
      setLoader(false);
      const errorFailure = {
        errorCode:
          favFacilities?.error?.errorCode ||
          favFacilities?.error?.code ||
          favFacilities?.status,
        errorFlag: true,
        errorMessage: ERROR_MSG_SSO_FAVOURITE_FACILITY_ERROR,
      };
      setError(errorFailure);
    }
  };
  const updateFavFacility = async (e: any) => {
    const updateFacilities = await updateFavouriteFacility(
      AuthObj?.userProfile?.userName,
      e.siteUseId,
      FavoriteType.REMOVEFAVORITE
    );
    if (updateFacilities) {
      getUserFavFacilities();
    }
  };
  const Spinner = () => {
    return (
      <div className="facility-spinner">
        <LoadingSpinner />
      </div>
    );
  };

  const handleFavLink = (e: any) => {
    setLoader(true);
    updateFavFacility(e);
  };

  const handleSelectClick = async (e: any) => {
    if (currentPath === "/ssoRedirect") {
      localStorage.setItem("isComingFromSSO", "false");
    }
    const reqBody = {
      UserName: AuthObj?.userProfile?.userName,
      SiteUseId: e.target.id,
    };
    if (!loader) {
      setLoader(true);
    }
    const userPermissions = await selectFacility(reqBody);
    const data = sortedData.filter((x: any) => x.siteUseId === e.target.id)[0];
    AuthObj?.setuserRolePermissionData(userPermissions);
    await searchFacilityAddr(data, userPermissions);
    setLoader(false);
  };

  const searchFacilityAddr = async (data: any, userPermissions: any) => {
    let tempSideNavMenuOption: ISideNav;
    let readyCareFlag = "N";

    var facilitySearchRequest = {
      customerNumber: data.accountNumber,
      customerName: "",
      postalCode: "",
    };
    const searchedFacilities = await searchFacility(facilitySearchRequest);
    if (searchedFacilities) {
      const facilityRes = searchedFacilities.filter((x) => {
        if (x.siteUseId === data.siteUseId.toString()) {
          return x;
        }
      })[0];
      readyCareFlag = facilityRes.readyCareFlag!;
      facilityRes.isFavourite = true;
      //Set CareGiverId
      if (facilityRes.siteUseCode && facilityRes.siteUseCode?.includes("CG")) {
        const reqBody = {
          customerAccountNumber: facilityRes.accountNumber?.toString(),
        };
        const response = await caregiverFacilitySearch(reqBody);
        if (response && response.items && response.items.length > 0) {
          let homeCareGiverDetails = response.items;
          const homeCareGiverDetail = homeCareGiverDetails[0];
          if (facilityRes.siteUseCode?.includes("ST/CG")) {
            facilityRes.careGiverSiteUseId = homeCareGiverDetail.siteUseId;
            facilityRes.careGiverId = homeCareGiverDetail.origSystemReference;
          } else {
            facilityRes.careGiverId = homeCareGiverDetail.origSystemReference;
          }
        }
      }
      facilityRes.isPostAcute = (
        await checkPostAcuteFacility({
          FacilityTypeCode: facilityRes.typeCode,
        })
      ).postAcuteFound;
      AuthObj?.setregisteredFaciltyAddress(facilityRes);
      const rolesPermissionRes = await mapUserRolesAndPermissionData(
        userPermissions,
        readyCareFlag
      );

      permissionObj?.setMappedRolesPermissionData(rolesPermissionRes);
      let adminText: any = "Administration";
      if (
        (AuthObj?.unLinkedFacilitesCount && rolesPermissionRes.Is3MAdminRole) ||
        (AuthObj?.unLinkedFacilitesCount &&
          rolesPermissionRes.IsProdManagerRole) ||
        (AuthObj?.unLinkedFacilitesCount && rolesPermissionRes.IsSupportRole)
      ) {
        adminText = (
          <div className="sideNavmadminBtnMain">
            Administration
            <span className="sideNavmadminBtnStyle">
              {AuthObj?.unLinkedFacilitesCount}{" "}
            </span>{" "}
          </div>
        );
      }

      tempSideNavMenuOption = {
        ...AuthObj?.sideNavMenuOptionModelData!,
        orders: {
          labelText: "Start New Order",
          isVisible: showVacOrderMenuOption(rolesPermissionRes),
        },
        inventory: {
          labelText: "Inventory",
          isVisible: rolesPermissionRes.IsShowInventoryOption,
        },
        administration: {
          labelText: adminText,
          isVisible: rolesPermissionRes.IsShowAdminstrationOption,
        },
      };

      AuthObj?.setuserRolesAndPermissionLoaded(true);
      AuthObj?.setIsInternalUserFacilitySelected(true);
      history.push("/home");
    }

    AuthObj?.setsideNavMenuOptionModelData(tempSideNavMenuOption!);
  };

  return (
    <div className="favoriteFacility-subMain">
      <div className="favorite-facility" data-testid="favorite-facility">
        Your Favorites
      </div>
      <Popup closeHandler={Spinner} openFlag={loader} hideCloseButton={true}>
        <div className="facility-spinner">
          <LoadingSpinner />
        </div>
      </Popup>

      {isMobileScreen ? (
        <div className="ag-theme-quartz" style={{ height: 500, width: "100%" }}>
          <AgGridReact
            getRowStyle={agGridGetRowStyle}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={sortedData}
            suppressRowClickSelection={true}
            suppressDragLeaveHidesColumns={true}
          />
        </div>
      ) : (
        <div className="tables_container" data-testid="Facility-list">
          <Table
            tableClassName="tables"
            tableColumns={
              isMobileScreen
                ? columns.filter((column) => column.label !== "")
                : columns
            }
            handleSorting={handleSorting}
          >
            {columns && sortedData && sortedData.length > 0 ? (
              <tbody>
                {sortedData.map((data: any) => {
                  return (
                    <tr key={data.id}>
                      {!isMobileScreen && (
                        <td
                          className="select-facility"
                          id={data.siteUseId}
                          onClick={handleSelectClick}
                        >
                          Select
                        </td>
                      )}

                      <td
                        className={
                          isMobileScreen
                            ? "tables-static-data-mobileScreen"
                            : "tables-static-data"
                        }
                      >
                        <div
                          className={
                            isMobileScreen ? "facility-data-mobileScreen" : ""
                          }
                        >
                          <span
                            className={
                              isMobileScreen
                                ? "fav-facility-name-mobile-screen"
                                : ""
                            }
                            id={data.siteUseId}
                            onClick={
                              isMobileScreen ? handleSelectClick : () => {}
                            }
                          >
                            {makeCapitalEachWordInString(data.accountName)}
                          </span>
                          <br />
                          <span>
                            {makeCapitalEachWordInString(data.address1)},{" "}
                            {data.address2
                              ? makeCapitalEachWordInString(data.address2) + ","
                              : ""}{" "}
                            {makeCapitalEachWordInString(data.city)},{" "}
                            {makeCapitalEachOfWordInString(data.state)},{" "}
                            {makeCapitalEachWordInString(data.zip)}
                          </span>
                        </div>
                      </td>

                      <td className="tables-static-data">
                        <div className="imgclick">
                          <img
                            src={Favorite}
                            alt={Favorite}
                            onClick={() => handleFavLink(data)}
                          ></img>
                        </div>
                      </td>
                      <td className="tables-static-data">
                        {makeCapitalEachOfWordInString(`${data.siteUseCode}`)}
                      </td>
                      <td className="tables-static-data">
                        {makeCapitalEachWordInString(`${data.accountNumber}`)}
                      </td>
                      <td className="tables-static-data">
                        {determineReadyCareFlag(data.readyCareFlag) ===
                        "Yes" ? (
                          <CheckIcon />
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="tables-static-data">
                        {makeCapitalEachWordInString(
                          `${data.marketingSegmentDesc}`
                        )}
                      </td>
                      <td className="tables-static-data">
                        {makeCapitalEachWordInString(`${data.careSetting}`)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : null}
          </Table>
        </div>
      )}
      <ErrorPopup
        buttontitle={"Done"}
        errorCode={error.errorCode}
        errorPopupFlag={error.errorFlag}
        errorMessage={error.errorMessage}
        isShortSentence={true}
        handleBackButton={() => setError(defaultErrorPopUp)}
        popUpStyles="errorPopup"
        isSupportPhoneRequired={true}
        showTitle={false}
      />
    </div>
  );
};
export default FavoriteFacility;
export enum FavoriteType {
  ADDFAVORITE = 1,
  REMOVEFAVORITE = 2,
}
