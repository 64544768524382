import { Button, Grid, InputBase, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import "./changeSalesTerritory.css";
import SearchIcon from "@mui/icons-material/Search";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import { CustomCheckBox } from "../../../core/checkBox/checkBox.component";
import {
  changeDateFormatOnlyDate,
  makeCapitalEachWordInString,
} from "../../../util/utilityFunctions";
import {
  ISalePeople,
  ITerritoryDetails,
} from "../../../components/administration/salesManageUsers/salesUserProfile/AddSalesTerritory/addSalesTerritory.interface";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { useDebounce } from "use-debounce";
import { format } from "react-string-format";
import { DD_ALL_ROLES } from "../../../util/staticText";
import { getdropDownContent } from "../../../util/dropDownService";
import { getSalesTerritories } from "../../../util/userService";
import ErrorPopup, {
  defaultErrorPopUp,
  IErrorPopUp,
} from "../../../core/errorPopup/errorPopup.component";
import { ERROR_MSG_GET_TERRITORY_DETAILS } from "../../../util/errorMsg";

export interface IChangeSalesTerritory {
  selectedTerritory: string;
  setSelectedTerritory: Function;
  setShowPopup: Function;
}

export const ChangeSalesTerritory = ({
  selectedTerritory,
  setSelectedTerritory,
  setShowPopup,
}: IChangeSalesTerritory) => {
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const isMobileScreen = useMediaQuery("(max-width:768px)");
  const [searchedInput, setSearchedInput] = useState<string>("");
  const [searchInput, setSearchInput] = useState<string>("");
  const [debouncedText] = useDebounce(searchInput, 500);
  const [roleText, setRoleText] = useState<string[]>([]);
  const [selectedRoleType, setSelectedRoleType] = useState<string>("");
  const [territoriesData, setTerritoriesData] = useState<
    ITerritoryDetails[] | null
  >([]);
  const [filteredTerritoryData, setFilteredTerritoryData] = useState<
    ITerritoryDetails[] | null
  >([]);
  const [showMyTeamOnly, setShowMyTeamOnly] = useState(true);
  const [showInPageLoader, setShowInPageLoader] = useState(false);
  const [error, setError] = useState<IErrorPopUp>(defaultErrorPopUp);

  const handleSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const re = /^[a-zA-Z0-9-\s]+$/;
    if (e.target.value?.trim() === "" || re.test(e.target.value)) {
      setSearchInput(e.target.value?.trim() === "" ? "" : e.target.value);
    }
  };

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format("{0}", DD_ALL_ROLES ?? "");
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const roleTypeObject = data.items.filter(
          (item: { name: string }) => item.name === DD_ALL_ROLES
        );
        const roleTypeData = roleTypeObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setRoleText(roleTypeData.map((x: { text: string }) => x.text));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const validateAndSetRoleData = (e: any) => {
    setSelectedRoleType(e.target.value);
    filterUsers(debouncedText, e.target.value);
  };

  const filterUsers = (searchParam: string, role: string) => {
    setSearchedInput(searchParam);
    let searchRole = "";
    if (role.toLowerCase() !== "all roles") {
      searchRole = role;
    }
    if (territoriesData && territoriesData.length > 0) {
      let filtedUsers: ITerritoryDetails[] = territoriesData;
      if (searchRole.length > 0) {
        filtedUsers = filtedUsers.filter((user: ITerritoryDetails) =>
          user.roleType.includes(searchRole)
        );
      }
      if (searchParam.length > 0) {
        filtedUsers = filtedUsers.filter((user: ITerritoryDetails) => {
          const matchesTerritory =
            user.name.toLowerCase().includes(searchParam.toLowerCase()) ||
            user.code.toLowerCase().includes(searchParam.toLowerCase());

          const matchesSalesPeople = user.salesPeople?.some(
            (person) =>
              person.firstName
                .toLowerCase()
                .includes(searchParam.toLowerCase()) ||
              person.lastName.toLowerCase().includes(searchParam.toLowerCase())
          );

          return matchesTerritory || matchesSalesPeople;
        });
      }
      setFilteredTerritoryData(filtedUsers);
    }
  };

  const handleCheckBoxChange = (e: any) => {
    setSearchInput("");
    setSelectedRoleType("All roles");
    setShowMyTeamOnly(e.target.checked);
    getSalesTerritoriesAPI!(e.target.checked);
  };

  const getSalesTerritoriesAPI = async (myTeamOn?: boolean) => {
    setFilteredTerritoryData(null);
    setTerritoriesData(null);
    let params;
    if (authObj && authObj.userProfile) {
      if (authObj?.userProfile.area && authObj?.userProfile.region) {
        params = {
          region: myTeamOn ? authObj.userProfile?.region : "",
          area: !myTeamOn ? authObj.userProfile?.area : "",
          CurrentDate: changeDateFormatOnlyDate(new Date().toString()),
        };
        setShowInPageLoader(true);
      }
      const response = await getSalesTerritories(params);
      if (response && response.succeeded) {
        let allTerritories = response.data;
        const result = allTerritories.filter(
          (x: any) => x.code !== selectedTerritory
        );
        setTerritoriesData(result);
        setFilteredTerritoryData(result);
      } else {
        const errorFailure = {
          errorCode:
            response?.error?.code ||
            response?.error?.errorCode ||
            response?.status,
          errorFlag: true,
          errorMessage: ERROR_MSG_GET_TERRITORY_DETAILS,
        };
        setError(errorFailure);
      }
    }
    setShowInPageLoader(false);
  };

  const handleSelect = (territory: ITerritoryDetails) => {
    setSelectedTerritory(territory);
    setShowPopup(false);
  };

  useEffect(() => {
    getSalesTerritoriesAPI(true);
    fetchDropDownContent();
  }, []);

  useEffect(() => {
    if (
      (debouncedText.length === 0 && searchedInput !== debouncedText) ||
      debouncedText.length > 2
    ) {
      filterUsers(debouncedText, selectedRoleType);
    }
  }, [debouncedText]);

  return (
    <div className="changeSalesTerritory">
      <h4
        className="changeSalesTerritoryTitle"
        data-testid="changeSalesTerritory-header"
        id="changeSalesTerritoryTitleId"
      >
        Choose Sales Territory
      </h4>

      <Grid container gap={1.2} className="changeSalesTerritory-search">
        <Grid item sm={6.5} xs={12}>
          <div className="sale-user-searchbar" id="sale-user-searchbar-id">
            <div className="sale-search-icon-div">
              <SearchIcon className="sale-search-icon" />
            </div>
            <InputBase
              autoFocus
              className="sale-user-search-input"
              id="sale-user-search-input-id"
              data-testid="sale-user-search-input"
              name="search-input"
              onChange={handleSearch}
              placeholder={
                isMobileScreen
                  ? "Territory, Code or Salesperson"
                  : "Filter by Territory, Code or Salesperson"
              }
              value={searchInput}
            />
          </div>
        </Grid>
        <Grid item sm={2.5} xs={5} className="changeSalesTerritorySelectedRole">
          <CustomDropDown
            handleChange={validateAndSetRoleData}
            menuItem={["All roles"].concat(roleText)}
            name="sale-user-role"
            placeHolder="All roles"
            selectpropsClassName={
              selectedRoleType ? "sale-user-status-select" : "placeHolder"
            }
            selectClassName={
              selectedRoleType ? "sale-user-status-input" : "placeHolder"
            }
            testId="sale-user-status"
            value={selectedRoleType ? selectedRoleType : null}
          />
        </Grid>
        <Grid
          item
          sm={2.5}
          xs={6}
          className="changeSalesTerritoryRolesCheckBox"
        >
          <CustomCheckBox
            handleChange={handleCheckBoxChange}
            value={showMyTeamOnly}
            checked={showMyTeamOnly}
            labelText="Show my team only"
            labelClassName="checkboxLabel"
            testId="changeSalesTerritoryCheckBox"
          />
        </Grid>
      </Grid>

      <div className="changeSalesTerritory-list">
        {showInPageLoader ? (
          <div
            className="changeSalesTerritoryLoadingSpinner"
            data-testid="changeSalesTerritoryLoadingSpinner"
            id="changeSalesTerritoryLoadingSpinnerId"
          >
            <LoadingSpinner />
          </div>
        ) : filteredTerritoryData?.length ? (
          filteredTerritoryData.map(
            (territory: ITerritoryDetails, index: number) => (
              <Grid
                className={`changeSalesTerritoryList${
                  index % 2 === 1 ? " gray" : ""
                }`}
              >
                <Button
                  className="selectButton"
                  onClick={() => handleSelect(territory)}
                  data-testid="change-sales-territory"
                  id="change-sales-territory-id"
                >
                  Select
                </Button>
                <div className="changeSalesTerritoryDataGrid">
                  <Grid className="changeSalesTerritoryRegionBlock">
                    <Grid
                      className="changeSalesTerritoryRegion"
                      data-testid="territoryRegionTestId"
                    >
                      Territory & Region
                    </Grid>
                    <Grid
                      className="changeSalesTerritoryTerritoriesDetails"
                      data-testid="territoriesDetailsTestId"
                    >
                      {territory.code} - {territory.name}
                      <Grid>{territory.regionDistrict}</Grid>
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid className="salesPersonTitle">Salesperson</Grid>
                    {territory?.salesPeople?.length
                      ? territory.salesPeople.map((salepeople: ISalePeople) => (
                          <Grid className="salesPersonValue">
                            {makeCapitalEachWordInString(salepeople.lastName)}
                            {", "}
                            {makeCapitalEachWordInString(salepeople.firstName)}
                          </Grid>
                        ))
                      : "-"}
                  </Grid>
                </div>
              </Grid>
            )
          )
        ) : (
          <div
            className="changeSalesTerritoryNoRecords"
            data-testid="changeSalesTerritoryNoRecords"
          >
            No records to display.
          </div>
        )}
      </div>
      <ErrorPopup
        buttontitle={"Done"}
        errorCode={error.errorCode}
        errorPopupFlag={error.errorFlag}
        errorMessage={error.errorMessage}
        isShortSentence={true}
        handleBackButton={() => setError(defaultErrorPopUp)}
        popUpStyles="errorPopup"
        isSupportPhoneRequired={true}
        showTitle={false}
      />
    </div>
  );
};
