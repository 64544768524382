import { Grid, useMediaQuery } from "@mui/material";
import { useContext, useState } from "react";
import {
  OrderDetailContext,
  OrderDetailContextType,
} from "../../../../context/OrderDetailsContext";
import { ClinicalInformation } from "../../../newOrder/clinicalInformation/clinicalInformation.component";
import { ISecondaryWoundInfo } from "../../../newOrder/clinicalInformation/secondaryWoundInfo/secondaryWoundInfo.interface";
import { ComorboditiesReviewOrder } from "../../../newOrder/comorbodities/reviewOrder/comorboditiesReviewOrder.component";
import { ContributingCauseReviewOrder } from "../../../newOrder/contributingCause/reviewOrder/contributingCauseReviewOrder.component";
import Debridement from "../../../newOrder/debridement/debridement.component";
import { DeliveryContactReviewOrder } from "../../../newOrder/deliveryContact/reviewOrder/deliveryContactReviewOrder.component";
import { DeliveryInfoReviewOrder } from "../../../newOrder/deliveryInformation/reviewOrder/deliveryInfoReviewOrder.component";
import { DressingSuppliesReviewOrder } from "../../../newOrder/dressingSupplies/reviewOrder/dressingSuppliesReviewOrder.component";
import { EmergencyContactReviewOrder } from "../../../newOrder/emergencyContactInfo/reviewOrder/emergencyContactReviewOrder.component";
import { ExposedStructures } from "../../../newOrder/exposedStructures/exposedStructures.component";
import { Exudate } from "../../../newOrder/exudate/exudate.component";
import { HomeCareProviderReviewOrder } from "../../../newOrder/homeCareProvider/reviewOrder/homeCareProviderReviewOrder.component";
import { InpatientTransitionReviewOrder } from "../../../newOrder/inpatientTransition/reviewOrder/inpatientTransitionReviewOrder.component";
import { InsuranceMainComponent } from "../../../newOrder/insuranceInformation/insuranceMainComponent";
import { INewOrderWoundInfo } from "../../../newOrder/newOrderWoundInfoStepper/newOrderWoundInfo.interface";
import { NutritionReviewOrder } from "../../../newOrder/nutrition/reviewOrder/nutritionReviewOrder.component";
import { OsteomyelitisReviewOrder } from "../../../newOrder/osteomyelitis/reviewOrder/osteomyelitisReviewOrder.component";
import { PatientCurrentAddressReviewOrder } from "../../../newOrder/patientCurrentAddress/reviewOrder/partientCurrentAddressReviewOrder.component";
import { PatientInfoReviewOrder } from "../../../newOrder/patientInformation/reviewOrder/patientInfoReviewOrder.component";
import { PrescriberInformationReviewOrder } from "../../../newOrder/prescriberInformation/reviewOrder/prescriberInformationReviewOrder.component";
import { PreviousTherapiesReviewOrder } from "../../../newOrder/previousTherapies/reviewOrder/previousTherapyReviewOrder.component";
import { TherapyInformationReviewOrder } from "../../../newOrder/therapyInformation/reviewOrder/therapyInformationReviewOrder.component";
import { VerifyRequesterInfoReviewOrder } from "../../../newOrder/verifyRequesterInfo/reviewOrder/verifyRequesterInfoReviewOrder.component";
import { IVacTherapyInformation } from "../../../newOrder/woundBed/vacTherapyInformation.interface";
import WoundDimension from "../../../newOrder/woundDimension/woundDimension.component";
import WoundTunneling from "../../../newOrder/woundTunneling/woundTunneling.component";
import WoundUndermining from "../../../newOrder/woundUndermining/woundUndermining.component";
import "./../../../newOrder/newOrderReviewOrderStepper/reviewOrderStepper.css";
import { OrderDetailsProductInformation } from "./orderDetailsProductInformation/orderDetailsProductInformation.component";
import "./orderSummary.css";
import { BillingInfomation } from "./billingInformation/billingInformation.component";
import WoundInformationSectionReview from "../../../inpatientOrder/components/woundInformation/reviewWoundInformation/woundInformatoinReview.component";
import { ReplacementInformationReview } from "../../../inpatientOrder/components/replacementInformation/reviewReplacementInformation/replacementInformationReview.component";
import { PlacementDateReview } from "../../../inpatientOrder/components/placementDate/reviewPlacementDate/placementDateReview.component";
import { DeliveryPreferenceReview } from "../../../inpatientOrder/components/deliveryPreference/reviewDeliveryPreference/deliveryPreferenceReview.component";
import { IBillingAddress } from "../../../inpatientOrder/components/billingAddress/billingAddress.interface";
import { IPatientInformation } from "../../../inpatientOrder/components/patientInformation/patientInformation.interface";
import { makeCapitalEachWordInString } from "../../../../util/utilityFunctions";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { InpatientOrderValidator } from "../../../inpatientOrder/inpatientOrder.validator";
import { useHistory } from "react-router-dom";
import { IOrderDetailResponse } from "../orderDetailsTracking/orderDetailsTracking.interface";
import { LoadingSpinner } from "../../../../core/loader/LoadingSpinner";
import { Popup } from "../../../../core/popup/popup.component";
import { IPatient } from "../../patient.interface";
import { OrderOverviewValidator } from "../orderOverview/orderOverview.validator";

interface Props {
  isOrderSummary: boolean;
  newOrderData: any;
  setNewOrderData: Function;
  requesterInfo: any;
  dressingKit: any;
  canister: any;
  accessory: any;
  secondaryWoundInfoData: ISecondaryWoundInfo;
  woundInfoData: INewOrderWoundInfo;
  deliveryInformation: any;
  insuranceTypes: never[];
  insuranceTypesText: never[];
  accidentTypes: never[];
  therapyLengths: never[];
  therapyGoals: never[];
  vacTherapyInformationData: IVacTherapyInformation;
  productInfo: any;
  prodInfoTypes: any;
  deliverySites: any;
  states?: never[];
  statesText?: never[];
  reasonForTransition: never[];
  isAcuteOrder?: boolean;
  purchaseOrderNumber?: string;
  billingAddress?: IBillingAddress;
  isCustomerPlus?: boolean;
  isStoredProduct?: boolean;
  isSolventumProduct?: boolean;
  updateAcuteOrderDetailsAPI?: Function;
}
enum EditModeSection {
  PATIENT_INFO_SECTION = "patient-section",
  PRESCRIBER_INFO_SECTION = "prescriber-section",
}
export const OrderSummary = ({
  isOrderSummary,
  newOrderData,
  requesterInfo,
  dressingKit,
  canister,
  accessory,
  secondaryWoundInfoData,
  woundInfoData,
  deliveryInformation,
  insuranceTypes,
  insuranceTypesText,
  accidentTypes,
  therapyLengths,
  therapyGoals,
  vacTherapyInformationData,
  productInfo,
  prodInfoTypes,
  deliverySites,
  reasonForTransition,
  isAcuteOrder = false,
  purchaseOrderNumber,
  billingAddress,
  isCustomerPlus = false,
  isStoredProduct = false,
  isSolventumProduct = false,
  updateAcuteOrderDetailsAPI,
}: Props) => {
  const orderOverViewObj = useContext<OrderDetailContextType | null>(
    OrderDetailContext
  );
  const setExitPopUp = orderOverViewObj!.setExitPopUp;
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const patientInfo = orderOverViewObj!.patientInfo;
  const setPatientInfo = orderOverViewObj!.setPatientInfo;
  const prescriberInfo = orderOverViewObj!.prescriberInfo!;
  const setPrescriberInfo = orderOverViewObj!.setPrescriberInfo;
  const isPatientEditMode = orderOverViewObj!.isPatientEditMode;
  const setIsPatientEditMode = orderOverViewObj!.setIsPatientEditMode;
  const isPrescriberEditMode = orderOverViewObj!.isPrescriberEditMode;
  const setIsPrescriberEditMode = orderOverViewObj!.setIsPrescriberEditMode;
  const [isPrescriberPopupOpen, setIsPrescriberPopupOpen] =
    useState<boolean>(false);
  const getErrorMessageContent = (componentName: string) => {
    return (
      <div className="msApiErrorline">
        The following {componentName} is not available at this time
      </div>
    );
  };
  const handleSubmitData = async () => {
    updateAcuteOrderDetailsAPI!();
  };

  const handleEditAcuteOrderInfo = (section: EditModeSection) => {
    const Validator = new InpatientOrderValidator();
    setExitPopUp(true);
    switch (section) {
      case EditModeSection.PATIENT_INFO_SECTION:
        let patientData: IPatientInformation = {
          ...patientInfo,
          firstName: {
            ...patientInfo.firstName,
            valid: ValidationStatus.VALID,
            errorMessage: null,
            value: makeCapitalEachWordInString(newOrderData.firstName.value),
          },
          lastName: {
            ...patientInfo.lastName,
            valid: ValidationStatus.VALID,
            errorMessage: null,
            value: makeCapitalEachWordInString(newOrderData.lastName.value),
          },
          dateOfBirth: {
            ...patientInfo.dateOfBirth,
            valid: Validator.validate(newOrderData.dob.value, "dateOfBirth")
              ?.status!,
            errorMessage: null,
            value: newOrderData.dob.value,
          },
          patientId: {
            ...patientInfo.patientId,
            valid: ValidationStatus.VALID,
            errorMessage: null,
            value: newOrderData.patientId.value,
          },
          room: {
            ...patientInfo.room,
            valid: ValidationStatus.VALID,
            errorMessage: null,
            value: newOrderData.patientLocation.value,
          },
        };
        setPatientInfo(patientData);
        setIsPatientEditMode(true);
        break;
      case EditModeSection.PRESCRIBER_INFO_SECTION:
        setIsPrescriberEditMode(true);
        setIsPrescriberPopupOpen(true);
        break;
    }
  };

  return (
    <div className="order-summary-main-div">
      <Grid className="order-overview">
        <div className="all-content-div-orderoverview">
          <div className="sub-content-div-orderoverview">
            <div className="sub-content-component-border-div">
              {orderOverViewObj?.isGetPatientInfoMSApiError && (
                <div className="orderDetailsPatientInfoError">
                  {" "}
                  {getErrorMessageContent("patient information")}
                </div>
              )}
              <div className="sub-content-component">
                <Grid
                  className="grid-container-order-summary-order-summary"
                  container
                  spacing={2}
                >
                  <Grid
                    className="grid-item"
                    item
                    xs={
                      (isAcuteOrder && isPatientEditMode) || isMobileScreen
                        ? 12
                        : 9.1
                    }
                  >
                    <PatientInfoReviewOrder
                      data={newOrderData!}
                      editButtonClicked={() =>
                        handleEditAcuteOrderInfo(
                          EditModeSection.PATIENT_INFO_SECTION
                        )
                      }
                      isOrderSummary={isOrderSummary}
                      isAcuteOrder={isAcuteOrder}
                      patientInfo={patientInfo}
                      setPatientInfo={setPatientInfo}
                      isPatientEditMode={isPatientEditMode}
                      setIsPatientEditMode={setIsPatientEditMode}
                      handleSubmitPatientData={handleSubmitData}
                      newOrderData={newOrderData}
                      setExitPopUp={setExitPopUp}
                      isEditable={isAcuteOrder && !isCustomerPlus}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            {!isAcuteOrder && (
              <div className="border-div">
                <div className="sub-content-component">
                  <Grid
                    className="grid-container-order-summary"
                    container
                    spacing={2}
                  >
                    <Grid className="grid-item" item xs={9.1}>
                      <PatientCurrentAddressReviewOrder
                        data={newOrderData}
                        editButtonClicked={false}
                        isOrderSummary={isOrderSummary}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
            {!isAcuteOrder && (
              <div className="border-div">
                <div className="sub-content-component">
                  <Grid
                    className="grid-container-order-summary"
                    container
                    spacing={2}
                  >
                    <Grid className="grid-item" item xs={9.1}>
                      <EmergencyContactReviewOrder
                        data={newOrderData}
                        editButtonClicked={false}
                        isOrderSummary={isOrderSummary}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
            {orderOverViewObj?.isGetOpenRoMSApiError &&
              getErrorMessageContent("insurance information")}
            {!isAcuteOrder && (
              <div className="border-div">
                <div className="sub-content-component">
                  <Grid
                    className="grid-container-order-summary"
                    container
                    spacing={2}
                  >
                    <Grid className="grid-item" item xs={9.1}>
                      <InsuranceMainComponent
                        data={newOrderData}
                        setData={orderOverViewObj!.setOrderOverviewData!}
                        hideAddButton={true}
                        dropDownDataArray={insuranceTypes}
                        dropDownTextArray={insuranceTypesText}
                        addOrRemoveButtonAction={() => {}}
                        vacTherapyInformationData={vacTherapyInformationData}
                        isReviewOrder={true}
                        primaryEdiitButtonClicked={() => {}}
                        secondaryEditButtonClicked={() => {}}
                        isOrderSummary={isOrderSummary}
                        showAdditionalFields={
                          orderOverViewObj!.showAddtionalObject
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
            {orderOverViewObj?.isGetClinicalInfoMSAPIError &&
              getErrorMessageContent(
                "contributing cause and therapy information"
              )}
            {!isAcuteOrder && (
              <div className="border-div">
                <div className="sub-content-component">
                  <Grid
                    className="grid-container-order-summary"
                    container
                    spacing={2}
                  >
                    <Grid className="grid-item" item xs={9.1}>
                      <ContributingCauseReviewOrder
                        accidentTypes={accidentTypes!}
                        data={newOrderData}
                        editButtonClicked={false}
                        isOrderSummary={isOrderSummary}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
            {!isAcuteOrder && (
              <div className="border-div">
                <div className="sub-content-component">
                  <Grid
                    className="grid-container-order-summary"
                    container
                    spacing={2}
                  >
                    <Grid className="grid-item" item xs={9.1}>
                      <TherapyInformationReviewOrder
                        data={newOrderData!}
                        editButtonClicked={false}
                        therapyLengths={therapyLengths}
                        therapyGoals={therapyGoals}
                        isOrderSummary={true}
                        isOrderOverviewFlow={true}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}

            {orderOverViewObj?.isOrderStatusTrackingMSApiError &&
              getErrorMessageContent("prescriber information")}
            <div className="border-div">
              <div className="sub-content-component">
                <Grid
                  className="grid-container-order-summary"
                  container
                  spacing={2}
                >
                  <Grid
                    className="grid-item"
                    item
                    xs={isAcuteOrder && isMobileScreen ? 12 : 9.1}
                  >
                    <PrescriberInformationReviewOrder
                      data={orderOverViewObj?.prescriberAddedData}
                      editButtonClicked={() =>
                        handleEditAcuteOrderInfo(
                          EditModeSection.PRESCRIBER_INFO_SECTION
                        )
                      }
                      isOrderSummary={isOrderSummary}
                      isPrescriberEditMode={isPrescriberEditMode}
                      setIsPrescriberEditMode={setIsPrescriberEditMode}
                      prescriberInfo={prescriberInfo!}
                      setPrescriberInfo={setPrescriberInfo}
                      handleSubmitPrescriberData={handleSubmitData}
                      isAcuteOrder={isAcuteOrder}
                      isPrescriberPopupOpen={isPrescriberPopupOpen}
                      setIsPrescriberPopupOpen={setIsPrescriberPopupOpen}
                      setExitPopUp={setExitPopUp}
                      isEditable={isAcuteOrder && !isCustomerPlus}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>

            {orderOverViewObj?.isGetOpenRoMSApiError &&
              getErrorMessageContent("product information")}

            <div className="border-div">
              <div className="sub-content-component">
                <OrderDetailsProductInformation
                  productInfo={orderOverViewObj!.productInfo}
                  isAcuteOrder={isAcuteOrder}
                  isCustomerPlus={isCustomerPlus}
                />
              </div>
            </div>
            {isAcuteOrder && (
              <div className="border-div">
                <div className="sub-content-component">
                  <Grid
                    className="grid-container-order-summary"
                    container
                    spacing={2}
                  >
                    <Grid className="grid-item" item xs={9.1}>
                      <WoundInformationSectionReview
                        data={orderOverViewObj?.inPatientWoundInformation!}
                        editButtonAction={() => {}}
                        isFromCustomerPlusOrderOverView={true}
                        isSummary={isOrderSummary}
                        isFromOrderOverView={true}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
            {isSolventumProduct && (
              <div className="border-div">
                <div className="sub-content-component">
                  <Grid
                    className="grid-container-order-summary"
                    container
                    spacing={2}
                  >
                    <Grid className="grid-item" item xs={9.1}>
                      <DeliveryPreferenceReview
                        data={orderOverViewObj!.inPatientDeliveryPreferences!}
                        editButtonAction={() => {}}
                        isSummary={isOrderSummary}
                        isFromOrderOverview={isSolventumProduct}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
            {isStoredProduct && (
              <div className="border-div">
                <div className="sub-content-component">
                  <PlacementDateReview
                    data={orderOverViewObj?.inPatientPlacementDate!}
                    editButtonAction={() => {}}
                    isSummary={isOrderSummary}
                  />
                </div>
              </div>
            )}
            {!isAcuteOrder && (
              <div className="border-div">
                <div className="sub-content-component">
                  <Grid
                    className="grid-container-order-summary"
                    container
                    spacing={2}
                  >
                    <Grid className="grid-item" item xs={9.1}>
                      <DressingSuppliesReviewOrder
                        dressing={dressingKit}
                        canister={canister}
                        accesssory={accessory}
                        editButtonClicked={false}
                        isOrderSummary={isOrderSummary}
                        newOrderObj={orderOverViewObj}
                        isFromReadyCare={
                          orderOverViewObj!.productInfo.isReadyCare.value ===
                          "Yes"
                        }
                        isComingFromOrderOverView={true}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
            {orderOverViewObj?.isGetClinicalInfoMSAPIError &&
              getErrorMessageContent("inpatient transition information")}

            {!isAcuteOrder && (
              <div className="border-div">
                <div className="sub-content-component">
                  <Grid
                    className="grid-container-order-summary"
                    container
                    spacing={2}
                  >
                    <Grid className="grid-item" item xs={9.1}>
                      <InpatientTransitionReviewOrder
                        data={newOrderData}
                        facility={newOrderData.inpatientFacility}
                        editButtonClicked={false}
                        isOrderSummary={isOrderSummary}
                        isOrderOverview={true}
                        reasonForTransition={reasonForTransition}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
            {orderOverViewObj?.isOrderStatusTrackingMSApiError &&
              getErrorMessageContent("order and delivery information")}

            {!isAcuteOrder && (
              <div className="border-div">
                <div className="sub-content-component">
                  <Grid
                    className="grid-container-order-summary"
                    container
                    spacing={2}
                  >
                    <Grid className="grid-item" item xs={9.1}>
                      <VerifyRequesterInfoReviewOrder
                        data={requesterInfo}
                        facility={requesterInfo.requesterFacility}
                        editButtonClicked={false}
                        isOrderSummary={isOrderSummary}
                        hideFacilityID={
                          requesterInfo?.requesterFacility?.siteUseCode === "CG"
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
            {!isAcuteOrder && (
              <div className="border-div">
                <div className="sub-content-component">
                  <Grid
                    className="grid-container-order-summary"
                    container
                    spacing={2}
                  >
                    <Grid className="grid-item" item xs={9.1}>
                      <HomeCareProviderReviewOrder
                        data={newOrderData}
                        editButtonClicked={false}
                        isOrderSummary={isOrderSummary}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
            {!isAcuteOrder && (
              <div className="border-div">
                <div className="sub-content-component">
                  <Grid
                    className="grid-container-order-summary"
                    container
                    spacing={2}
                  >
                    <Grid className="grid-item" item xs={9.1}>
                      <DeliveryContactReviewOrder
                        data={newOrderData}
                        editButtonClicked={false}
                        isOrderSummary={isOrderSummary}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
            {isStoredProduct && (
              <div className="border-div">
                <div className="sub-content-component">
                  <Grid
                    className="grid-container-order-summary"
                    container
                    spacing={2}
                  >
                    <Grid className="grid-item" item xs={9.1}>
                      <ReplacementInformationReview
                        data={
                          orderOverViewObj!.inPatientReplacementInformation!
                        }
                        isSummary={true}
                        editButtonAction={() => {}}
                        isFromOrderOverview={isStoredProduct}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
            {(isStoredProduct || isSolventumProduct) && (
              <div className="border-div">
                <div className="sub-content-component">
                  <BillingInfomation
                    billingAddress={billingAddress}
                    purchaseOrderNumber={purchaseOrderNumber}
                  />
                </div>
              </div>
            )}
            {!isAcuteOrder && (
              <div className="border-div">
                <div className="sub-content-component">
                  <Grid
                    className="grid-container-order-summary"
                    container
                    spacing={2}
                  >
                    <Grid className="grid-item" item xs={9.1}>
                      <DeliveryInfoReviewOrder
                        data={deliveryInformation}
                        deliverySites={deliverySites}
                        editButtonClicked={false}
                        isOrderSummary={isOrderSummary}
                        isAcuteOrder={isAcuteOrder}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}

            <div>
              {" "}
              {orderOverViewObj?.isGetClinicalInfoMSAPIError &&
                getErrorMessageContent("clinical information")}
              {!isAcuteOrder && (
                <div className="border-div">
                  <div className="sub-content-component">
                    <Grid
                      className="grid-container-order-summary"
                      container
                      spacing={2}
                    >
                      <Grid className="grid-item" item xs={9.1}>
                        <NutritionReviewOrder
                          editButtonClicked={false}
                          isOrderSummary={isOrderSummary}
                          woundInfoData={woundInfoData}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
              {!isAcuteOrder && (
                <div className="border-div">
                  <div className="sub-content-component">
                    <Grid
                      className="grid-container-order-summary"
                      container
                      spacing={2}
                    >
                      <Grid className="grid-item" item xs={9.1}>
                        <PreviousTherapiesReviewOrder
                          editButtonClicked={false}
                          isOrderSummary={isOrderSummary}
                          woundInfoData={woundInfoData}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
              {!isAcuteOrder && (
                <div className="border-div">
                  <div className="sub-content-component">
                    <Grid
                      className="grid-container-order-summary"
                      container
                      spacing={2}
                    >
                      <Grid className="grid-item" item xs={9.1}>
                        <ComorboditiesReviewOrder
                          editButtonClicked={false}
                          isOrderSummary={isOrderSummary}
                          woundInfoData={woundInfoData}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
              {!isAcuteOrder && (
                <div className="border-div">
                  <div className="sub-content-component">
                    <Grid
                      className="grid-container-order-summary"
                      container
                      spacing={2}
                    >
                      <Grid className="grid-item" item xs={9.1}>
                        <OsteomyelitisReviewOrder
                          editButtonClicked={false}
                          isOrderSummary={isOrderSummary}
                          woundInfoData={woundInfoData}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
              {!isAcuteOrder && (
                <div className="border-div">
                  <div className="sub-content-component">
                    <Grid
                      className="grid-container-order-summary"
                      container
                      spacing={2}
                    >
                      <Grid className="grid-item" item xs={9.1}>
                        <ClinicalInformation
                          editButtonClicked={false}
                          isOrderSummary={isOrderSummary}
                          isReviewOrder={true}
                          setWoundInfoData={orderOverViewObj?.setWoundInfoData!}
                          woundInfoData={woundInfoData}
                          newOrderObj={orderOverViewObj}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
              {!isAcuteOrder && (
                <div className="border-div">
                  <div className="sub-content-component">
                    <Grid
                      className="grid-container-order-summary"
                      container
                      spacing={2}
                    >
                      <Grid className="grid-item" item xs={9.1}>
                        <Debridement
                          editButtonClicked={false}
                          isOrderSummary={isOrderSummary}
                          isReviewOrder={true}
                          setWoundInfoData={orderOverViewObj?.setWoundInfoData!}
                          woundInfoData={woundInfoData}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
              {!isAcuteOrder && (
                <div className="border-div">
                  <div className="sub-content-component">
                    <Grid
                      className="grid-container-order-summary"
                      container
                      spacing={2}
                    >
                      <Grid className="grid-item" item xs={9.1}>
                        <WoundDimension
                          editButtonClicked={false}
                          isOrderSummary={isOrderSummary}
                          isReviewOrder={true}
                          setWoundInfoData={orderOverViewObj?.setWoundInfoData!}
                          woundInfoData={woundInfoData}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
              {!isAcuteOrder && (
                <div className="border-div">
                  <div className="sub-content-component">
                    <Grid
                      className="grid-container-order-summary"
                      container
                      spacing={2}
                    >
                      <Grid className="grid-item" item xs={9.1}>
                        <WoundUndermining
                          editButtonClicked={false}
                          isOrderSummary={isOrderSummary}
                          isReviewOrder={true}
                          setWoundInfoData={orderOverViewObj?.setWoundInfoData!}
                          woundInfoData={woundInfoData}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
              {!isAcuteOrder && (
                <div className="border-div">
                  <div className="sub-content-component">
                    <Grid
                      className="grid-container-order-summary"
                      container
                      spacing={2}
                    >
                      <Grid className="grid-item" item xs={9.1}>
                        <WoundTunneling
                          editButtonClicked={false}
                          isOrderSummary={isOrderSummary}
                          isReviewOrder={isOrderSummary}
                          setWoundInfoData={orderOverViewObj?.setWoundInfoData!}
                          woundInfoData={woundInfoData}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
              {/* <div className="border-div">
              <div className="sub-content-component">
                <Grid className="grid-container-order-summary" container spacing={2}>
                  <Grid className="grid-item" item xs={9.1}>
                    <WoundBed
                      editButtonClicked={false}
                      isOrderSummary={isOrderSummary}
                      isReviewOrder={isOrderSummary}
                      setWoundInfoData={orderOverViewObj?.setWoundInfoData!}
                      woundInfoData={woundInfoData}
                      vacTherapyInformationData={vacTherapyInformationData!}
                    />
                  </Grid>
                </Grid>
              </div>
            </div> */}
              {!isAcuteOrder && (
                <div className="border-div">
                  <div className="sub-content-component">
                    <Grid
                      className="grid-container-order-summary"
                      container
                      spacing={2}
                    >
                      <Grid className="grid-item" item xs={9.1}>
                        <Exudate
                          editButtonClicked={false}
                          isOrderSummary={isOrderSummary}
                          isReviewOrder={isOrderSummary}
                          setWoundInfoData={orderOverViewObj?.setWoundInfoData!}
                          woundInfoData={woundInfoData}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
              {!isAcuteOrder && (
                <div className="border-div">
                  <div className="sub-content-component">
                    <Grid
                      className="grid-container-order-summary"
                      container
                      spacing={2}
                    >
                      <Grid className="grid-item" item xs={9.1}>
                        <ExposedStructures
                          editButtonClicked={false}
                          isOrderSummary={isOrderSummary}
                          isReviewOrder={isOrderSummary}
                          setWoundInfoData={orderOverViewObj?.setWoundInfoData!}
                          woundInfoData={woundInfoData}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
              {woundInfoData.isShowSecondaryWoundInfo.value === "Yes" &&
                !isAcuteOrder && (
                  <div className="">
                    <div className="border-div">
                      <div className="sub-content-component">
                        <Grid
                          className="grid-container-order-summary"
                          container
                          spacing={2}
                        >
                          <Grid className="grid-item" item xs={9.1}>
                            <ClinicalInformation
                              editButtonClicked={false}
                              isOrderSummary={isOrderSummary}
                              isReviewOrder={isOrderSummary}
                              isSecondaryWoundInfo={true}
                              setWoundInfoData={
                                orderOverViewObj?.setSecondaryWoundInfoData!
                              }
                              woundInfoData={secondaryWoundInfoData}
                              newOrderObj={orderOverViewObj}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    {!isAcuteOrder && (
                      <div className="border-div">
                        <div className="sub-content-component">
                          <Grid
                            className="grid-container-order-summary"
                            container
                            spacing={2}
                          >
                            <Grid className="grid-item" item xs={9.1}>
                              <Debridement
                                editButtonClicked={false}
                                isSecondaryWoundInfo={true}
                                isOrderSummary={isOrderSummary}
                                isReviewOrder={isOrderSummary}
                                setWoundInfoData={
                                  orderOverViewObj?.setSecondaryWoundInfoData!
                                }
                                woundInfoData={secondaryWoundInfoData}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                    {!isAcuteOrder && (
                      <div className="border-div">
                        <div className="sub-content-component">
                          <Grid
                            className="grid-container-order-summary"
                            container
                            spacing={2}
                          >
                            <Grid className="grid-item" item xs={9.1}>
                              <WoundDimension
                                editButtonClicked={false}
                                isSecondaryWoundInfo={true}
                                isOrderSummary={isOrderSummary}
                                isReviewOrder={isOrderSummary}
                                setWoundInfoData={
                                  orderOverViewObj?.setSecondaryWoundInfoData!
                                }
                                woundInfoData={secondaryWoundInfoData}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                    {!isAcuteOrder && (
                      <div className="border-div">
                        <div className="sub-content-component">
                          <Grid
                            className="grid-container-order-summary"
                            container
                            spacing={2}
                          >
                            <Grid className="grid-item" item xs={9.1}>
                              <WoundUndermining
                                editButtonClicked={true}
                                isSecondaryWoundInfo={true}
                                isOrderSummary={isOrderSummary}
                                isReviewOrder={isOrderSummary}
                                setWoundInfoData={
                                  orderOverViewObj?.setSecondaryWoundInfoData!
                                }
                                woundInfoData={secondaryWoundInfoData}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                    {!isAcuteOrder && (
                      <div className="border-div">
                        <div className="sub-content-component">
                          <Grid
                            className="grid-container-order-summary"
                            container
                            spacing={2}
                          >
                            <Grid className="grid-item" item xs={9.1}>
                              <WoundTunneling
                                editButtonClicked={false}
                                isSecondaryWoundInfo={true}
                                isOrderSummary={isOrderSummary}
                                isReviewOrder={isOrderSummary}
                                setWoundInfoData={
                                  orderOverViewObj?.setSecondaryWoundInfoData!
                                }
                                woundInfoData={secondaryWoundInfoData}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}

                    {!isAcuteOrder && (
                      <div className="border-div">
                        <div className="sub-content-component">
                          <Grid
                            className="grid-container-order-summary"
                            container
                            spacing={2}
                          >
                            <Grid className="grid-item" item xs={9.1}>
                              <Exudate
                                editButtonClicked={false}
                                isSecondaryWoundInfo={true}
                                isOrderSummary={isOrderSummary}
                                isReviewOrder={isOrderSummary}
                                setWoundInfoData={
                                  orderOverViewObj?.setSecondaryWoundInfoData!
                                }
                                woundInfoData={secondaryWoundInfoData}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                    {!isAcuteOrder && (
                      <div className="border-div">
                        <div className="sub-content-component">
                          <Grid
                            className="grid-container-order-summary"
                            container
                            spacing={2}
                          >
                            <Grid className="grid-item" item xs={9.1}>
                              <ExposedStructures
                                editButtonClicked={false}
                                isSecondaryWoundInfo={true}
                                isOrderSummary={isOrderSummary}
                                isReviewOrder={isOrderSummary}
                                setWoundInfoData={
                                  orderOverViewObj?.setSecondaryWoundInfoData!
                                }
                                woundInfoData={secondaryWoundInfoData}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>
        </div>
      </Grid>
      <Popup
        openFlag={showLoader}
        closeHandler={() => setShowLoader(false)}
        dialogParentClass={"edit-acute-data-loader-pop-up"}
        hideCloseButton={true}
      >
        <div className="edit-acute-data-pop-up">
          <LoadingSpinner />
        </div>
      </Popup>
    </div>
  );
};
